$maps-light-color: $brand-color-element;
$maps-primary-color: $brand-color-base;
$maps-space-s: $s;

.sucursales {

    .slideFromBottom {
        @media (max-width: 767px) {
            .Select-option.is-focused {
                background-color: rgba(0, 0, 0, 0.1);
                padding: 1rem 0rem 0rem 1rem;
            }

            .Select-option {
                padding: 1rem 0rem 0rem 1rem;
            }
        }
    }

    &--title-sucursal {
        width: 100%;
        margin-bottom: $maps-space-s;
        background-color: #fff;

        h3 {
            width: 100%;
            padding: 0.5rem 1rem;
            margin-top: 0;
            border-radius: 5px;
            color: $maps-primary-color;
            font-size: 1.125rem;
            font-weight: 500;
            color: grey;
            text-transform: capitalize;
            background-color: $maps-light-color;
        }

        @media (max-width: 767px) {
            h3 {
                background-color: transparent;
                margin-top: 10px;
                padding: 0.5rem 0rem;
            }
        }
    }

    @media (max-width: 767px) {
        &--title-sucursal {
            display: flex;
            align-items: center;
        }
    }

    &--list {
        display: flex;
        flex-direction: column;
        border: 1px solid $maps-light-color;
        padding: 0;
        min-height: 500px;
        max-height: 500px;
        overflow: scroll;
    }

    @media (max-width: 767px) {
        &--list {
            display: block;
            border: 0px solid #efedf7;
            background: #fff;
            min-height: 0px;
            position: fixed;
            transition: all .2s linear;
            border-radius: 5px 5px 0 0;
            overflow: unset;
        }
    }

    &--list-filter {
        display: flex;
        border-bottom: 1px solid $maps-light-color;
        min-height: 64px;

        >div:first-child {
            width: 40px;
            margin: auto 0;
            height: 40px;

            //Checkbox Size
            .svg-wrapper {
                .svg-icon {
                    width: 2rem;
                    min-width: 2rem;
                    height: 2rem;
                    fill: $maps-primary-color;
                }

                @media (max-width: 767px) {
                    .svg-icon {
                        width: 0.75rem;
                        min-width: 0.75rem;
                    }
                }
            }
        }

        >div:last-child {
            margin: auto 0;
            height: 40px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: flex-end;

            .c-control-block {
                width: 25%;
            }
        }

        @media (max-width: 767px) {

            >div:last-child .c-control-block {
                width: 100%;
                text-align: center;
            }

            >div:last-child {
                justify-content: space-around;
            }

            >div:first-child {
                width: 100%;
            }
        }
    }

    &--list-item {

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        strong {
            font-size: 12px;
            color: grey;
        }

        >div {
            text-decoration: none;
            display: flex;

            &:hover {
                background: #f9f9f9;
            }

            >div {
                width: 100%;
                display: flex;
                min-height: 80px;
                border-bottom: 1px solid $maps-light-color;

                >div:first-child {
                    width: 60px;
                    background: $maps-light-color;
                    margin: 8px;
                    height: 60px;
                    border-radius: 5px;
                }

                >div:last-child {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 0.65rem;
                    align-items: center;

                    p:first-child {
                        text-decoration: none;
                        font-size: 1.25rem;
                        color: grey;
                        font-weight: 600;
                    }

                    p:last-child {
                        font-size: 0.65rem;
                    }
                }
            }
        }
    }

    &--list-item-mobile {
        p {
            font-size: 1.2rem;
            color: $maps-primary-color;
        }
    }

    >div:last-child {
        padding-right: 0;
    }

    &--maps {
        position: relative;
        background: $maps-light-color;
        padding: $maps-space-s;
        min-height: 500px;
        max-height: 500px;

        .googleMap {
            position: relative;
            display: flex;

            >div {
                min-height: 200px;
                padding: 0;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 2px 14px 4px #d6d6d6;

                >div {
                    min-height: 170px;
                }
            }
        }

        @media (max-width: 767px) {
            // Map 100%
            min-height: 100%;
            max-height: 100%;
            // Map without margins
            padding: 0rem;

            .googleMap {
                position: initial;
                height: auto;
            }

            .buttons--maps .btn-block+.btn-block {
                margin-top: 5px;
            }

            .buttons--maps .btn {
                margin-right: 5px;
            }
        }
    }

    @media (max-width: 767px) {

        //Show Collapsed Menu with Animation
        .show-sucursales--list {
            bottom: 0px !important;
            transition: all .2s linear;
        }

        // Select at 100%
        .form-group-row {
            display: block;
        }

    }

    // Top Map Buttons
    &--maps {
        .buttons--maps {
            position: absolute;
            bottom: 30px;
            right: 20px;
        }
    }

    @media (max-width: 767px) {

        // Top Map Buttons Mobile
        &--maps {
            .buttons--maps {
                position: absolute;
                top: 2%;
                right: 2%;
                width: 40%;
            }
        }
    }

    .Select-menu-outer {
        height: 85%;
        width: 96%;
        left: 2%;
        border-radius: 5px 5px 0 0;
        text-align: left;
    }

    //Hide info on Select Collapsed
    &--list-item-mobile {
        p {
            span {
                display: none;
            }
        }
    }
}

//Open Select Menu Styles
.is-open {
    .sucursales {
        &--list-item-mobile {
            border-bottom: 3px solid #eee;

            p {
                font-size: 1.2rem;
                margin: 0;
                color: $brand-color-base;

                span {
                    margin-bottom: 15px;
                    //Show Info on Select UnCollpased
                    display: block;
                }
            }

            strong {
                font-size: 1rem;
                color: grey;
                font-weight: 500;
            }
        }
    }
}


@media (min-width: 768px) {
    .googleMap {
        >div {
            max-width: 40rem;
            padding: 0 20px;
            width: 100%;
            max-width: initial;

            >div {
                height: 300px;
                position: relative !important;
            }
        }
    }
}

@media (max-width: 767px) {

    .container--map {
        align-items: initial;
        height: 100%;

        >.container {
            min-height: 100%;
        }
    }

    .googleMap {
        margin: 0;
        height: auto;

        >div {
            padding: 0;
        }
    }

}

.googleMap .btn {
    position: absolute;
    z-index: 1;
    width: auto;
}

.google-map-wrapper {
    position: relative;
    min-height: 100%;
}

.google-map-button-wrapper {
    width: 100%;
    position: absolute;
    z-index: 10;
    display: flex;

    @include respond-to(mobile) {
        justify-content: center;
    }
}

.view-wrapper .google-map-button-wrapper .btn {
    margin: 1rem;
}