.react-tagsinput {
    @extend .form-control;
    position: static;

    > span {
        display: flex;
        flex-flow: wrap;
    }

    &-tag {
        background-color: #cde69c;
        border-radius: 2px;
        border: 1px solid #a5d24a;
        color: #638421;
        display: inline-block;
        margin-right: 5px;
        box-sizing: border-box;
        white-space: nowrap;
        margin-bottom: 0.25em;

        button::before {
            content: " x";
        }
    }

    &-input {
        @include placeholder($color: $input-color-placeholder);

        background: transparent;
        border: 0;
        font-weight: 400;
        outline: none;
        width: 100%;
    }

    &-remove {
        cursor: pointer;
        font-weight: bold;
    }
}
