/* Main Container */
.view-wrapper {
    z-index: 1;
    min-height: calc(100vh - 250px);
}

.main-container {
    z-index: 100;
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: wrap row;
    align-items: stretch;
    justify-content: space-around;
    margin-left: 0;
    margin-right: 0; // squizz elements
    // flex-direction: column;
    flex-grow: 1;

    /* Usualmente solo va un elemento aqui, pero no es mandatorio */
    > * {
        width: 100%;
    }

    /**
     * 1. Permite una alineacion centrada para mobile, ademas de no comprimir
     *    el contenido.
     */
    @include respond-to(mobile) {
        > * {
            margin-top: auto;
            /* [1] */
            margin-bottom: auto;
            /*  [1] */
        }
    }
}

/* Above the fold */

.above-the-fold {
    flex-basis: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
    align-self: stretch;
    z-index: 1;

    /* para que settings no tenga separacion entre los contenedores, no me convence */
    &.tight-containers {
        .container--layout {
            margin: 0;

            &:last-of-type {
                margin-bottom: 1.75rem;
            }

            &:first-of-type {
                margin-top: 0.5rem;
            }
        }
    }

    &.show-on-top {
        display: block;
    }
}

.below-the-fold {
    width: 100%;
    flex-basis: 100%;
}

/* Containers */

.container {
    padding: 0 1rem;
}

/**
 * TODO arreglar esto que no se entiende que es.
 * HACK remove double margin, used to fix filters
 */

@include respond-to(mobile) {
    .container--layout:last-child .container--layout:last-child {
        margin-bottom: 0;
    }
}

@include respond-to(desktop) {
    .container--layout {
        padding: 0 ($l / 2);
    }
}

.container--layout {
    display: flex;
    width: 100%;
    margin: 1rem 0;

    @include respond-to(mobile) {
        &:last-of-type {
            margin-bottom: $l;
        }
    }

    &.container--bottom {
        margin-bottom: 0;
        margin-top: 1.25rem;
    }

    @include when-inside(".view-morphing") {
        margin-bottom: 0;
    }

    @include when-inside(".default-layout") {
        max-width: calc(1200px - (2rem * 2));
    }

    @include when-inside(".app-footer") {
        max-width: initial;
    }
}

.container--layout .app-header,
.app-footer {
    .container--layout {
        margin-bottom: 0;
    }
}

/* Contenedor centrado */

.container--layout > .row {
    min-width: 100%;
    flex: auto;
    justify-content: inherit;
}

.container--bottom small {
    vertical-align: text-bottom;
}

@include respond-to(desktop) {
    .above-the-fold {
        justify-content: flex-start;
        margin: initial !important;
    }
}

.camera-page > .above-the-fold {
    justify-content: center;
    flex-flow: row wrap;
}

.credit-card-steps {
    display: inline-flex;
    position: absolute;
    justify-content: flex-end;
    width: 100%;
}

.credit-card-pill {
    position: relative;
    width: 150px;
    height: 20px;
    border: 1px solid blue;
    margin: 5px;
    border-radius: 5px;

    div {
        position: absolute;
    }
}

.credit-card-main {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background-image: url("../images/gradiant-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 200px;
}

.credit-card-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respond-to(mobile) {
        display: block;
    }
}

.credit-card-justify {
    justify-content: center;
}

.credit-card-info {
    width: 110%;
}

.credit-card-text {
    width: 70%;
}

.credit-card-step {
    position: relative;
    height: 100%;
    width: 100%;
}

.swiper-disabled {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.swiper-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
        height: 100%;
    }

    .swiper-slide {
        transition: all 1s;
        opacity: 0.7;

        &-active {
            position: relative;
            z-index: 10;
            opacity: 1;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        z-index: 3000;
        text-align: center;
        justify-content: center;

        //left: 150px;
        &:hover {
            .cls-1 {
                fill: white !important;
            }
        }

        span {
            display: none;
        }

        svg {
            text-align: center;
        }
    }

    .swiper-button-hidden {
        display: none;
    }

    .swiper-button-prev {
        top: 0;
    }

    .swiper-button-next {
        bottom: 10px;
    }

    .card1,
    .card2,
    .card3,
    .card4,
    .card5 {
        background-size: contain;
        background-repeat: no-repeat;
    }

    .card1 {
        background-image: url("../images/visaTravel.png");
    }

    .card2 {
        background-image: url("../images/visaShop.png");
    }

    .card3 {
        background-image: url("../images/visaFC.png");
    }

    .card4 {
        background-image: url("../images/masterP.png");
    }

    .card5 {
        background-image: url("../images/masterB.png");
    }

    .selected {
        transform: translate(200px);
    }
}

.onboarding-warning {
    height: 10rem;
    width: 10rem;
}

.onboarding-error {
    margin: auto;
    display: flex;
    div {
        margin: 0 5px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
}

.onboarding-error-hasAccount {
    background-image: url("../images/onboarding-hasAccount.png");
    background-repeat: no-repeat;
    height: 100%;
    width: 300px;
    background-position: center;
}

.onboarding-documents {
    text-align: center;
    padding: 10px 0;

    .onboarding-container {
        width: 60%;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 100px;
        margin-bottom: 80px;

        .onboarding-card {
            height: 350px;
            opacity: 0.8;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            border-width: 3px;
            margin-top: 40px !important;

            .svg-wrapper {
                height: auto;
                width: auto;
            }

            svg {
                width: 200px;
                height: 200px;
                margin: 20px;
            }

            &:hover {
                opacity: 0.9;
            }

            &:focus {
                opacity: 1;
            }
        }

        .oc-active {
            border-color: $brand-color-base;
            background: white;
        }
    }
}

.statements-title {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding-bottom: 1.5%;
    font-weight: 700;
    font-size: 1rem;
    h4 {
        font-size: 1rem !important;
    }
}

.onboarding-camera {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    div,
    h2 {
        margin: 0 0 20px 0;
    }
}

.documents-presentation {
    display: flex;
    flex-direction: row;

    img {
        width: 50%;
        height: 50%;
        padding: 1px;
    }
}

.onboarding-text-align-center {
    text-align: center;
}

.onboarding-margin {
    margin: 0 auto;
}

.productRequest {
    nav {
        ul {
            list-style: none;
            background: white;
            width: 100%;
            min-height: 100px;
            margin: 0;

            .logo {
                padding-right: 50px;

                img {
                    vertical-align: middle;
                    width: 190px;
                }
            }

            .sign {
                margin: 20px 40px 0 0;
                float: right;
                box-shadow: none;
                line-height: 20px;
                padding: 0;

                div {
                    justify-content: center;
                    text-align: center;

                    a {
                        line-height: 10px;
                        text-transform: uppercase;
                        font-size: 0.6em;
                        text-decoration: underline;
                    }
                }
            }

            li {
                float: left;
                line-height: 100px;
                border-right: solid 1px #eeeeee;
                margin: 0;
                padding: 0 15px;
                vertical-align: middle;

                a {
                    text-decoration: none;
                }

                color: $brand-color-base;

                &:hover {
                    background-image: linear-gradient(#ffffff, #f5f5f5) !important;
                }
            }
        }
    }

    .theme-auth {
        .view-page {
            &::after {
                display: none;
            }
        }

        .view-content {
            padding-top: 0 !important;
        }
    }

    .sign-btn {
        // background: #139688;
        border: 2px solid white !important;
        line-height: 30px;
        color: white;
        padding: 5px 20px;
        border-radius: 5px;
        outline: none;
        text-transform: uppercase;
        cursor: pointer;
    }

    .title {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        color: white;
        z-index: 1000;

        h1 {
            font-size: 7em;
            text-transform: uppercase;
        }

        p {
            font-size: 1.6em;
            width: 60%;
            margin: 0 auto;
            letter-spacing: 2px;
        }

        .sign-btn {
            width: 200px !important;
            margin: 20px auto !important;
        }
    }

    .background-image {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(../images/background.png);
        background-size: cover;
        object-fit: cover;
    }

    .flight-image {
        position: absolute;
        top: -5%;
        left: -10%;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-image: url(../images/flight.png);
        background-position: 0 50px;
        background-size: 50%;
        background-repeat: no-repeat;
    }

    .btn-picture .svg-wrapper svg {
        width: 40px;
        height: 40px;
    }

    .btn-mb {
        margin-bottom: 2.5rem;
    }

    @media screen and (max-width: 1260px) {
        .flight-image {
            left: -120px;
        }

        .title {
            h1 {
                font-size: 5em;
            }

            p {
                font-size: 1em;
            }
        }
    }

    @media screen and (max-width: 1099px) {
        .logo {
            display: inline-block;
        }

        .flight-image {
            position: relative;
            margin: 0 auto;
            top: 0;
        }

        .title {
            position: relative;
            top: -10px;
            left: 0;
            transform: translate(0);
        }
    }

    @media screen and (max-width: 768px) {
        .flight-image {
            width: 280px;
            height: 200px;
        }

        .title {
            h1 {
                font-size: 4em;
            }
        }

        .onboarding-error{
            flex-direction: row;
        }
    }
}

#cameraBtn {
    border: 1px solid lightgray;
    background: lightgray;
    width: 80px;
    height: 60px;
    display: flex;
    margin: 20px auto;

    svg {
        width: 2.5em;
        height: 2.5em;
    }
}

// Onboarding Layout Desktop

.onboarding-layout {
    .list-icon-container {
        padding-left: 0;

        .list-item--has-icon div.bullet-icon {
            margin-right: 0;
        }
    }

    div.list-text {
        margin-left: 10px;
        margin-top: 2px;

        p > span {
            font-family: "Montserrat", sans-serif;
        }

        p:nth-child(1) > span {
            font-weight: bold;
        }
    }
}

.enrollment-layout, .onboarding-layout{
    @include respond-to(desktop){
        .btn-block:not(.btn-image) {
            display: inline-flex;
            // width: 100%;
            margin-top: 20px;
            margin-left: 0 !important;
        }
    }
}

.camera-layout {
    .onboarding-camera h2,
    .onboarding-camera h1,
    .onboarding-camera p {
        color: black;
    }

    .view-close,
    .view-back {
        color: $brand-color-accent;
        border-color: $brand-color-accent;
    }

    .view-back:hover {
        color: #2c9fbe;
    }

    #cameraBtn {
        border: 1px solid lightgray;
        background: lightgray;
        width: 80px;
        height: 60px;
        display: flex;
    }

    video {
        margin: 0 auto;
    }

    .container--layout:first-of-type > .form-content > .row > .col:first-of-type {
        display: flex;
        justify-content: center;
    }

    .btn-picture .svg-wrapper svg {
        width: 40px;
        height: 40px;
    }
}


.error-layout {
    .app-page {
        @include respond-to(mobile) {
            flex-direction: row;
        }
    }
}

.enrollment-layout {
    .toolbar-btn.view-back {
        border: 0;
        font-size: 1.5rem;
        padding: 0;

        &:focus,
        &:active {
            background: transparent;
            box-shadow: none;
        }

        @include respond-to(mobile) {
            margin: 10px 15px;
        }
    }

    .container-navbar {
        display: flex !important;
        justify-content: space-between;
    }

    .form-content ol.steps-path {
        li {
            border: 1px solid $brand-color-base;
            margin-right: 2em;
            width: 25%;

            @include respond-to(mobile) {
                margin-right: 0.5em;
            }

            span {
                display: flex;
                justify-content: center;
                margin-top: 10px;
                text-align: center;
                font-size: 0.8rem;
            }
        }
    }

    .frontID,
    .passport,
    .reverseID,
    .LifeTest {
        width: 50%;
        height: 50%;
        margin: 60px auto;
    }

    .row h2.text-center {
        margin-top: 30px;
    }

    p.text-center {
        margin-bottom: 2em;
    }

    video {
        margin: 0 auto;
    }

    .text-lead {
        margin-top: 60px;
    }

    .list-icon-container .list-item--has-icon div.bullet-icon {
        div.svg-wrapper {
            width: 2em;
            height: 2em;

            svg {
                width: 1em;
            }
        }

        @include respond-to(mobile) {
            margin-top: 8px;
        }
    }

    .list-item--has-icon {
        display: flex;
        justify-content: center;
    }

    .ob-list-icon {
        display: flex;
        align-items: center;

        .svg-wrapper {
            width: 4em;
            height: 4em;

            svg {
                width: 3em;
                height: 3em;
            }
        }
    }

    .phone-icon > div > div {
        width: 60%;
        stroke: $ui-gray-medium;
    }
}

.productRequest {
    ul > li {
        font-family: $brand-font-family;
        font-weight: 500;
    }

    h4 {
        font-size: 1.25rem;
    }

    h5 {
        font-size: 1rem;
        font-weight: 500;
    }

    .terms-mb {
        font-family: $brand-font-family;
        margin-bottom: 30px;
        font-weight: 500;
    }

    .description-mb {
        margin-bottom: 30px;
    }

    .terms-link {
        background: $brand-color-accent;
        color: white;
        font-size: 0.9rem;

        &:hover {
            color: $ui-gray-ultra-light;
        }

        + p {
            background: $brand-color-red;
            color: white;
            padding: 5px;
            text-align: center;
            border-radius: 3px;
            margin-top: 30px;
            font-size: 0.8rem;
        }
    }

    .frontID,
    .passport,
    .reverseID,
    .LifeTest {
        width: 50%;
        height: 50%;
        margin: 60px auto;
    }

    @include respond-to(desktop) {
        .credit-card-justify > section:first-child {
            margin-top: 100px;
        }

        .credit-card-main > form.above-the-fold {
            overflow: auto;
        }

        .onboarding-text-align-center.text-lead {
            text-align: center;
        }
    }
}

.enrollment-layout .app-page .onboarding-documents {
    .btn-block + .btn-block:not(.btn-image) {
        margin-top: 0;
    }

    .onboarding-container {
        gap: 100px;
        margin-bottom: 80px;
    }

    .container > .justify-content-center {
        display: flex;
        flex-wrap: wrap;
    }
}

.ob-step1-header {
    .view-title span {
        margin-left: -64px;
    }
}

// Onboarding Layout Mobile

@include respond-to(mobile) {
    .onboarding-documents .onboarding-container {
        display: block;
        width: 84%;

        .onboarding-card {
            justify-content: row;
            height: 200px;
            flex-direction: row;

            svg {
                width: 100px;
                height: 100px;
            }
        }
    }

    .onboarding-layout {
        .list-icon-container {
            .list-item--has-icon div.bullet-icon {
                margin-right: auto;
            }
        }
    }
}

// iPhone 6/7/8 Plus media querys

@include respond-to(mobile) {
    .productRequest {
        .credit-card-main {
            flex-direction: column;
            height: 50%;
        }

        .swiper-button-prev.btn,
        .swiper-button-next.btn {
            display: none;
        }

        .swiper-slide {
            position: relative;
            opacity: 1;
            height: 166px;
            background-position: 50%;
            transform: translate(0) !important;

            &-active {
                transform: scale(1.3) !important;
            }
        }

        .credit-card-info {
            width: 100%;
        }

        .btn-mb {
            margin-bottom: 2.5rem;
        }

        .terms-mb {
            margin-bottom: 1rem;
        }

        .onboarding-documents .onboarding-container .onboarding-card svg {
            width: 125px;
            height: 125px;
        }

        .title h1 {
            font-size: 3em;
        }

        .onboarding-container {
            display: flex;
            flex-flow: row wrap;
        }

        .onboarding-documents .onboarding-card {
            flex-direction: row;
            height: 250px;
        }

        .ID-btn {
            margin-top: 2em;
            width: 80%;
        }

        .credit-card-text {
            width: 100%;
        }

        video {
            margin: auto;
        }

        .text-uppercase {
            text-transform: none;
        }

        .documents-presentation img {
            margin-bottom: 40px;
        }
    }
}
