.table-data--truncate {
    width: 50%;

    .newProduct-cell--ellipsis {
        overflow: hidden;

        display: flex;
        flex-direction: column;

        text-overflow: ellipsis;
    }
}

.table-data.ellipsis {
    .data-text {
        @include ellipsis;
    }
}


.widget .table--products .col {
    padding: 0;
}

.table--products {
    border-collapse: separate;
    border-spacing: 0 1rem;
    padding-right: 15px;
    padding-left: 15px;


    
    .table-row {
        border-radius: 5px;

        //background-color: lighten($brand-primary, 61%);

        &.is-expired {
            background-color: lighten($brand-danger, 35%);
        }

        .table-data {
            border: none;

            &:first-child {
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }

    .table-data {
        max-width: 0;
    }

    .table-data .newProduct-row {
        margin: 0.25rem 0;

        &.newProduct-row--header {
            align-items: center;

            margin-bottom: 0;
        }

        .newProduct-cell--icon {
            flex: 0;

            padding-right: 0.5rem;
        }

        .newProduct-cell--ellipsis {
            flex: 1;
        }

        // TODO fix this component
        .data-desc {
            display: inline;
        }
    }
}

.newProduct {
    display: flex;
    flex-direction: column;

    height: 100%;
    padding: 0.75rem;
    @include respond-to(desktop) {
        margin:1rem 0rem;
    }

    border-radius: 5px;

    color: $gray;

    background-color: #ffffff;
    box-shadow: 0 4px 17px -2px #0000071f;

    @include respond-to(mobile) {
        display: inline-block !important;

        width: 100%;
    }

    &.is-expired {
        background-color: lighten($brand-danger, 35%);

        .data-name {
            color: $brand-danger;
        }
    }

    h4.data-name {
        white-space: initial;
        line-height: 25px;
        overflow: unset;
    }
}

.newProduct-cell {
    & > [class*=" data-"],
    & > [class^="data-"],
    & > .svg-wrapper {
        padding: 0 0.25rem;
    }

    .data-name {
        font-family: $font-family-base;
    }
}

.newProduct-row--header {
    flex-grow: 1;
}

.newProduct-row {
    display: flex;
    justify-content: space-between;

    width: 100%;

    &.newProduct-row--header {
        align-items: flex-start;

        margin-bottom: 0.5rem;
    }

    &:not(.newProduct-row--header) {
        align-items: flex-end;

        .newProduct-cell {
            display: flex;
            flex-wrap: wrap;

            &.text-right {
                align-items: baseline;
                justify-content: flex-end;

                // REVIEW dont understand if flexing cells is needed
                .data-label {
                    width: 100%;
                    padding-left: 2rem;
                }
            }
        }
    }
}

.newProduct-row:only-child {
    align-items: center;

    .newProduct-cell {
        display: block;
    }
}

.newProduct-cell {
    flex-grow: 1;
}

.newProduct-cell--ellipsis {
    min-width: 0;

    .product-title,
    .data-name {
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: $brand-font-family-semibold;
        font-weight: $brand-font-weight-semibold;
        font-size: 16px;
    }
}


.newProduct-row--header .svg-wrapper {
    float: right;
}

.newProduct-cell .data-tag {
    // REVIEW needed to align baseline in linebreak situation
    padding: 0.25rem;
}

.newProduct-relation {
    position: relative;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    width: auto;

    border-radius: 5px;

    background-color: lighten($gray-lighter, 10%);

    @include respond-to(mobile) {
        width: 100%;
    }

    > .svg-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate3d(-0.5rem, -0.5rem, 0);

        &,
        & svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .newProduct {
        flex-grow: 1;

        width: auto;
        max-width: 50%;

        background-color: transparent;

        .newProduct-row--header {
            margin-bottom: 0;
        }
    }
}

.newProduct-row {
    align-items: center;
}

.newProduct-cell--icon {
    flex-grow: 0;
}

@include respond-to(mobile) {
    .newProduct-card-mobile .newProduct-cell--icon {
        order: 1;
    }
}

.transfer-data .newProduct {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;

    .newProduct-row {
        margin-bottom: 0;
    }
}

.table--products {
    .table-body--grid {
        display: flex;
        flex-wrap: wrap;
    }
}