/* Slider */
@media (min-width: 768px) {
    .slick-slider-wrapper {
        //      display: flex;
    }
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus,
.slick-slide article:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track::before,
.slick-track::after {
    display: table;
    content: "";
}

.slick-track::after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    // float: left;
    height: 100%;
    min-height: 1px;
}

// [dir="rtl"] .slick-slide {
//     float: right;
// }

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: inline-block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover::before,
.slick-prev:focus::before,
.slick-next:hover::before,
.slick-next:focus::before {
    opacity: 1;
}

.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
    opacity: 0.25;
}

.slick-prev::before,
.slick-next::before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 0.5rem;
}

[dir="rtl"] .slick-prev {
    right: 0.5rem;
    left: auto;
}

.slick-prev::before {
    content: "←";
}

[dir="rtl"] .slick-prev::before {
    content: "→";
}

.slick-next {
    right: 0.5rem;
}

[dir="rtl"] .slick-next {
    right: auto;
    left: 0.5rem;
}

.slick-next::before {
    content: "→";
}

[dir="rtl"] .slick-next::before {
    content: "←";
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    bottom: 0;
    display: flex !important;
    justify-content: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    // margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    border-radius: 50%; // background: red;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:hover::before,
.slick-dots li button:focus::before {
    opacity: 1;
}

.slick-dots li button::before {
    font-family: "slick";
    font-size: 1.5rem;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    // color: #48b7d5;
    color: $gray-darker;
    opacity: 0.2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button::before {
    // color: orchid;
    color: $gray-darker;
    opacity: 1;
    transform: scale(1.5);
}

.slick-slide {
    >div {
        display: flex;
        padding: 0 0 1.5rem;
    }
}

.slick-dots {}

.slick-slide {
    .data-label {
        transition: transform 0.3s ease;
    }

    .data-amount {
        transition: transform 0.3s ease;
    }
}

.slick-dots {
    transition: opacity 0.3s ease;
}

/**
 * Solucion trancitoria para outline en foco del carrousel
 */
.slick-slide:hover {
    cursor: pointer;
}

.slick-slide div[tabindex="-1"] {
    cursor: pointer;

    &:focus {
        position: relative;

        outline: none;
    }
}