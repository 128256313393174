.billpay-add-payee {
    position: relative;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    .heading {
        @include fully-centered-row;

        .separator {
            padding: 0 3px;
            margin: 0;

            &:before {
                content: "-";
                display: inline-flex;
            }
        }
    }

    .billpay-logo {
        padding: 8px 0;
    }

    .billpay-addpayee-form {
        .form-group {
            max-width: 390px;
        }
        .form-content {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .add-payee-buttons {
        .container {
            div {
                div{
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

    }
    .billpay-add-payee-form {
        .content-wrapper,
        .form-footer {
            @include fully-centered-row;
        }

        .content-wrapper {
            .fields-wrapper {
                @include fully-centered-col;
            }
        }
    }

    .billpay-add-payee-summary {
        @include row-v_start-h_space_between;
        padding: 20px 40px;

        > div {
            @include col-v_start-h_start;
            width: 50%;
        }

        p {
            @include col-v_start-h_start;
            margin-bottom: 20px;

            .summary-label {
                color: $brand-color-black;
                font-weight: bold;
            }

            .summary-value {
                color: $brand-color-black;
            }
        }
    }

    .new-modal-wrapper {
        
        .new-modal {
            @include fully-centered-col;
            width: 470px;
            padding: 1em;
            background-color: #ffffff;

            .new-modal-header {
                border-bottom: none;
                font-family: $brand-font-family;
                font-size: 14px;
                
                .btn {
                    font-size: 1.3em;
                    outline: none;
                    border: none;
                    color: $brand-color-base !important;
                    height: 0.5em;

                    &:hover, &:active {
                        background-color: #ffffff;
                        color: $brand-color-base !important;
                        outline: none;
                        border: none;
                    }
                }
            }

            .description {
                text-align: center;
            }

            article {
                padding: 0;

                .btns-wrapper {
                    @include fully-centered-col;
                    padding: 10px 0;
                    margin-top: 3em;

                    &.btn-block + .btn-block { 
                        margin: 0;
                    }

                    .justify-content-center.row{
                        > button{
                            margin: 0 !important;
                        }
                    }

                    .btn {
                        text-transform: uppercase;
                        height: 59px;
                        box-shadow: none;
                        font-size: 16px;

                        .btn-secondary {
                            box-shadow: none;
                        }

                        &:hover, &:active {
                            color: $brand-color-base !important;
                        }
                    }

                    .link {
                        margin-top: 1em;
                        text-decoration: none;
                        color: $brand-color-base;
                        font-weight: normal;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .billpay-add-payee {
        .billpay-add-payee-form {
            .content-wrapper,
            .form-footer {
                @include fully-centered-col;
            }
        }
    }

    .billpay-summary {
        .billpay-summary-content-wrapper {
            .billpay-summary-content {
                .billpay-summary-content-body {
                    .billpay-add-payee-summary {
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        > div {
                            @include col-v_start-h_start;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 844px) {
    .billpay-add-payee {
        .new-modal-wrapper {
            padding: 0;

            .new-modal {
                .new-modal-header {
                    padding-bottom: 1em;
                }
            }
        }
    }
}