.enrollment-layout {
    .navbar-header{
        .svg-icon{
            height: auto;
        }
    }
    .navbar-brand {
        svg{
            height: 100px;
        }
        path{
            fill: $brand-color-base;
        }
    }
    .view-page{
        // min-height: 100%;
    }
    .view-title {
        text-align: center;
    }

    .app-page .justify-content-center {
        justify-content: center;
    }
    .view-page .terms-and-conditions-style{
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        .form-content.container {
            max-width: 100%;
        }
        .modal-header{
            border-bottom: 0;
            padding-top: 0;
            h1{
                margin-top: 0;
            }
        }
        .modal-body{
            min-height: 300px;
            padding: 0;
        }
        .modal-footer {
            .col{
                display: flex;
                justify-content: flex-end;
            }
            .btn + .btn{
                margin-top: 12px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .theme-auth .form-group--composite .input-group .Select {
        min-width: 200px;
        z-index: 1000;
        border-right: none;
    }
    p{
        font-size: 14px;
        font-weight: 600;
    }
    ul{
        color: $brand-color-base;
        padding-left: 20px;
        margin: 0;
        > li span{
            color: rgb(20, 15, 15);
            font-size: 12px;
        }
    }
    h1{
        font-size: 20px;
        line-height: 28px;
        text-align: center;
    }
    .btn-secondary{      

        margin-top: 10px;
        @media (min-width: 2320px) {
            margin-top: 0;
            margin-left: 20px;
        }

    }

    .btn.btn-block {

        &.btn-primary{
            &:hover{
                color: $brand-color-primary;
                background-color: $ui-white;
            }
        }

        &.btn-secondary{
            margin-top: 20px;

            &:hover{
                color: #222222;
                background-color: $ui-white;
            }
        }
    }
    .container--layout {
        margin-top: 0;
    }
    .align-items-center.container--layout .justify-content-center.row {
        text-align: center;
        section{
            margin: 20px 0;
            @include respond-to(mobile) {
                margin: 0;
            }
        }
    }

    .form-content {
        max-width: 390px;
        padding: 0;
        .col {
            padding: 0;
        }
        .row{
            margin: 0;
            width: 100%;
            > div{
                width: 100%;
            }
        }
        .form-group{
            text-align: left;
            max-width: 390px;
            margin-left: auto;
            margin-right: auto;
        }
        button {
            &.btn-outline {
                margin: 1rem 0;

                .svg-wrapper {
                    margin-right: 0.5em;
                }
            }
        }

        .steps-path {
            display: flex;
            margin: 0 0 2em 0;
            padding: 0;
            list-style: none;

            li {
                height: 10px;
                background-color: lighter($brand-primary, 60%);
                flex-grow: 1;
                margin-right: 0.5em;
                border-radius: 3px;
            }

            li:last-child {
                margin-right: 0;
            }

            li.active {
                background-color: $brand-primary;
            }
        }
        .control-label{
            span{
                display: inline;
            }
        }
        .tooltip2{
            display: inline;
            .svg-wrapper{
                width: 10px;
                height: 10px; 
            }
            // position: absolute;
            // .svg-wrapper{
            //     position: absolute;
            //     top: 0;
            //     right: 24px;
            //     width: auto;
            // }
        }
    }
    .container-fluid .navbar-header .btn-outline {
        width: 15%;
        float: right;
        margin: 1.25rem 0rem;
    }

    form {
        h1 {
            margin-bottom: 24px;
            font-family: $brand-font-family-bold;
            font-weight: $brand-font-weight-bold;
            @include respond-to(desktop) {
                @media(min-height: 800px){
                    margin-top: 60px; 
                }
            }
        }
    }
    .form-content .text-lead {
        text-align: center;
        color: $gray-darker;
    }

    .first-step .form-group {
        padding-top: 1em;
    }

    .second-step button.btn.btn-primary {
        margin-top: 1.5em;
    }

    .form-content .progress-wrapper {
        margin-bottom: 1em;
    }

    .progress {
        height: 8px;
        margin-bottom: 20px;
        margin-top: 33px;
        border: none;
        border-radius: 30px;
        box-shadow: none;
        background: $ui-gray-light;
        max-width: 390px;
        margin-right: auto;
        margin-left: auto;
        @include respond-to(mobile) {
            margin-bottom: 12px;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
            width: 40%;
        }
    }

    .form-content .progress-label {
        margin: 0;
        position: inherit;
        color: $ui-color-success;
    }

    .form-content .successAlert .alert-success {
        height: 5px;
        background-color: lighter($brand-primary, 60%);
        margin-bottom: 2em;
        padding: 0;
    }

    .form-group--image-selector {
        .container-image {
            display: flex;
            align-items: center;
        }

        .btn-outline {
            width: auto;
        }

        .image {
            flex-basis: 5rem;
            min-width: auto;
            margin: 0;
            position: initial;
            align-items: center;

            @include respond-to(mobile) {
                margin-right: 1rem;
            }

            @include respond-to(mobile) {
                margin-bottom: 1rem;
            }
        }

        .image figure {
            width: 5rem;
            height: 5rem;
            margin: auto;

            img {
                background-color: transparent;
            }
        }
    }

    .image-grid {
        // margin-top: 2rem;

        .image-grid-item {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
    .error-page {
        h2 {
            color: $list-item--color--error;
        }

        .list-icon-container .list-item--has-icon .bullet-icon .svg-wrapper svg {
            color: $list-item--icon-color--error;
            border: 3px solid currentColor;
        }
    }
    .success-page {
        h2 {
            color: $list-item--color--success;
        }
    }

    .media-object{
        align-items: center;
    }
    .list-icon-container {
        @include respond-to(mobile) {
            padding: 0;
        }

        .list-item--has-icon {
            margin: auto auto 1em;
            text-align: center;

            .bullet-icon {
                position: unset;
                margin-right: 0.5rem;

                font-size: 0.875em;
                line-height: 1.5;

                .svg-wrapper {
                    height: 1.5rem;
                    width: 1.5rem;
                    margin-top: 0.5rem;
                    border: 2px solid currentColor;
                    border-radius: 50%;
                    color: $list-item--icon-color--success;
                    align-items: unset;

                    svg {
                        padding: 0.1em;
                    }
                }
            }
        }
    }

    .list-text {
        margin-left: 1em;
    }

    .container-fluid {
        .btn.btn-outline {
            align-self: flex-end;
            width: auto;
            margin: 1.25rem 0rem;
        }
    }

    .security-image-enrollment {
        .image {
            min-width: initial;
            flex-basis: initial;
            flex-grow: initial;

            figure {
                width: 8rem;
                height: 8rem;
            }
        }
        .security-image > div + div {
            margin-left: 2rem;
        }
    }

    .onboarding-loading {
        h2 {
            text-align: center;
            color: $brand-primary;
            margin-top: 60px;
        }
    }
    .above-the-fold {
        justify-content: space-evenly;
    }

    @include respond-to(desktop) {
        background-size: cover;

        .view-header,
        .view-header > .navbar {
            background: transparent;
        }

        .theme-auth .view-page::after {
            background: none;
        }
    }
    .powered-by-footer{
        bottom: 20px;
        right: auto;
        left: auto;
    }
    .terms-and-conditions-style{
        padding: 0;
        @include respond-to(mobile) {
            .modal-container {
                padding-top: 50px;
            }
        }
    }

    @include respond-to(mobile) {
        .confirmation-page{
        }
        .toolbar{
            .toolbar-item--fixed{
                margin-top: -47px;
            }
        }
    }
    .powered-by-footer{
        padding-top: 16px;
    }
}

.camera-layout {
    .overlay {
        position: fixed;
        background-color: $camera-overalay--background-color;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -1;
    }

    .view-header {
        background-color: transparent;
        .navbar {
            background-color: transparent;
        }
    }

    h1,
    h2,
    p {
        color: white;
    }

    .form-content .steps-path {
        display: flex;
        margin: 0 0 2em 0;
        padding: 0;
        list-style: none;

        li {
            height: 10px;
            background-color: rgba($color: white, $alpha: 0.5);
            flex-grow: 1;
            margin-right: 0.5em;
            border-radius: 3px;

            &.active {
                background-color: white;
            }
        }

        li:last-child {
            margin-right: 0;
        }
    }

    .btn-picture {
        background-color: transparent;
        border: none;
        margin: auto;
        display: block;
        margin-top: 40px;

        svg {
            width: 60px;
            height: 60px;
        }
    }

    .phone-icon svg {
        width: 60%;
        margin: 20px auto;
    }

    .selfie-icon svg {
        width: 50%;
        margin: 20px auto;
    }
}
@include respond-to(mobile) {
    .enrollment-layout {
        .view-header{
            background-color: transparent;
            max-height: 100% !important;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .navbar.navbar-default{
                box-shadow: none;
                background-color: transparent;
            }
            .toolbar{
                .view-title{
                    top: 90px
                }
            }
        }
        .view-page{
            .view-content{
                .main-container{
                    align-items: flex-start;
                    padding: 0;
                    & > * {
                        margin-top: 0 !important;
                    }
                    form.above-the-fold{
                        padding-top: 110px;
                    }
                    &.onboarding-layout, .above-the-fold{
                        background-color: $ui-white;
                    }
                    &.camera-page{
                        .above-the-fold{
                            background-color: transparent;
                        }
                    }

                    .modal-container {
                        &.modalFeeSchedule {
                            margin-top: 140px !important;
                            @include respond-to(mobile){
                                margin-top: 90px !important;
                                width: 390px;
                                .form-content {
                                    margin-left: 1px;
                                    margin-right: 1px;
                                }
                            }
                        }
                    }
                    .modal-footer {
                        padding: 0;
                        .col{
                            padding: 0;
                        }
                    }
                }
            }
        }
        .onboarding-documents{
            background-color: $ui-gray-ultra-light;
        }
        
        .list-text {
            width: 100%;
            margin-left: 0;
            text-align: center;

            p:first-child {
                font-weight: bold;
            }
        }

        .list-icon-container {
            .list-item--has-icon {
                .bullet-icon {
                    float: none;
                    width: 2.5rem;
                    margin: auto;
                    position: static;

                    .svg-wrapper {
                        height: 2.5rem;
                        width: 2.5rem;
                        margin: auto;

                        svg {
                            align-self: center;
                            height: 1.5rem;
                            width: 1.5rem;
                        }
                    }
                }
            }
        }

        .message {
            .list-icon-container {
                display: block;
            }

            h2,
            p {
                text-align: center;
            }
            &.error-page {
                h2 {
                    color: $list-item--color--error;
                }

                .bullet-icon .svg-wrapper svg {
                    color: $list-item--color--error;
                    border: 3px solid $list-item--icon-color--error;
                }
            }

            &.success-page {
                h2 {
                    color: $list-item--color--success;
                }
            }
        }

        .check-list .c-control-label {
            font-size: 1.25rem;
            text-align: center;
        }
    }
}

.list-item--has-icon {
    position: relative;

    display: block;

    .bullet-icon {
        position: absolute;
        left: -1.5em;

        font-size: 0.875em;
        line-height: 1.5;
    }
}

.enrollment-layout .image-grid .image-grid-item{
    flex: 0 0 33.33%;
    max-width: 33.33%;
    @include respond-to(mobile){
        flex: 0 0 23%;
        max-width: 23%;
        margin-right: 5%;
        margin-left: 5%;
    }
}

.phone-number-container{
    display: inline;
    margin-left: 5px;
    white-space: nowrap;
}

#sameAddress {
    padding-left: 1px;
}

.onboarding-success {
    .above-the-fold {
        padding-top: 100px;
        .media-object--state-success {
            h2 {
                padding-top: 40px;
                color: $brand-color-black;
            }
            h4 {
                font-size: 16px;
                font-family: $brand-font-family-semibold;
                font-weight: $brand-font-weight-semibold;
                line-height: 25px;
            }
        }
    }
}