//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
    display: inline-block;
    padding-left: 0;
    margin: $line-height-computed 0;
    border-radius: $border-radius-base;

    > li {
        display: inline; // Remove list-style and block-level defaults
        > a,
        > span {
            position: relative;
            float: left; // Collapse white-space
            padding: $padding-base-vertical $padding-base-horizontal;
            line-height: $line-height-base;
            text-decoration: none;
            color: $pagination-color;
            background-color: $pagination-bg;
            border: 1px solid $pagination-border;
            margin-left: -1px;
        }
        &:first-child {
            > a,
            > span {
                margin-left: 0;
                @include border-left-radius($border-radius-base);
            }
        }
        &:last-child {
            > a,
            > span {
                @include border-right-radius($border-radius-base);
            }
        }
    }

    > li > a,
    > li > span {
        &:hover,
        &:focus {
            z-index: 2;
            color: $pagination-hover-color;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border;
        }
    }

    > .active > a,
    > .active > span {
        &,
        &:hover,
        &:focus {
            z-index: 3;
            color: $pagination-active-color;
            background-color: $pagination-active-bg;
            border-color: $pagination-active-border;
            cursor: default;
        }
    }

    > .disabled {
        > span,
        > span:hover,
        > span:focus,
        > a,
        > a:hover,
        > a:focus {
            color: $pagination-disabled-color;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border;
            cursor: $cursor-disabled;
        }
    }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
    @include pagination-size(
        $padding-large-vertical,
        $padding-large-horizontal,
        $font-size-large,
        $line-height-large,
        $border-radius-large
    );
}

// Small
.pagination-sm {
    @include pagination-size(
        $padding-small-vertical,
        $padding-small-horizontal,
        $font-size-small,
        $line-height-small,
        $border-radius-small
    );
}
