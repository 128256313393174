.scheduled-transactions-report {
    @include fully-centered-col;
    width: 100%;
    padding-top: 1em;
    margin-bottom: 30px;

    .heading {
        @include row-v_center-h_space_between;
        width: 100%;

        a.highlighted-link {
            color: $brand-color-terciary;
            font-weight: bold;
            text-transform: uppercase;
            padding: 0 2em 0.5em 2em;
    
            &:hover, &:active, &:visited {
                color: $brand-color-terciary;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .scheduled-transactions-report-content-wrapper {
        width: 100%;
        padding: 3em 2.5em;
        background-color: #E6F4F3;
        margin-bottom: 20px;

        .scheduled-transactions-report-content {
            position: relative;
            background-color: #ffffff;
            border: 2px solid #FDE9BB;
            -webkit-box-shadow: 0px 13px 15px 5px #AFDCD7; 
            box-shadow: 0px 13px 15px 5px #AFDCD7;

            .scheduled-transactions-report-content-body {
                width: 100%;
                padding: 1.5em;

                .scheduled-transactions-list {
                    width: 100%;

                    .scheduled-transactions-list-heading {
                        width: 100%;
                        margin-bottom: 1em;
                        text-transform: uppercase;
                    }

                    .scheduled-transactions-list-item {
                        @include row-v_center-h_space_between;
                        width: 100%;
                        border-bottom: 2px solid $ui-gray-ultra-light;

                        p {
                            @include col-v_center-h_start;
                            font: $brand-font-family;
                            width: 33%;
                            padding-right: 0.8em;

                            span {
                                font: $brand-font-family;

                                &.regular {
                                    color: $brand-color-black;
                                }
        
                                &.upper-case {
                                    text-transform: uppercase;
                                }

                                &.highlighted {
                                    font-weight: bold;
                                }
        
                                &.primary {
                                    color: $brand-color-primary;
                                }
        
                                &.secondary {
                                    color: $brand-color-black;
                                }
        
                                &.tertiary {
                                    color: $brand-color-black;
                                }
                            }

                            .btn-icon, .btn, .btn-default {
                                padding: 0;
                                margin: 0.25em 0 0 0;
                                width: auto;
                                border: none;
                                color: $brand-color-primary;
                                font-size: 1.25em;

                                > * {
                                    margin: 0;
                                }

                                &:hover {
                                    background-color: transparent;
                                }
                            }

                            &:last-of-type {
                                @include col-v_center-h_end;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.scheduled-transaction-details {
    width: 100%;
    padding: 3em 2.5em;
    background-color: #E6F4F3;
    margin-bottom: 20px;

    .scheduled-transaction-details-wrapper {
        position: relative;
        background-color: #ffffff;
        border: 2px solid #FDE9BB;
        -webkit-box-shadow: 0px 13px 15px 5px #AFDCD7; 
        box-shadow: 0px 13px 15px 5px #AFDCD7;

        .scheduled-transaction-details-header {
            @include row-v_center-h_end;
            padding: 1em;

            .btn-delete, .btn, .btn-default {
                width: auto;
                padding: 0;
                margin: 0;
                border: none;
                color: $brand-primary;

                &:hover {
                    background-color: transparent;
                }

                > * {
                    margin: 0;
                }
            }
        }

        .scheduled-transaction-details-body {
            padding: 0 1.5em;

            p {
                @include col-v_center-h_start;
                font-family: $brand-font-family;
                margin-bottom: 1em;

                span {
                    font: $brand-font-family;

                    &.custom-label {
                        color: $ui-gray-medium;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 85%;
                    }

                    &.custom-value {
                        color: $ui-gray-base;
                        font-weight: bold;
                    }
            
                    &.uppercase {
                        text-transform: uppercase;
                    }
            
                    &.highlighted {
                        font-weight: bold;
                    }

                    &.italic {
                        font-style: italic;
                    }
            
                    &.primary-color {
                        color: $brand-color-primary;
                    }
            
                    &.secondary-color {
                        color: $brand-color-black;
                    }
            
                    &.tertiary-color {
                        color: $brand-color-black;
                    }
                }
            }
        }

        .scheduled-transaction-details-footer {
            @include row-v_center-h_end;
            padding: 1em;

            a.highlighted-link {
                color: $brand-color-terciary;
                font-weight: bold;
                text-transform: uppercase;
        
                &:hover, &:active, &:visited {
                    color: $brand-color-terciary;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .scheduled-transactions-report {
        .heading {
            @include row-v_center-h_space_between;
            width: 100%;
    
            a.highlighted-link {
                color: $brand-color-terciary;
                font-weight: bold;
                text-transform: uppercase;
                padding: 0;
            }
        }

        .scheduled-transactions-report-content-wrapper {
            width: 100%;
            max-width: 100%;
            padding: 2em 1em;
            margin: 0.5em 0 0 0;
        }
    }
}