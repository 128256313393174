// These vars were populated by brand

.text-lead,

.form-group-label {
    font-weight: normal;
    font-size: 1.2em;

}

.control-label {
    .text-optional {
        font-weight: bold;
    }
}

.login-background {
    .form-group.has-focus {
        .input-group {
            box-shadow: 0 0 0 2px #d6be6e;
        }
    }
}

a.btn {
    text-decoration: none;
}

.app-page {
    transition: all 0.3s ease;
}

.app-footer {
    p {
        margin: 0;
    }
}

.text-lead,
.view-title {
    color: white;
    text-align: center;
}

.view-title .navbar-pt-logo{
    padding: 0;
    margin-top: -10px;
    @include respond-to(mobile){
        svg{
            height: 80px;
        }
    }
}

.text-lead {
    margin: 0.375em 0 1.25rem 0;
}

.text-lead{
    span{
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: $brand-color-black;
    }

    strong{
        font-size: 16px;
    }
}

.text-black {
    color: black;
    margin: 0.375em 0 1.25rem 0;
    text-align: center;
    font-family: $brand-font-family;
}

label,
input {
    font-size: 1rem;
}

small {
    font-size: 0.75rem;
}

.btn {
    padding: $btn--padding;
    border-radius: $btn--border-radius;
    font-size: $btn--font-size; //$btn--font-size;
    font-weight: $btn--font-weight;
    text-transform: $btn--text-transform;
    width: auto;
    height: $btn--height;
}

.btn-small {
    padding: 0.5em;
}

.btn-secondary{
    background-color: $brand-color-base;
    border-color: $brand-color-base;
    border-width: 2px;
    color: $ui-white;
    width: $btn-primary--width;
    height: $btn-primary--heigth;
    &:hover{
        color: #222222;
        background-color: $ui-white;
    }
    .svg-wrapper svg path{
        fill: $brand-color-base;
    }
}

.btn-secondary{
    background-color: $ui-white;
    border-color: $brand-color-secondary;
    border-width: 1px;
    color: $brand-color-secondary;
    width: $btn-primary--width;
    height: $btn-primary--heigth;
    &:hover{
        color: #222222;
        background-color: $ui-white;
    }
    .svg-wrapper svg path{
        fill: $brand-color-base;
    }
}

.btn-image {
    position: relative;
    overflow: hidden;

    width: 100%;
    padding: 0;
    padding-top: 100%;

    border: none;
    border-radius: 50%;

    background-color: transparent;
    background-position: center;
    background-size: cover;
    box-shadow: none;

    &.btn-avatar{
        border: 2px solid white;
        border-right: none;
        border-left: none;
    }

    &.btn-avatar:focus {
        border: 1.5px solid $brand-color-primary;
    }

    &.btn-avatar:active {
        border: 1.5px solid $brand-color-primary;
    }

    &.btn-avatar:hover {
        border: 1.5px solid $brand-color-primary;
    }
    span {
        position: absolute;

        opacity: 0;
    }
}


.btn.btn-block {
    width: 100%;
    &.btn-primary{
        &:hover{
            color: $brand-color-primary;
            background-color: $ui-white;
        }
    }
}

.app,
.view-wrapper {
    color: $app--color;

    

    @include colorize-scope($theme-colors: ("primary": $app-page--primary-color,
    ));

    .text-danger {
        color: $ui-color-text--error;
    }

    a {
        color: $app-page-link--color; //z-index: 101;
        
        &:hover,
        &:focus {
            color: $brand-color-primary;
        }
    }

    .btn-primary {
        // se pisa el color por que segun color-yiq deberia de ser oscuro
        color: white;
    }
}

.text-danger {
    color: $ui-color-text--error;
}

@include colorize-scope($theme-colors: ("primary": $app-page--primary-color,
));

a {
    color: $app-page-link--color; //z-index: 101;
    text-decoration: none;
    // padding: 0;
    &:hover,
    &:focus {
        color: darken($app-page-link--color, 10%);
    }
}


.container--bottom {
    background-color: $container-bottom--background-color;

    @include when-inside(footer) {
        background-color: transparent;
    }
}

.app-footer {
    a {
        color: white;
    }

    .legal-nav {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;

        height: 100%;
        margin-bottom: 0;

        list-style: none;

        li a {
            font-size: 0.8rem;

            svg {
                height: auto;
                max-width: 2rem;
                margin-right: 0.5rem;
            }
        }

        li:not(:last-child) {
            margin: 0 auto;
            padding-right: 9rem;
        }
    }
}

.form-control {
    box-shadow: none;
}

.app-footer {
    border: none;
}

.link-forgot-password{
    display: flex;
    justify-content: space-between;

    a{
        color: $brand-color-maroon;
        
        span{
            border-radius: 5px;
            color: $brand-color-maroon;
        }
    }
}

.link-unlock-account{
    text-align: right;
    a{
        span{
            border-radius: 5px;
            color: $brand-color-maroon;
        }
    }
}

.link-forgot-password-desktop{
    display: flex;
    justify-content: space-between;

    a{  
        color: $brand-color-maroon;
        span{
            border-radius: 5px;
        }
    }
}

.link-unlock-account-desktop{
    text-align: right;
    a{  
        color: $brand-color-maroon;
        span{
            border-radius: 5px;
        }
    }
}

.username-div-user-locked {
    margin-bottom: 0px !important;
}


.username-user-locked {
    border-color: $brand-color-maroon !important;
    border-style: solid !important;
    margin-bottom: 0px !important;
}

.user-locked-alert-message {
    color: $brand-color-maroon;
    padding-top: 0px;
    font-size: 13px;
    font-style: italic;
    margin-top: 0px;

    .svg-wrapper {
        width: unset;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}

.user-notification-alert-section-start {
    background-color: $brand-color-maroon;
    width: 10px;
    height: 100%;
}

.user-notification-alert-section {
    color: white;
    width: 100%;
    background-color: $brand-color-primary;
    display: flex;
    border-width: 1px;
    border-style: solid;
    font-family: $brand-font-family;
    position: absolute;
    z-index: 100;

    .svg-wrapper {
        
        @include respond-to(desktop) {
            width: 70px;
        }

        @include respond-to(mobile) {
            width: 100px;
            padding-left: 10px;
            padding-right: 10px;


            svg {
                z-index: 1;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                .cls-1 {
                        stroke:none;
                    }
            }
        }
    }

    span {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 20px;
    }
}

.input-group {
    border: none;
}

// revisar el scope para este elemento
body {
    .c-control--switch {
        .c-control-label {
            font-size: 25px;

            // width: 100%;
            &::after {
                width: 0.6em;
                height: 0.6em;
                margin: 0.3em;

                border-radius: 50%;

                content: "";

                background-color: white;
            }

            &::before {
                border: none !important;

                background-color: $ui-color-field;
                box-shadow: none;
            }

            .form-group-text{
                font-size: 17px;
            }
        }

        .c-control-input:checked ~ .c-control-label {
            &::before {
                background-color: $brand-color-accent;
            }
        }

        margin-bottom: 30px;
    }

    .c-control-input:focus ~ .c-control-label {
        &::before {
            // border: none;
            box-shadow: none;
            outline: 0;
        }
    }
}

.btn-link {
    border: none;

    color: $brand-color-accent !important; //$brand-color-accent !important;

    background-color: transparent;

    &:hover {
        color: white !important; // color-yiq($brand-color-accent; //$brand-color-accent) !important;
        text-decoration: none;

        background-color: $brand-color-accent; //$brand-color-accent;
    }
}

.table-row .svg-wrapper {
    color: $brand-primary; // $brand-color-accent;
}

.table-head {
    display: table-header-group;
}

.table-head .table-data {
    border-width: 2px;

    color: $brand-primary;
}

.data-name {
    color: $brand-primary;
}

.product-item {
    .data-aux {
        color: white;

        background-color: $brand-color-secondary; // #49ccbd este color seria para app--auth
    }

    .data-name {
        color: $brand-primary;
    }
}

/* --------- */

/* Menu-list */

/* --------- */

.app-sidebar {
    background-color: $ui-white;
}

.menu-list {
    .menu-list-item {
        border-bottom: 1px solid white;

        li:hover > a {
            background-color: #8f88aa;
        }

         > a,
         > span {
            padding: 15px 15px 15px 55px;

            color: $navbar-default-color;
            text-decoration: none;
            white-space: normal;
            font-weight: bold;
            font-family: 'Open Sans';
            cursor: pointer;

            &:hover,
            &:focus {
                color: white;
                background-color: $brand-primary;
                .svg-wrapper svg path{
                    fill: white;
                }
            }
        }
        &.has-focus {
            > a,
            > span {
                color: white !important;
                font-weight: bold;
                background-color: $brand-primary;
            }
            .svg-wrapper svg path{
                fill: white;
            }
        } 

        .svg-wrapper{  
            color: $brand-primary;         
        }
    }

    .dropdown.open {
        .dropdown-menu {
            padding: 0;
            margin: 0;

            li {
                border-bottom: 1px solid rgba(256, 256, 256, 0.4);
                &.has-focus {
                    color: white;
                    background-color: $brand-color-accent;
                    a > span {
                        color: white;
                        background-color: $brand-primary;
                    }
                }

                 > a,
                 > span {
                    padding: 0.75em 2em 0.75em 3.5rem;

                    font-size: 0.875rem;
                    line-height: 1.4;
                    text-decoration: none;

                    background-color: transparent;

                    @include respond-to(desktop) {
                        color: $navbar-default-color;
                        background-color: transparent;

                        span{
                            color: $navbar-default-color;
                        }
                    }
                    &:hover {
                        color: white;
                        background-color: $brand-color-accent;

                        @include respond-to(desktop) {
                            color: white;
                            background-color: $brand-color-accent;
                        }
                    }

                    &:active{
                        color: white;
                    }
                }
            }

            > li > a:hover > span{
                color: white;
            }
        }
    }
}


.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    border: none;
    color: white;
    background-color: $brand-color-primary;

    .svg-wrapper{  
        color: white;         
    }
}

//mobile
@include respond-to(mobile) {
    .view-header .navbar-default .navbar-collapse {
        padding: 0;
        flex: 0 0 100%;
    }
}

// mobile
.view-header {


    @include respond-to(mobile) {
        &.no-buttons {
            .navbar.navbar-default {
                .toolbar{
                    flex-direction: column;
                    .svg-wrapper {
                        height: 60px;
                        width: 120px;
                        padding-top: 20px;

                    }
                }
            }
        }

        .navbar.navbar-default {
                box-shadow: $header-nav-shadow;
                margin-top: -12px;
                margin-right: -12px;
                margin-left: -12px;
            .navbar-header{
                margin-top: 0;
                margin-bottom: 0;
                h1{
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            ul.menu-list{
                padding-left: 0;
            }
            .toolbar {
                flex-direction: row;
                flex-wrap: wrap;
                .toolbar-item{
                    display: flex;
                    position: relative;
                }
                .btn{
                    padding: 20px;
                }
            }
        }
    }
    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        background-color: white;
    }

    .navbar-toggle {
        .icon-bar {
            background-color: $brand-primary;
        }

        &:hover,
        &:focus {
            background-color: transparent;

            .icon-bar {
                background-color: darken($brand-primary, 10%);
            }
        }
    }
}

/* --------------- */

/* Header-commands */

/* --------------- */

/* ------------ */

/* View Toolbar */

/* ------------ */

.btn.btn-only-icon {
    color: white;

    &:hover,
    &:focus,
    &:active,
    &:active:hover {
        color: white;
    }
}

.btn.isLoading,
.btn.is-loading {
    background-color: darken($brand-color-accent, 10%);
}

/* -------- */

/* Controls */

/* -------- */

.check-list {
    .c-control-label {
        color: $checks-list-type-color;
    }

    .c-control-input:checked ~ .c-control-label {
        color: $checks-list-type-color-active;
    }
}



.view-header .navbar .navbar-header {
    @include respond-to(mobile) {
        margin-top: $m;
        margin-bottom: 0.5rem;
    }
}

.card-admin-options-header .navbar .navbar-header {
    margin-top: $l;

    @include respond-to(mobile) {
        margin-top: 0px;
    }
}

.content-heading {
    a {
        text-decoration: none;
    }
}

.digital-banking-services{
    text-align: center;
    color: $brand-color-primary;
    font-size: 1.7rem;

    @include respond-to(mobile) {
    font-size: 1.4rem;        
    }
}

.dbs-disclaimer{
    font-size: 1rem;
    text-align: center;
}

// Confirmation Page Styles

// .enrollment-layout .view-page .view-content .main-container.confirmation-page .above-the-fold{
//     @include respond-to(mobile) {
//         background-color: $brand-color-secondary;
//     }
// }

.view-page .view-content .confirmation-page.pay-any-day-styles{
    @include respond-to(mobile) {
        background-color: $brand-color-secondary;
        margin-right: -12px;
        margin-left: -12px;
        margin-bottom: -12px;
        .justify-content-center{
            text-align: center;
        }
    }
}

.confirmation-page, .confirmation-page.recovery-pass-styles, .confirmation-page.pay-any-day-styles{

    .above-the-fold{
        // span, .text-area, .text-center, a, h1, h2, h3, h4{
        //     @include respond-to(mobile) {
        //         color: $ui-white;
        //     }
        // }

        .btn.btn-primary{
            @include respond-to(mobile) {
                background-color: $brand-color-secondary;
                border-color: $ui-white;
                border-width: 2px;
                box-shadow: none;
    
                &:hover{
                    color: $ui-white;
                    background-color: $brand-color-secondary;
                }
            }
        }

        .form-content{
            .media-object.media-object--state-success{
                flex-direction: column;

                .media-object--text{
                    margin: 0;
                }
            }
        }
    }

    
    .media-object.media-object--state-success .svg-wrapper{
        background-color: transparent;

        @include respond-to(mobile){
            svg{
                path, circle{
                    stroke: $ui-white;
                }
            }
        }
    }

    .media-object--text{
        @include respond-to(mobile){
            h2 {
                color: white;
            }

            h3 {
                color: white;
            }
        }
    }

    .media-object--state-success{
        @include respond-to(mobile){
            h2 {
                color: white;
            }

            h3 {
                color: white;
            }
        }
    }

    .align-text-center-go-dashboard{
        @include respond-to(mobile){
            a {
                color: white;
            }
        }
    }
    

    @include respond-to(mobile) {
        .view-header .navbar.navbar-default{
            background-color: $brand-color-primary;
            opacity: 1;
        }

        header nav .toolbar .view-title .svg-wrapper{
            display: none;
        }

        .content-data-credit .content-data-wrapper .svg-wrapper svg{
            margin-left: 5px;
            width: 1.5rem;
            height: 1.5rem;
        }

    }

    .svg-wrapper{
        margin: 0 auto;
    }
}

//Terms and Conditions

.theme-auth.enrollment-layout .view-page{
    .terms-and-conditions-style{
        min-height: 600px;
        @media (min-width: 768px) {
            background-color: transparent;
        }

        @include respond-to(desktop){
            min-height: unset;
            max-height: unset;
            margin: auto;
            padding-top: 8%;
            padding-bottom: 8%;

            .modal-header{
                width: 75%;
                text-align: center;
            }
            .modal-container{
                height: 100%;
                min-height: 68vh;
            }
        }

        section{
            display: flex;
        }

        .view-header{
            margin: 0;
            max-height: 0px;
        }
        .form-content{
            overflow: auto;
        }
        .modal-container{
            height: auto;
            .modal-body{
                max-height: calc(100vh - 350px);
                width: 100%;
                padding: 12px 0;
                @include respond-to(xs){
                    max-height: calc(100vh - 400px);
                }
                @media (max-height: 600px) {
                    max-height: 100%;
                }
            }
            .modal-footer{
                @media (max-height: 600px) {
                    position: fixed;
                    bottom: 20px;
                    right: 0;
                    max-width: 50%;
                    text-align: right;
                }
            }

            .terms-conditions-text{
                text-align: justify;
                line-height: 1.3;
                max-height: fit-content;
                overflow-x: hidden;

                label{
                    font-weight: 500;
                    padding: 15px;
                }
            }

            .modal-button{
                color: $brand-color-accent ;
                font-weight: bold;
                text-decoration: none;
                margin: 0 10px;
                cursor: pointer;
            }
        }
    }
}

// Recover Password

.external-header-styles{
    background-color: transparent;

    .toolbar-btn{
        color: $brand-color-primary;
    } 
}

.recovery-pass-styles{
    background-color: transparent;

    p, ul, li, .form-group .form-group-text .control-label span, .descriptionStatus, svg{
        color: #222222;
    }

    
    h1 .descriptionStatus{
        color: $brand-color-primary;
    }
    
    &.confirmation-page{
        color: $ui-white;
    }
}

// Pay Any Day

.pay-any-day-styles{
    &.confirmation-page{
        .text-center{
            color: $brand-color-primary; 
        }

        @include respond-to(mobile){
            .PAD-arrows{
                color: $ui-white;
            }

            .btn.btn-secondary.btn-block{
                background-color: #8ba09e;
                color: white;
                border-color: white;
            }
            .text-center{
                color: white; 
            }
        }

        .extra-info{
            font-size: small;
        }
        h3 {
            font-weight: 600;
        }
    }
}

//Agreements
.agreements-mobile {
    max-width: 390px;
    color: $brand-color-base;
    margin-left: auto;
    margin-right: auto;
    span {
        margin-top: 25px;
        font-size: 0.85em;
    }

    h1{
        padding-top: 20px;
        span {
            font-family: $brand-font-family-bold;
            font-weight: $brand-font-weight-bold;
            font-size: 20px;
        }
    }

    h4 {
        text-align: center;
        margin-bottom: 25px;
        font-size: 2em;
    }

    input {
        margin-left: 5px;
        margin-right: 8px;
    }

    label {
        text-decoration: underline;
        font-size: 18px;
    }

    .downloadLink {
        margin-left: 5px;

        span {
            font-size: 1em;
        }
    }

    .agreementsSubtitle {
        text-align: center;
        span {
            font-size: 0.8em;
            font-weight: bold;
            @include respond-to(mobile){
                font-family: $brand-font-family;
                font-weight: $brand-font-weight-regular;
                font-size: 16px;   
                color: $brand-color-black;         
            }
        }


    

    }

    .agreementsGroup {
        margin-top: 40px;

        .agreementElement {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-family: $brand-font-family-semibold;
            font-weight: $brand-font-weight-semibold;
            color: $brand-color-black;
            height: 40px;
            .download-button {
                padding: 0;
                justify-content: flex-end;
                margin: 0;
                padding-left: 20px;
                flex-grow: 0;
                width: 10%;
                &:active{
                    background: none;
                    box-shadow: none;
                }
            
                &:focus{
                    background: none;
                    box-shadow: none;
                }
                &:hover{
                    background: none;
                    box-shadow: none;
                }
            }
    
        }


    }


    
}

//Agreements popup
.agreements-popup {
    color: $brand-color-base;
    font-size: 1rem;
    text-align: justify;

    @include respond-to(mobile){
        font-size: 1.2em;
    }

    .modal-dialog .modal-content{
        padding: 1rem;

        .modal-body{
            margin-top: 1rem;
        }

        .modal-footer{
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
            .btn + .btn{
                margin-top: 12px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

.billpay-popup{
    color: $brand-color-primary;
    font-size: 1rem;
    text-align: justify;

    @include respond-to(mobile){
        font-size: 1.2em;
    }

    .modal-dialog .modal-content{
        padding: 2rem;

        .modal-body{
            margin-top: 0;
        }
    }
}

.settings-main {
    .navigational-list-title {
        span {
            font-size: 20px;
            font-family: $brand-font-family-bold;
            font-weight: $brand-font-weight-bold;
        }
    }
    .navigational-list-item {
        span {
            font-size: 16px;
            font-family: $brand-font-family-semibold;
            font-weight: $brand-font-weight-semibold;
        }
    }

    .svg-wrapper{
        width: auto;

        svg{
            fill: none;
            stroke: #e87722;
            stroke-width: 3;
        }
    }
}

.settings-heading {
    .content-data-row {
        .content-data-wrapper{
            .data-text {
                font-size: 20px;
                font-family: $brand-font-family-bold;
                font-weight: $brand-font-weight-bold;                
            }  
            .data-desc {
                font-size: 16px;
                font-family: $brand-font-family-semibold;
                font-weight: $brand-font-weight-semibold;                
            }  
        }
    }
}

.header-title {
    font-size: 18px;
    font-family: $brand-font-family-bold;
    font-weight: $brand-font-weight-bold;    
}


.label-additional-description {
    width: 250px;
    font-size: 14px;
    font-family: 'Open Sans';
    font-weight: bold;
    padding-top: 12px;
    padding-left: 15px;
}

.label-with-description {
    padding-left: 0;
}

.dashboard-logo {
    @include respond-to(mobile) {
        padding-top: 10px;
        height: 90px;

    }
}