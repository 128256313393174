// selection-list variables
$selection-list-icon-color: $brand-primary;
$selection-list-type-color: $brand-info;
$selection-list-background-color-active: $brand-primary;
$selection-list-type-color-active: white;
// checks-list variables
$checks-list-icon-color: $brand-primary;
$checks-list-type-color: $brand-info;
$checks-list-type-color-active: white;
// icon-toggle variables
$icon-toggle-color: $brand-info;

.c-control {
    position: relative;

    display: inline-block;

    margin-bottom: 0.5rem;

    user-select: none;

    @include when-inside(list-particular) {
        margin-bottom: 0;
    }

}

.c-control-input {
    position: absolute;

    opacity: 0;

    pointer-events: none;
}

.c-control-input:focus~.c-control-label {
    &::before {
        border-color: $c-control-input--border-color;

        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        outline: 0;
    }
}

.c-control-label {
    position: relative;
    font-size: 1.1rem;
    min-height: 0.875em;
    padding-left: 1.75em;
    margin-bottom: 0;

    font-weight: $body-type-weight;

    will-change: transform;

    &:hover {
        cursor: pointer;
    }

    /* Posible fix articulado con FastClick JS para mejorar la interaccion */
    &>* {
        pointer-events: none;
    }
}

.c-control-label::before {
    border: 2px solid $brand-color-primary;
    border-radius: 2px;
}

.c-control-label::after,
.c-control-label::before {
    position: absolute;

    content: "";
}

.c-control-label::after,
.c-control-label::before {
    top: calc(50% - 0.6em);
    left: 0;

    width: 1.25em;
    height: 1.25em;
}

.rc-table-row {

    .c-control-label::after,
    .c-control-label::before {
        top: calc(50% - 9px);

        width: 18px;
        height: 18px;
    }
}

.c-control-label::after {
    border: 1px solid transparent;

    color: #333333;
}

@include respond-to(mobile) {
    .c-control-label::after {
        border-color: $control-label-box--border-color;

        background-color: #eee;
        background-size: 0;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
    }
}

@include respond-to(desktop) {
    .c-control-label::after {
        border-color: $control-label-box--border-color;
    
        background-color: #eee;
        background-size: 0;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
    }
}

.c-control-label::after {
    /* Centrar un caracter */
    line-height: 1.125;
    text-align: center;

    background-repeat: no-repeat;
    background-position: center;
    /* Centrar un fondo */
    background-size: 100%;

    transform: scale(0);
    transition: transform 0.1s ease;
    will-change: transform;
}

.c-control-input:checked~.c-control-label::after {
    transform: scale(1.6);
}

.c-control--checkbox {
    .c-control-label::after {
        content: "✓";
    }
}

.c-control--radio,
.c-control--switch {
    .c-control-label::before {
        border-radius: 10rem;
    }

    .c-control-label::after {
        content: "•";
    }
}

.c-control{
    .language-mark{
        &.c-control-label::before{
            border: none;
        }

        @include respond-to(mobile){
            color: #FFFFFF;
        }
        
        .c-control-icons{
            .c-control-mark{
                .svg-wrapper{
                    background-color: transparent;

                    svg{
                        background-color: transparent;

                        path{
                            stroke: $brand-color-base;

                            @include respond-to(mobile){
                                stroke: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }
    }
}

.c-control--radio .c-control-label::after {
    content: none;
}

.c-control--switch {
    position: relative;

    margin-right: 0;

    .c-control-label {
        padding-left: 2.6em;

        &::after {
            transform: translate(0) scale(1.6);
        }

        &::before {
            width: 2em;
        }
    }

    .c-control-input:checked~.c-control-label {
        &::after {
            transform: translate(0.8em) scale(1.6);
        }
    }

    &.c-control--switch-on-right {
        .c-control-label {
            padding-right: 0;
            padding-left: 2.6em;

            @include respond-to(mobile) {
                width: 100%;
            }

            &::before,
            &::after {
                right: initial;
                left: 0;
            }

            &::after {
                transform: translate(0em) scale(1.6);
            }
        }

        .c-control-input:checked~.c-control-label {
            &::after {
                transform: translate(0.8em) scale(1.6);
            }
        }
    }
}

.c-control-confirmation {
    position: relative;

    display: block;

    width: 100%;
    margin-right: 0;

    .c-control-label {
        width: 100%;
        padding-right: 2.6em;
        padding-left: 0;

        &::before,
        &::after {
            right: 0;
            left: initial;
        }

        &::after {
            transform: translate(-0.8em) scale(1.6);
        }
    }

    .c-control-input:checked~.c-control-label {
        &::after {
            transform: translate(0) scale(1.6);
        }
    }
}

.c-control-block {
    position: relative;

    display: block;

    width: 100%;
    margin-right: 0;
}

.c-control--has-icon {
    .c-control-label::after {
        content: none;
    }
}

.c-control--table,
.c-control {
    .c-control-label {
        .c-control-mark {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            // right: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            height: 100%;
            padding: 0.25rem;
            margin: auto;

            color: $brand-primary;

            transform-origin: center;
            transition: all 0.125s ease;

            &:only-child {
                opacity: 0;

                transform: scale(0);
                visibility: hidden;
            }
        }
    }
}

.c-control--radio,
.c-control--checkbox {
    .c-control-label {
        .c-control-mark {

            .svg-wrapper,
            svg {
                height: 18px;
                width: 18px;
                border-radius: 2px;
                background-color: #E87722;
                padding: 3px;

                path{
                    stroke: #FFFFFF;
                    stroke-width: 6px;
                }
            }
        }
    }
}

.c-control {
    .c-control-input:checked~.c-control-label {
        .c-control-mark {
            opacity: 1;
            width: 1.25em;
            transform: scale(1);
            visibility: visible;
        }
    }
}

.c-control--table {
    .c-control-mark {
        .svg-wrapper {
            color: $brand-primary;
        }
    }

    .c-control-label {
        .c-control-mark {
            right: 0.5rem;
            left: initial;
        }
    }

    .c-control-input:checked+.c-control-label {
        &::after {
            content: none;
        }

        .c-control-mark {
            right: 0.5rem;

            opacity: 1;

            transform: scale(1);
            visibility: visible;
        }
    }
}

.c-control--icon-toggle {
    display: flex;

    margin: 0 0.8rem;

    font-size: 1rem;

    .c-control-label {
        z-index: 1;

        width: 1.6rem;
        padding: 0;

        color: transparent;

        .c-control-on {
            display: none;
        }

        .c-control-off {
            display: flex;
            align-items: center;

            color: $icon-toggle--color;
        }
    }

    .c-control-input:checked~.c-control-label {
        .c-control-on {
            display: flex;
        }

        .c-control-off {
            display: none;
        }
    }

    .c-control-label::before {
        position: absolute;

        content: none;
    }

    .c-control-label::after {
        content: none;
    }
}

/**
 * TODO revisar esto un poco para integrarlo en el componente
 */
.c-control-input.disabled,
.c-control-input[disabled],
fieldset[disabled] .c-control-input {
    ~.c-control-label {
        cursor: default;

        &::before,
        .c-control-icons {
            opacity: 0.25;
            box-shadow: none;
            cursor: not-allowed;
        }
    }
}

/** 
 * TODO find and replace
 * form-check-group => form-group-control-list 
 * form-group-list => form-group-control-list 
 */

.form-group-list {
    .c-control {
        margin-right: 1rem;
    }
}

.form-group-control-list,
.form-check-group {
    padding: 0;
    margin: 0.5em 0;

    .c-control {
        margin-right: 1.125em;
    }
}

/* Selector de idioma, cambiar nombre variando form-group-control-list */
.check-list {
    .c-control {
        display: flex;
        justify-content: center;

        margin-right: 0;
    }

    .c-control-label {
        position: relative;

        padding-right: 1.75em;

        color: $checks-list--color;
        font-size: 1.5rem;

        // .glyphicon {
        //     left: 0.2em;

        //     color: $checks-list-icon--color;
        // }

        &.language-mark{   
            @include respond-to(mobile){
                color: $ui-white;
            }
        }
    }

    .c-control-input:not(:checked)~.c-control-label::before {
        opacity: 0;
    }

    .c-control-input:checked~.c-control-label {
        color: $checks-list--color-active;
    }
}

/* Selector de ambientes */

.selection-list {
    .c-control-label {
        color: $selection-list-type-color;

        .svg-wrapper {
            color: $selection-list-type-color;
        }

        &:hover {
            color: white;

            background-color: $brand-info;

            .svg-wrapper {
                color: inherit;
            }
        }
    }

    .c-control-label:focus,
    .c-control-input:checked~.c-control-label {
        color: $selection-list-type-color-active;

        background-color: $selection-list-background-color-active;
    }

    .c-control-label {
        color: $brand-info;

        .svg-wrapper {
            color: inherit;
        }
    }

    .c-control-input:checked~.c-control-label {
        color: $brand-primary;

        background-color: white;

        h4,
        .svg-wrapper {
            color: currentColor;
        }
    }

    .c-control {
        display: flex;
        justify-content: center;

        margin-right: 0;
    }

    .c-control-label {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        min-height: 3rem;
        padding-right: 0;
        padding-left: 2em;

        border-radius: 5px;

        font-size: 1.5rem;

        h4 {
            font-weight: normal;
        }

        span {
            margin-bottom: 0.875em;

            font-family: sans-serif;
            font-size: 0.5em;
        }

        svg {
            width: 1.75rem;
            height: 1.75rem;
            min-width: 1.75rem;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .c-control-label {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        padding: 0;
    }

    .svg-wrapper {
        width: initial;
        margin-right: 0.75rem;
    }

    .c-control-input:not(:checked)~.c-control-label::before {
        opacity: 0;
    }
}

.theme-auth .selection-list {
    .c-control-label {
        color: $brand-info;

        .svg-wrapper {
            color: $brand-primary;
        }
    }

    .c-control-label:hover,
    .c-control-input:checked~.c-control-label {
        color: white;

        background-color: $brand-primary;

        h4,
        .svg-wrapper {
            color: inherit;
        }
    }

    .c-control-label:hover {
        background-color: $brand-info;
    }
}

.input-group>.c-control--icon-toggle {
    margin: 0 0.8rem;

    .c-control-label {
        .c-control-mark {
            align-items: center;

            color: currentColor;
        }

        .c-control-off {
            display: flex;
            align-items: center;

            color: $icon-toggle--color;
        }
    }
}

.c-control--icon-toggle {
    display: flex;

    font-size: 1rem;

    &.toggle--outline {
        display: inline-flex;

        margin: 0 0.5rem;

        .c-control-label {
            width: 2rem;
            height: 2rem;

            border: 1px solid $brand-info;
            border-radius: 50%;

            color: $brand-info;

            cursor: pointer;

            &:hover {
                color: white;

                background-color: $brand-info;
            }
        }

        .c-control-input:focus~.c-control-label {
            box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.4);
        }
    }

    .c-control-label {
        z-index: 1;

        width: 1.6rem;
        padding: 0;

        color: transparent;

        .c-control-mark {
            align-items: center;

            color: currentColor;

            .svg-wrapper,
            svg {
                width: 1.25em;
                height: 1.25em;
            }
        }

        .c-control-mark--checked {
            opacity: 0;

            transform: scale(0);
            visibility: hidden;
        }

        .c-control-mark--unchecked {
            opacity: 1;

            transform: scale(1);
            visibility: visible;
        }
    }

    .c-control-input:checked~.c-control-label {
        .c-control-mark--checked {
            opacity: 1;

            transform: scale(1);
            visibility: visible;
        }

        .c-control-mark--unchecked {
            opacity: 0;

            transform: scale(0);
            visibility: hidden;
        }
    }

    .c-control-label::before {
        position: absolute;

        content: none;
    }

    .c-control-label::after {
        content: none;
    }
}

.rc-table-row .c-control {
    display: flex;
    align-items: center;
}