@include respond-to(desktop) {
    .theme-auth {
        .view-header .navbar {
            color: $view-title--color !important;

            .text-lead,
            .view-title {
                color: $view-title--color !important;
                text-align: center;
            }
        }

        .view-header .navbar .btn.btn-only-icon {
            color: $view-icons--color;
        }
    }
}

.login-splash {
    position: relative;
    overflow: hidden;

    opacity: 0;

    transform: translate3d(0, 0, 0);
    animation: appearOpacity 0.75s ease 0.25s forwards;

    .flex-grow {
        opacity: 0;

        animation: appearOpacity 3s ease 0.75s forwards;
    }

    .container--layout:nth-child(2) {
        &::before {
            position: absolute;
            bottom: 0;
            z-index: 0;

            width: 100%;
            height: 30vh;

            content: "";

            opacity: 0;
            background: linear-gradient(transparent, black);

            transform: translateY(4rem);
            animation: appearSections 2s ease 2.25s forwards;
        }

        .col:nth-child(1) {
            button:nth-child(1) {
                opacity: 0;

                transform: translateY(4rem);
                animation: appearSections 0.5s ease 2.5s forwards;
            }

            button:nth-child(2) {
                opacity: 0;

                transform: translateY(4rem);
                animation: appearSections 0.5s ease 2.625s forwards;
            }

            button:nth-child(3) {
                opacity: 0;

                transform: translateY(4rem);
                animation: appearSections 0.5s ease 2.75s forwards;
            }
        }

        .col:nth-child(2) {
            p {
                opacity: 0;

                animation: appearOpacity 0.5s ease 3s forwards;
            }
        }
    }

    .container--bottom {
        opacity: 0;

        animation: appearOpacity 0.5s ease 1.75s forwards;

        > .container {
            opacity: 0;

            transform: translateY(4rem);
            animation: appearSections 0.5s ease 2s forwards;
        }
    }
    .form-content.container .text-center{
        a{
            color: $app-page-link--color;
        }
    }
}

.login-background {
    min-height: 100%;

    opacity: 0;
    background-size: cover;

    animation: appearOpacity 0.75s ease 0.5s forwards;

    .app-footer {
        opacity: 0;

        animation: appearOpacity 0.5s ease 0.5s forwards;
    }
}

// LOGIN STYLING FOR PARALLAX BACKGROUNDS

// Login animation resizing
.login-background-wrapper,
.login-background-wrapper > div {
    overflow: auto;

    width: 100% !important;
    height: 100% !important;
}



#root > div:first-child,
.transition-wrapper,
.transition-left {
    overflow: auto;

    height: 100%;
}

// Layout changes

.login-background {
    height: 100%;
}

.app {
    //Login box container
    > section:nth-child(2) {
        margin-bottom: ($l / 2) + $external-footer-height !important;
    }

    .app-footer {
        margin-bottom: 0 !important;
    }
}

// Changes backgrounds depending on the moment of the day or the weather:
// valid classes are: dayClear, dayCloudy, daySnowy, dayRainy, nightClear, nightCloudy, nightSnowy , nightRainy

.dayClear,
.nightClear,
.nightCloudy,
.nightSnowy,
.nightRainy {
    .logo {
        color: white;
    }
}

.dayRainy,
.dayCloudy {
    .logo {
        color: $brand-primary;
    }
}

.dayRainy,
.nightRainy {
    &.login-background-wrapper:after {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        content: "";

        background-image: url(../images/rain.png);
        background-repeat: repeat;
        background-position: top left;
    }
}

.widget-preloader.weather-animation-loader {
    position: fixed;
    top: 10px;
    left: 10px;

    width: auto;
    height: auto !important;
    min-height: 20px;

    background: transparent;

    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        width: 10px;
        height: 10px;
        margin: 0 1px;
    }

    &.hide {
        display: none;
    }
}
