.heading-row{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: fit-content;
    margin: auto;

    //Only for PointPickup
    display: none;

    .heading {
        display: flex;
        width: auto;
        margin: auto;
        padding: 10px;

        span {
            width: max-content;
        }

        .separator {
            padding: 0 3px;
            margin: 0;

            &:before {
                content: "-";
                display: inline-flex;
            }
        }
    }
    .detail-links-href{
        display: flex;
        justify-content: center;
    }
    .svg-wrapper{
        color: $brand-color-primary;
        .svg-icon{
            height: 32px;
            width: 32px;
        }
    }
}

.split-body-wrapper {
    flex-wrap: wrap;
    display: flex;
    // @include row-v_start-h_space_between;

    > * {
        flex-grow: 1;
        min-width: 350px;
        width: 49%;
        @include respond-to(mobile) {
            margin-top: 0;
            width: 100%;
            min-width: 0;
        }
    }
    margin-top: 37px;

    @include respond-to(mobile) {
        margin-top: 0;
    }
}

.powered-by-footer{
    padding-top: 30px;
    margin: auto;
    svg {
        width: 130px;
        max-width: 130px;
    }
}
.billpay-history-page, .add-money-history-page{
    .rellevate-s1-container-wrapper .content-wrapper .content {
        background-color: white;
        padding: 0;
    
        .content-body-title{
            text-align: center;
            margin-bottom: 30px;
            padding-bottom: 15px;
            border-bottom: solid 1px;
            h3{
                font-weight: normal;
            }
        }
    }
}

.rellevate-s1-container-wrapper .content-wrapper  .content  .content-body .content-body-filter-container .filter-container-button, 
.movement-buttons .movement-button{
    border-radius: 25px;
}

.rellevate-s1-container-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    .main-title {
        text-align: center;
        text-transform: capitalize;
        color: #333333;
        font-size: 20px;
    }

    .subtitle {
        font-size: 1.50em;
        color: #333333;
    }

    .content-wrapper {
        width: 100%;
        max-width: 850px;
        padding: 27px 25px;
        margin-bottom: 20px;
        margin-top: 30px;

        .heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 20px;

            .highlighted-link
            {
                font-weight: 600;
            }

        }

        .content {
            width: 100%;
            position: relative;
            padding: 10px 20px;
            margin-top: 40px;
            opacity: 1;

            .content-header-buttons {
                padding: 1em;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;

                .btn-delete, .btn, .btn-default {
                    width: auto;
                    padding: 0;
                    margin: 0;
                    border: none;
                    color: $brand-primary;
        
                    &:hover {
                        background-color: transparent;
                    }
        
                    > * {
                        margin: 0;
                    }
                }
            }

            .content-footer-buttons {
                padding: 1em;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
    
                a.highlighted-link {
                    color: $brand-color-terciary;
                    font-weight: bold;
                    text-transform: uppercase;
            
                    &:hover, &:active, &:visited {
                        color: $brand-color-terciary;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }

            .content-body {
                display: flex;
                flex-direction: column;
                position: relative;
                margin: 0;

                .addMoney-colbtn{
                    .addMoney-history-btn{
                        height: 80%;
                        margin:2%;
                        font-size: 0.9em;
                    }
                }

                .content-body-title{
                    font-weight: bold;
                    color: $ui-gray-dark;
                    font-size: 16px;
                    display: none;
                }

                .content-body-filter-container{
                    display: flex;
                    padding: 0;
                    width: 100%;
                    margin-bottom: 13px;
                    justify-content: center;

                    .filter-container-button{
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        justify-content: center;
                        padding: 0.75em;
                        margin: 0;
                        font-family: "Open Sans", sans-serif;
                        font-size: 0.75rem;
                        font-weight: bold;
                        text-transform: uppercase;
                        border: 1px solid #e87722;
                        color: #333333;
                        background-color: transparent;
                        margin: 1px;
                        width: -webkit-max-content;
                        width: max-content;
                        margin-right: 0.5%;
                        margin-right: 0.5%;
                    }

                    .filter-container-button.is-active {
                        color: #ffffff;
                        border: none;
                        background-color: $brand-color-base;
                    }
                }

                .content-row{
                    margin-bottom: 20px;
                }
            }
        }    

        .rellevate-content-content-mobile, .rellevate-content-content{
            .title{
                border: none;
                border-bottom: 2px solid $brand-color-base;
                font-weight: bold;
                color: $brand-color-base;
                background-color: white;
                margin-bottom: 15px;
                text-transform: uppercase;
            }
        }
    }

    .new-modal-wrapper {
        
        .new-modal {
            @include fully-centered-col;
            width: 470px;
            padding: 0 1em;
            background-color: #ffffff;

            .new-modal-header {
                border-bottom: none;
            }

            .description {
                text-align: center;
                margin-top: 1.5em;

                strong {
                    color: $brand-color-accent;
                }
            }

            article {
                padding: 0;

                .btns-wrapper {
                    @include fully-centered-row;
                    padding: 10px 0;

                    &.btn-block + .btn-block { 
                        margin: 0;
                    }

                    .justify-content-center.row{
                        > button{
                            margin: 0 !important;
                        }
                    }

                    .btn.btn-link {
                        width: 33%;
                        height: 59px;
                        text-transform: uppercase;
                        font-size: 1em;
                        font-weight: bold;
                        outline: none;

                        &:hover, &:active {
                            background-color: #ffffff;
                            border: 3px solid $brand-color-base;
                            color: $brand-color-base !important;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .rellevate-s1-container-wrapper{
        .powered-by-footer{
            display: none;
        }
    }
}

.rellevate-bold-green{
    font-weight: bold;
    color: $brand-color-base;
}

.rellevate-bold-black{
    font-weight: bold;
    color: #282828;
}

.rellevate-bold-grey{
    font-weight: bold;
    color: #777777;
}

.rellevate-green{
    color: $brand-color-base;
}

.rellevate-black{
    color: $brand-color-black;
}

.rellevate-semibold-black{
    color: #333333;
    font-weight: 600;
}

.rellevate-grey{
    color: $brand-color-black;
}

.rellevate-uppercase{
    text-transform: uppercase;
}

.transaction-title-detail{
    font-weight: 600;
}

.rellevate-data-amount{
    color: #282828 !important;
    font-weight: bold;
    text-align: right;

    .btn > * {
        margin: 0;
    }

    .arrow-button{
        display: block !important;
        border: none;
        padding: 0;
        margin-top: 0 !important;
        text-align: right;
        background: none;
        box-shadow: none;

        .svg-icon {
            height: 1em;
        }
    }

    .arrow-button:active{
        box-shadow: none;
        background: none;
    }
}

.rellevate-data-amount{
    .data-amount{
        color: #282828 !important;   
    }

    .btn{
        font-size: 20px;
    }
}

.rellevate-content-displayName {
    font-weight: bold;
    color: $brand-color-base;
}

.rellevate-content-account {
    font-weight: bold;
    color: #777777;
}

.billPay-history-date {
    color: #777777;
}

.rellevate-content-status {
    text-transform: uppercase;
    color: #777777;
}

.rellevate-content-amount {
    font-weight: bold;
}

@media (max-width: 844px) {
    .rellevate-s1-container-wrapper{
        display: block;
        width: 100%;
        margin-bottom: 30px;

        .title{
            text-align: center;
        }

        .subtitle{
            text-align: center;
        }

        .heading-row{
            display: none;
        }

        .content-wrapper {
            padding: 0px 5px;

            .heading{
                .highlighted-link{
                    font-size: 14px;
                }
            }

            .content{
                padding: 10px 10px;
                margin-top: 20px;

                .content-body {

                    .datepicker-div-period-filter{
                        width: 100% !important;

                        .datepicker-period-filter-field{
                            width: auto;
                        }

                        .react-datepicker-popper[data-placement^="bottom"] {
                            margin-top: 54px !important
                        }

                        .datepicker-filter-button {
                            justify-content: center;
                        }

                        .datepicker-filter-button-inner{
                            padding:0;
                            margin: 0px;
                        }
                    }

                    .table {
                        .table-row {
                            .table-data {

                                .rellevate-black {
                                    font-size: small;
                                    display: inline-block;
                                    width: 145px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .rellevate-uppercase {
                                    font-size: small;
                                }
                                
                                

                                .input-group-datepicker{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                
                .table-data{
                    padding: 0;
                }
            }
        }

        .new-modal-wrapper {
            padding: 0;

            .btn-block {
                margin-top: 0;
            }
        }

    }

    
    .rellevate-content-displayName {
        font-size: 0.9rem;
    }

    .rellevate-content-account {
        font-size: 0.9rem;
    }

    .rellevate-content-date {
        font-size: 0.9rem;}

    .rellevate-content-status {
        font-size: 0.9rem;
        }

    .rellevate-content-amount {
        font-size: 0.9rem;
    }

    .rellevate-content-filter-searchButton{
        margin-left: 2em;
    }
}

.preloader-recent-transactions {
    height: 50px;
    position: sticky;
    margin-top: 2em;
}


.alerts-card {
    // width: 390px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 17px -2px #0000071f;
    border-radius: 1rem;
    opacity: 1;
    padding-left: 40px;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 2em;
    margin-bottom: 2em;

    font-size: 14px;
    line-height: 25px;
    .svg-wrapper {
        margin-left: 3em;
        width: 0.3em;
        height: 0.3em;
        .st0 {
            stroke: none;
        }
    }

    .svg-icon-left{
        margin-left: -3em;
    }

    .switch-direct-deposit-button {
        //margin: 0 !important;
        margin-top: 1em;
        min-width: 38vh;
        height: 60px;
        margin-left: -4em;
        .btn {
            min-width: 37vh;
            max-width: 390px;
            width: 85%;
        }
    }
    .data-label-alt {
        width:90%;
    }
    .data-label-alt-list{
        font-size: small;
        margin-right: 1.5vh;
    }
    li{
        font-size: small;
    }
    .svg-icon {
        height: 1.5em;
    }

    .svg-wrapper {
        margin-left: 1.5em;
        margin-top: -1em;
    }
    .alert-key{
        margin-right: 1vh;
        margin-left: 1vh;
        display:inline;
        float:left;
        width: 10em;
    }
  }
.nickNameHelper{
    font-size: 0.9rem;
    color: #718096;
    margin-top: -0.75rem;
    margin-bottom: 20px;
}

.info-card {
    //width: 100vh;
    padding-left: 1vh;
    padding-right: 1vh;
    height: 50vh;
    margin-top: 7vh;
    margin-bottom: 5vh;

    p {
        margin-top: 3vh;
        text-align: center;
        font-weight: 500;
        font-size: 15px;
    }

    .card-info-image-wrapper {
        margin-top: 1vh;

        height: 13vh;
        .svg-wrapper{
            display:inline;
        }
        svg{
            height: 13vh;
            display: inline;
        }
        margin-bottom: 3vh;
    }

    ul {
        li {
            color: black;
            font-size: smaller;
        }
    }
    
}

.cta-yes-label {
    strong {
        color:#e87722;
    }
}

.main-container-card-info {
    .powered-by-footer {
        display: none;
    }
}

.button-card-info {
    margin: 0;

}
