/**
 * Arreglo necesario para separar visualmente en contenido de la interfaz 
 * de la plataforma del Iphone X en particular
 */

//  Cabezal de color violeta
.view-header.theme-product-detail .navbar {
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}

.view-header:not(.theme-product-detail) {
    margin-top: constant(safe-area-inset-top); /* iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* iOS 11.2 */
}

.container--bottom {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.notifications-wrapper {
    top: 0;
    top: constant(safe-area-inset-top); /* iOS 11.0 */
    top: env(safe-area-inset-top); /* iOS 11.2 */
}

.slideFromBottom.Select-menu {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

.containerDetails .col > div > div {
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

// Elementos sobrantes del componente react-infinite
.containerDetails .table-row + div + div {
    display: none;
}

// Contenedor del listado de movimientos
.containerDetails .col > div > div {
    -webkit-overflow-scrolling: touch;
}

// Centrar contenedor de mensaje en listado para mobile
.containerDetails .react-tabs__tab-panel > div > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

// Fix for MANNAZCA-7042
@include respond-to(mobile) {
    .containerDetails .react-tabs__tab-panel > .row > .col {
        min-height: 0;
    }
}

.height-inherit{
    height: inherit !important;
}