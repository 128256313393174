
.sendMoney-history{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;

    .sendMoney-history-content-wrapper {
        width: 100%;
        max-width: 750px;
        padding: 27px 25px;
        background-color: #E6F4F3;
        margin-bottom: 20px;

        .heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .highlighted-link
            {
                color: #f7971c;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .sendMoney-history-content-mobile {
            background-color: #ffffff;
            border: 2px solid #FDE9BB;
        }
        .sendMoney-history-content {
            width: 100%;
            position: relative;
            background-color: #ffffff;
            margin-top: 4%;
            padding: 15px 25px;
            border: 2px solid #FDE9BB;
            -webkit-box-shadow: 0px 13px 15px 5px #AFDCD7;
            box-shadow: 0px 13px 15px 5px #AFDCD7;
        }    
        .sendMoney-history-content-mobile, .sendMoney-history-content{
            .sendMoney-history-content-title{
                border: none;
                border-bottom: 2px solid $brand-color-base;
                font-weight: bold;
                color: $brand-color-base;
                background-color: white;
                margin-bottom: 15px;
            }

            .sendMoney-history-content-body {
                display: flex;
                flex-direction: column;
                position: relative;
                background-color: #ffffff;
                margin: 0 25px;

                .addMoney-colbtn{
                    .addMoney-history-btn{
                        height: 80%;
                        margin:2%;
                        font-size: 0.9em;
                    }
                }
                .sendMoney-add-money-history{
                    padding: 20px;
            
                    > div {
                        @include col-v_start-h_start;
                      
                    }
            
                    p {
                        @include col-v_start-h_start;
                        margin-bottom: 15px;
            
                        .history-title {
                            text-transform: uppercase;
                            color: #B3B5B5;
                            font-weight: bold;
                        }
            
                        .history-value {
                            color: #747474;
                            font-weight: bold;
                        }
                    }

                    p {
                        font-size: 0.9em;
                    }
                }
            }

            .sendMoney-history-row{
                margin-bottom: 20px;

                .title{
                    text-transform: uppercase;
                    color: #B3B5B5;
                    font-weight: bold;
                }

                .amount{
                    color: $brand-color-base;
                    font-weight: bold;
                }

                .status{
                    text-transform: uppercase;
                    font-style: italic;
                    color: #B3B5B5;
                    font-weight: bold;
                    font-size: 1.3em;
                }
            }
        }
    }
}

.sendMoney-history-displayName {
    font-weight: bold;
    color: $brand-color-base;
}

.sendMoney-history-account {
    font-weight: bold;
    color: #777777;
}

.sendMoney-history-date {
    color: #777777;
}

.sendMoney-history-status {
    text-transform: uppercase;
    color: #777777;
}

.sendMoney-history-amount {
    font-weight: bold;
}

@media (max-width: 844px) {
    .sendMoney-history{
        display: block;
        width: 100%;
        margin-bottom: 30px;

        .sendMoney-history-content-wrapper {
            padding: 27px 15px;

            .sendMoney-history-content-mobile, .sendMoney-history-content{
                .sendMoney-history-content-body {
                    margin: 0 15px;
                }
            }
        }
    }

    
.sendMoney-history-displayName {
    font-size: 0.9rem;
}

.sendMoney-history-account {
    font-size: 0.9rem;
}

.sendMoney-history-date {
    font-size: 0.9rem;}

.sendMoney-history-status {
    font-size: 0.9rem;
    }

.sendMoney-history-amount {
    font-size: 0.9rem;
}

.sendMoney-history-filter-searchButton{
    margin-left: 2em;
}
}