.progress-wrapper {
    font-family: $brand-font-family;
    display: flex;
    height: 2.4em;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    .progress {
        position: relative;
        height: 2.4em;
        width: 100%;
        margin-bottom: 0;
        background-color: lighten($brand-primary, 60%) !important;
        box-shadow: none;
    }

    span {
        right: 10.25em;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.85em;
    }

    span+span {
        right: 2.5em;
    }

    @include respond-to(desktop) {
        margin-top: 1rem;
    }
}

.form-group {
    .progress {
        height: 1rem;
    }
}

.password-strength .progress-bar {
    background-color: rgba(0, 169, 64, 1) !important;
}

.progress-bar-danger {
    background-color: $brand-danger !important;
}

.progress-label {
    position: absolute;
    top: 0;
    line-height: 2rem;
    left: 0;
    margin: 0 1em;

    color: white;
}

.progress-below-half {
    left: initial;
    color: $gray-darker;

    right: 0;
}

.error {
    color: $brand-color-red;
    font-weight: bold;
}