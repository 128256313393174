.summary-page{

    @include respond-to(mobile){
        .container--layout {
            margin: 0 ;
        }
    }
    .container{
        padding: 0;
    }
    
    .summary-bg{
        margin: 0;
        padding-bottom: 1rem;
    }

    .form-group.has-focus {
            .input-group{
                border:solid 2px $brand-color-base !important;
            }
    }
    .full-width-select{
        width: 100%;
    }

    @include respond-to(mobile){
        svg.svg-image-onboarding-summary{
            max-height: 20vh;
        }
    }
    .onboarding-summary-view{
        text-align: left;
        width: 90%;
        padding: 2% 5%;
        display: flex;
        flex-direction: column;
        background-color: $ui-white;
        border: solid 1px $brand-color-base;
        border-radius: 3px;
        max-width: 390px;

        @include respond-to(desktop){
            margin-top: 1rem;
            width: 80%;
        }

        .edit-done{
            text-align: right;
            height: 4em;
            a {
                color: #f7971c;
                font-weight: bold;
                text-decoration: underline;
                text-align: right;
                cursor: pointer;

                .svg-wrapper {
                    align-items: right;
                    justify-content: right;
                    width: 10%;
                    .svg-icon{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .data-wrapper{
            .data-aux{
                color: $ui-gray-label;
                font-weight: bold;
            }
        }

        .col-12{
            &:focus{
                color: $brand-color-terciary;
                border: $brand-color-terciary 1px solid;
            }
        }
    }

    .summary-separator {
        border-bottom: 1px solid rgba(14, 109, 110, 0.1);
    }
}