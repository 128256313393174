@include respond-to(desktop) {
    .draggable-list {
        position: relative;
        z-index: 999;
    }

    .draggable-list.is-disabled > div {
        position: relative;
    }

    .draggable-list__item {
        position: absolute;
        overflow: visible;
        pointer-events: auto;
        transform-origin: 50% 50% 0px;

        box-sizing: content-box;
    }

    .draggable-list.is-enabled {
        user-select: none;
    }
}

.draggable-list__item {
    width: 100%;
    border-radius: 5px;
    color: rgb(153, 153, 153);
    font-size: 1rem;
    background-color: rgb(255, 255, 255);
    box-sizing: content-box;
}
.draggable-list__item-container {
    width: 100%;
    box-sizing: border-box;
}

// .is-enable
.draggable-list__item {
    box-shadow: none !important;
    height: 222px;
    margin: 0 -0.625rem;
    padding: 0 0.625rem;
    overflow: hidden;
}
