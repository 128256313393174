.googleMap {
    position: relative;
}

@media (min-width: 768px) {
    .googleMap {
        > div {
            max-width: 40rem;
            padding: 0 1rem;
            width: 100%;
            max-width: initial;

            > div {
                height: 300px;
                position: relative !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .container--map {
        align-items: initial;
        height: 100%;

        > .container {
            min-height: 100%;
        }
    }

    .googleMap {
        margin: 0;
        height: 100%;

        > div {
            padding: 0;
        }
    }
}