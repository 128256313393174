// Labels

@mixin label-variant($color) {
    background-color: $color;

    &[href] {
        &:hover,
        &:focus {
            background-color: darken($color, 10%);
        }
    }
}
