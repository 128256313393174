.modal-header .close {
    position: absolute;
    top: 0.125rem;
    right: 0;
    z-index: 1;

    padding: 0.5rem;
    margin-top: initial;
    margin-right: 1rem;

    border: none;

    color: transparent;
    text-shadow: none;

    opacity: 1;
    background-color: transparent;
    background-image: url(../../images/styledmenu-close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;

    @include respond-to(desktop) {
        margin-right: 2rem;
    }
}

.navigational-list-item .close {
    width: 2rem;
    padding: 0.5rem;
    margin-top: initial;

    border: none;

    color: transparent;
    text-shadow: none;

    opacity: 1;
    background-color: transparent;
    background-image: url(../../images/styledmenu-close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1rem;
}

@media (max-width: 600px) {
    .modal-title {
        max-width: 85%;
        margin: auto;
    }
}

.modal-dialog {
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100%;
    margin: 0;

    @include respond-to(desktop) {
        padding: 7em 0 0 15em;

        .illustration-wrapper {
            margin-bottom: 1rem;
        }

        .justify-content-center {
            justify-content: initial;
        }

        p {
            margin: 0.5rem 0;
        }

        .btn:only-child.btn-block {
            width: auto;
        }

        .modal-body:first-child {
            margin-top: 12rem;

            .text-lead,
            .modal-title {
                text-align: center;
            }

            p {
                text-align: center;
            }

            .btn {
                display: inline-flex;

                width: initial;
                margin-top: 0;
            }
        }
    }

    .modal-content {
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;

        border: none;
        border-radius: 0;

        box-shadow: none;

        @include respond-to(mobile) {
            display: flex;
        }
        @include respond-to(desktop) {
            align-items: center;
        }
    }

    .modal-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .svg-warapper {
            width: 6rem;
            height: 6rem;
        }
    }

    .modal-open {
        .modal-container {
            min-height: 100%;
        }
    }

    .modal-body,
    .modal-footer,
    .modal-header {
        padding: 0;

        border: none;
    }

    .modal-body {
        display: flex;
        align-items: center;

        @include respond-to(mobile) {
            flex-grow: 1;
        }

        .media-object {
            figure {
                .svg-wrapper {
                    width: 6rem;
                    height: 6rem;

                    .svg-icon {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .modal-header {
        position: relative;

        margin-top: $m - ($s / 2);
    }

    .modal-footer {
        margin-bottom: $l;
    }

    //estilos modales
    .modal-title {
        color: $brand-primary;
        font-size: 1.5rem;

        @include respond-to(mobile) {
            font-size: 1.25rem;
            text-align: center;
        }
    }
}

.new-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    z-index: 9999;

    display: flex !important;

    width: 100%;
    height: 100%;
    padding: 0 2rem 0 18rem;

    form {
        padding: 0 2rem;
    }
}

.sidebar-collapsed {
    .new-modal-wrapper {
        padding: 0 2rem 0 6rem;
    }
}

.new-modal {
    margin: auto;

    border-radius: 10px;

    background-color: white;

    .container--layout {
        margin-bottom: 0.5rem;
    }
}

.new-modal-header {
    position: relative;

    padding: 0.5rem 0;
    margin-bottom: 0.5rem;

    border-bottom: 1px solid $gray-lighter;

    .btn {
        position: absolute;
        top: 0;
        right: 0;

        > span {
            @include visually-hidden;
        }
    }
}

.new-modal-title {
    color: $brand-primary;
    text-align: center;
}

.new-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.25);
}


@include respond-to(desktop) {
    .modal-dialog {
        padding: 1rem;

        .modal-content {
            display: flex;
            flex-grow: 0;

            max-width: 32rem;
            margin: auto;

            border-radius: 10px;

            .modal-body {
                margin-top: 0;
            }

            .modal-body:first-child {
                margin-top: 1.5rem;
            }
        }

        .modal-header {
            .modal-title {
                padding: 0 4.5rem;

                text-align: center;
            }

            .close {
                top: 0;
            }
        }

        .modal-footer {
            .btn.btn-primary {
                display: block;

                width: 100%;
            }
            .btn.btn-secondary {
                display: block;

                width: 100%;
            }
        }
    }
}


.giant-chronometer {
    margin: 0;

    font-size: 2.5rem;
    text-align: center;
}