.product-item {
    display: flex !important;
    align-items: center;
    flex-direction: column;

    span {
        display: inline;

        text-align: center;
    }

    .data-label {
        font-size: 1rem;
    }

    .data-desc {
        font-size: 1rem;
    }

    .data-name {
        color: $brand-primary;
        font-size: 1.25rem;
        font-weight: bold;
    }

    .data-amount {
        font-size: 2rem;
        text-align: right;
    }
}


@include respond-to(mobile) {
    .view-title + .product-item {
        margin-top: 0.5rem;
    }
}

.table-body > a {
    text-decoration: none;
    margin-bottom: 1rem;

    @include respond-to(mobile) {
        display: flex;
    }
}

// Product card

.product {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    height: 100%;
    padding: 1rem 2rem;
    margin: 0.5rem 0;

    border-radius: 0.2rem; // ??

    color: $gray-darker;
    text-align: center;
    text-decoration: none;

    background-color: lighten($brand-primary, 60%);

    &.is-expired {
        color: $product-expired--color;

        background-color: $product-expired--background-color;

        .product-name {
            background-color: $product-expired--color;
        }

        .data-name {
            color: $product-expired--color;
        }

    }

    .data-amount {
        font-size: 2.25rem;
    }
    .data-wrapper {
        align-items: center;
        width: 100%;
        margin: 0;
    }
    .product-title {
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
    }
}

/**
 * Label de producto, usualmente el nombre asociado a este; cuyo estilo se 
 * diferencia del resto, para este caso con un fondo de color.
 * Cuenta con varias partes que conforman este label.
 */

@include respond-to(mobile) {
    .toolbar {
        .product-name {
            margin-bottom: 0;
        }
    }
}

.table-row {
    width: 100%;
    &.is-expired .product-name span {
        padding: 0.125rem;
        border-radius: 0.125rem;
    }
    .product-name {
        padding: 0;
    }
}
.product-name {
    overflow: hidden !important;
    display: flex;
    align-items: stretch;
    flex-flow: wrap;
    justify-content: center;

    padding: 0;
    // padding: 0.75em 1em;
    padding: 0.25rem;

    margin: 0.25rem 0;

    border-radius: 5px;

    color: $product-name-tag--color;
    font-size: 1.25rem;
    text-transform: none;
    white-space: nowrap;

    background-color: initial;
    background-color: $product-name-tag--background-color;

    @include respond-to(desktop) {
        margin: 0;
    }
    @include when-inside(".product") {
        margin: $m 0;
    }
    @include respond-to(mobile) {
        margin: 0.5rem auto;
    }

    > span {
        padding: 0 0.25em;
        color: inherit;

        &:last-child {
            font-weight: bold;
        }
    }

    .svg-wrapper {
        position: relative;

        display: inline-flex;

        width: 2.25em;
        padding: 0 0.375em;

        > div {
            margin: 0 -0.25rem;
            border-radius: 0.25rem;
        }

        .svg-logo {
            width: 1.75em;
            height: 0.75em;
        }
    }

    .visa-color {
        fill:  white;
    }

    .mastercard-typo {
        fill:  white;
    }
    .mastercard-color0,
    .mastercard-color1,
    .mastercard-color2 {
        fill:  white;
        stroke: $product-name-tag--background-color;
        stroke-width: 6px;
    }
}

.toolbar-product-name {
    @include respond-to(mobile) {
        .product-name {
            background-color: transparent;
            color: $brand-color-primary;
            > span {
                background-color: transparent;
                color: $brand-color-black;
                font-weight: $brand-font-weight-regular;

            }

            span div .svg-wrapper{
                width: 1.25em;
                height: 1.25em;
                > div{
                    color: $brand-color-terciary;
                }
            }
        }

        .mastercard-color0,
        .mastercard-color1,
        .mastercard-color2 {
            stroke: $product-name-tag--background-color--external;
        }

        .product-name .svg-wrapper,
        .product-name .svg-wrapper > div {
            background-color: transparent;
            color: $brand-color-primary;
            
        }

        .holder-name{
            color: $brand-color-terciary;
            font-weight: bold;

            &.holder-name-account-header {
                color: $brand-color-black;
            }

        }

        .credit-info-wrapper1{
            //box-shadow: $box-shadow;
            border-bottom: none;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            margin: 0 2px;
            margin-top: 10px;
            background-size: 100% 100%;
        }
        .credit-info-wrapper2{
            //box-shadow: $box-shadow;
            border-top: none;
            border-bottom: none;
            margin: 0 2px;
        }
    }

    @include respond-to(desktop) {
        .product-name {

            &.product-name-account-header {
                background-color: transparent;
                color: $brand-color-primary;
                > span {
                    background-color: transparent;
                    color: $brand-color-black;
                    font-weight: $brand-font-weight-regular;
                }

                span div .svg-wrapper{
                    width: 1.25em;
                    height: 1.25em;
                    > div{
                        color: $brand-color-terciary;
                    }
                }

            }
        }
        .holder-name {
            &.holder-name-account-header {
                font-weight: bold;
                color: $brand-color-black;
            }
        }        
        .credit-info-wrapper1 {
            &.credit-info-wrapper1-account-header {
                //box-shadow: $box-shadow;
                border-bottom: none;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                margin: 0 2px;
                margin-top: 10px;
                background-size: 32%;
                margin-left: 20px;
                margin-right: 10px;
            }
        }

        .credit-info-wrapper2 {
            &.credit-info-wrapper2-account-header {
                //box-shadow: $box-shadow;
                border-top: none;
                border-bottom: none;
                margin: 0 2px;
                margin-left: 20px;
                margin-right: 10px;
            }
        }
    }
}

@include respond-to(mobile) {
    .credit-info-wrapper3{
        //box-shadow: $box-shadow;
        border-top: none;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        margin: 0 2px;

        .section-content-heading{
            margin: 0;
        }
    }

    .credit-info-wrapper4{
        box-shadow: $box-shadow;
        border-radius: 5px;
        margin: 0 auto;
        width: 100%;
        background-size: 100%;
        padding: 10px 0px 15px 0px;

        &.accounts-page {
            padding-bottom: 0;
        }
        
        .content-data.row{
            width: 100%;
            margin: 0 auto;

            .content-data-wrapper{
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }
    }

    .content-data-credit{
        width: fit-content;
        @media (max-width: $screen-iphonex-xs-min) {
            padding-left: 0;
        }
        display: inline;
        .content-data{
            display: inline-grid;
            .content-data-wrapper:last-of-type{
                grid-column: 2;
                grid-row: 1;

                &.pay-any-day-transfer{
                    grid-column: 3;
                    grid-row: 1;
                }
            }

            .content-data-wrapper .data-label{
                max-width: 135px;
                min-width: 80px;
            }
        }

        .content-data.row{
            width: 100%;
            margin: 0 auto;
        }
    }

    .period-filter-blank-space {
        height: 60px;
    }

    .account-info-link {
        text-align: right;
    }
}



@include respond-to(desktop) {
    .credit-info-wrapper3 {

        padding-left: 5%;
        padding-right: 5%;
        @media (min-width: $screen-md) {
            padding-left: 10%;
            padding-right: 10%;        
        }
        &.credit-info-wrapper3-account-header {
           // color: $brand-color-secondary;
           // box-shadow: $box-shadow;
            border-top: none;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            margin: 0 2px;
            display: flex;

            .section-content-heading{
                margin: 0;
                .container-fluid {
                    border-bottom: none;

                }
            }
            margin-left: 20px;
            margin-right: 10px;
        }
        &.payAnyDay-info-wrapper3-account-header {
            color: $brand-color-secondary;  
            box-shadow: $box-shadow;
            border-top: none;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            margin: 0 2px;
            display: flex;

            .section-content-heading{
                margin: 0;
            }
            margin-left: 4px;
            margin-right: 26px;
        }
    }

    .credit-info-wrapper4{
        box-shadow: $box-shadow;
        border-radius: 5px;
        margin: 0 auto;
        width: 100%;
        background-size: 100%;
        padding: 15px;
        
        &.accounts-page {
            padding-bottom: 0;
        }

        .content-data{
            display: inline-grid;

            .content-data-wrapper {
                &.col-md-3{
                    max-width: 100%;
                }
            }

            .content-data-wrapper:last-of-type{
                grid-column: 2;
                grid-row: 1;

                &.pay-any-day-transfer{
                    grid-column: 3;
                    grid-row: 1;
                }
            }


            .content-data-wrapper .data-label{
                max-width: 120px;
                min-width: 80px;
                color: #000;
            }

        }

        .content-data.row{
            width: 100%;
            margin: 0 auto;

            .content-data-wrapper{
                padding-right: 1rem;
                padding-left: 1rem;
                &.col-md-3{
                    max-width: 100%;
                }
            }
        }
    }

    .content-data-credit {
        &.content-data-credit-account-header {
            padding-bottom: 4%;
            width: 100%;
            color: $brand-color-base;
            .content-data{
                display: inline-grid;

                .content-data-wrapper {
                    &.col-md-3{
                        max-width: 100%;
                    }
                }

                .content-data-wrapper:last-of-type{
                    grid-column: 2;
                    grid-row: 1;

                    &.pay-any-day-transfer{
                        grid-column: 3;
                        grid-row: 1;
                    }
                }


                .content-data-wrapper .data-label{
                    max-width: 120px;
                    min-width: 80px;
                    color: black;
                }

            }

            .content-data.row{
                width: 100%;
                margin: 0 auto;
                justify-content: space-between;
            }
        }
    }

    .content-data {
        &.content-data-account-header {
            justify-content: space-between !important;
        }
    }

    .content-data-wrapper {
        &.content-data-wrapper-account-balance {
            
            text-align: center;
            .data-label {
                color: $brand-color-primary !important;
            }
        }

        &.content-data-wrapper-details-links {
            display: flex;
            justify-content: center;

            .content-data-wrapper{
                width: 30%;
        
                .detail-links-href{
                    height: 6.5rem;
                    width: 6.5rem;
                    box-shadow: $box-shadow;
                    border-radius: 1rem;
                    text-align: center;
                    display: grid;
                    align-content: center;
        
                    .svg-icon{
                        width: 2rem;
                        min-width: 2rem;
                        height: 2rem;
                    }
        
                    .svg-wrapper{
                        color: $brand-color-primary;
                        margin-top: 5px;
                    }
                }
        
                a{
                    color: $brand-color-black;
                    text-decoration: none;
                    font-weight: bold;
        
                    p{
                        font-size: 0.8rem;
                    }
                }
            }
        }

    }

    .detail-links-href {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .detail-links {
        &.details-links-href {
            display: flex;
            justify-content: center;
            border-bottom: none;
        }
    }

    .detail-links-grid {
        display: block;
    }

    .period-filter-blank-space {
        height: 160px;
    }

    .account-info-link {
        text-align: right;
        padding-right: 30px;
    }

    .content-data-item {
        &.content-data-item-credit {
            &::after {
                max-width: 100%;
            }
            &::before {
                max-width: 100%;
            }
        }
    }
}

.PAD-arrows{
    font-size: 2em;
    font-weight: bold;
    color: $brand-color-primary;
}

.account-info-link-component {
    color: #FD9900 !important;
}

.pay-any-day-styles{
    .content-data-credit{
        .content-data{
            grid-template-columns: 40%;

            .content-data-wrapper{
                font-size: 1rem;
                margin: auto;
            }
        }
    }

    a {
        font-weight: bold;
    }

    .pad-transaction{
        color: $brand-color-primary;
    }

    .pad-transaction-number{
        font-size: 1.5rem;
    }

    .data-label{
        color: $brand-color-primary;
    }
    .data-label-alt{
        color: grey;
    }

    .svg-icon-small{
        margin-left: 3px;
        width: 0.8rem;
        height: 0.8rem;
    }

    .pay-any-day-transfer{
        .data-label{
            .text-center{
                font-size: 1em;
            }
        }
    }

    .descriptionStatus{
        color: grey;
    }
}

.pad-form{
    .input-group{
        .form-control{
            font-family: $brand-font-family;
            // font-style: italic;
        }
    }
}

.detail-links{
    justify-content: space-evenly;
    margin: 10px;
    padding-bottom: 40px;
    padding-top: 1em;
    border-bottom: 1px solid $brand-color-primary;

    .content-data-wrapper{
        width: 30%;

        .detail-links-href{
            height: 6.5rem;
            width: 6.5rem;
            box-shadow: $box-shadow;
            border-radius: 1rem;
            text-align: center;
            display: grid;
            align-content: center;

            .svg-icon{
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
            }

            .svg-wrapper{
                color: $brand-color-primary;
                margin-top: 5px;
            }
        }

        a{
            color: $brand-color-black;
            text-decoration: none;
            font-weight: bold;

            p{
                font-size: 0.8rem;
            }
        }
    }
}


.credit-card-plastic{
    padding: 12px;
    width: 50%;
    @include respond-to(mobile){
        width: 100%;
    }
    .cc-plastic-wrapper{
        @include respond-to(desktop){
            top: 1.5rem;
            bottom: 1.5rem;
            left: 1.5rem;
            right: 1.5rem;
        }
        .data-label{
            left: 10%;
            @include respond-to(mobile){
                .data-label{
                    &.cc-card-number{
                        .data-aux{
                            font-size: .9rem;
                        }
                    }
                    &.cc-exp-date{
                        .data-aux{
                            font-size: .7rem;
                        }
                    }
                    &.cc-visa{
                        .data-aux{
                            font-size: 1rem;
                        }
                    }
                }
            }
            @include respond-to(desktop){
                .data-label{
                    &.cc-card-number{
                        .data-aux{
                            font-size: .9rem;
                        }
                    }
                    &.cc-exp-date{
                        .data-aux{
                            font-size: .7rem;
                        }
                    }
                    &.cc-visa{
                        .data-aux{
                            font-size: .8rem;
                        }
                    }
                }
            }
        }
    }
}

@include respond-to(desktop) {
    .product-item,
    .product-item span,
    .product-item .data-amount {
        align-items: initial;

        text-align: initial;
    }

    .detail-links {
        display: flex;
        // width: 100%;
        justify-content: space-around;
    }
}
.react-tabs__tab-panel .cc-plastic-wrapper .data-label.full-length{
    @include respond-to(mobile){
        margin-bottom: 3%;
    }
}

.product-label {
    display: flex;
    align-items: center;

    font-size: 1rem;
}
.product-label-info {
    font-size: 0.875rem;
}

.product-label-icon {
    display: flex;

    margin-right: 0.5rem;
}

/* Checkbox de producto */
.c-control-product {
    font-family: sans-serif;

    .product-name {
        font-size: 1rem;
    }

    .product-help {
        font-size: 0.75rem;
    }
}
