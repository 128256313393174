@import "theme-ui";
// @import "bootstrap-variables-overrides";

//-------------------------------------------------------------- external layout --------------------------------------------------------------//
.app {
    
    .header {
        background-color: #ffffff;
        padding: 2rem 4rem 1rem 4rem;
        z-index: 10;
    }

    .main {
        flex-grow: 1;
        overflow: auto;
        padding: 0;
        display: flex;

        .image-presentation {
            background-image: url("../../../images/bg_desktop.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;        
            width: 100%;
            height: 100%;
            vertical-align: middle;
            min-height: 460px;
            max-height: 460px;
            flex-grow: 1;
            flex: 0 0 50%;
            max-width: 50%; 
            position:relative;
        }
      
    }
}

.desktop {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .image-presentation {
        background-image: url("../../../images/bg_desktop.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;        
        width: 100%;
        height: 100%;
        vertical-align: middle;
        min-height: 500px;
        max-height: 460px;
        flex-grow: 1;
        flex: 0 0 50%;
        max-width: 50%; 
        position:relative;

        &.image-presentation-unlock-profile {
            background-image: url("../../../images/recover-profile-desktop.svg");
        }

        &.image-presentation-summary{
            background-image: url("../../../images/onboarding-summary-desktop.svg");
            background-size: contain;
        }
    }

    .formDesktop {
        position:relative;
        height: 100%;
        flex: 0 0 50%;
        max-width: 50%;
        overflow-y: hidden;

        .headerNav{
            display: flex; 

            .title{
                flex: 0 0 90%;
                max-width: 90%; 
                text-align: center;
            }

            .cross{
                flex: 0 0 10%;
                max-width: 10%; 
            }
        }

        .view-content {
            position:relative;
            height: 100%;        
        }

        .view-page {
            position:relative;
            height: 100%;
            overflow: auto;            
        }
        
        .buttonLogin{
            padding: 0rem 0rem 2rem 0rem;
        }
     
        &.formDesktop-agreements {
            flex: unset;
            max-width: unset;
            height: 90vh;
        }

        &.onboarding-agreements{
            height: 100%;
            .modal-footer {
                &.agreements {
                    padding-top: 10px;
                }
                .container {
                    padding-top: 10px;
                    .justify-content-center{
                        div{
                            width: 100%;
                            div{
                                width: 100%;
                                justify-content: center;
                                .agreement {
                                    font-size: 16px;
                                    line-height: 24px;
                                    padding-top: 18px;
                                    padding-bottom: 18px;
                                    padding-left: 0;
                                    padding-right: 0;
                                    max-width: none;
                                }
                            }

                        }
                    }
                }

            }
        }
    }
}

.display-amount{
    display: none;
}

.desktopLogin{
    background-image: url("../../../images/bg_desktop_login.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;        
    width: 100%;
    height: 100%;
    svg.logo{
        height: 120px;
        path{
            fill: white;
        }
    }
    .headerNav{
        display: flex;       

        .title{
            flex: 0 0 90%;
            max-width: 90%; 
            text-align: center;
            padding-left: 10%;
        }

        .cross{
            flex: 0 0 10%;
            max-width: 10%; 
        }
    }

}


.mobileLogin{
//background-image: url("../../../images/bg_mobile_login.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;        
    width: 100%;
    height: 100%;

    .login-initial-screen{
        .justify-content-center{
            .c-control{
                .c-control-label::before {
                    border: 1px solid $ui-white;
                    border-radius: 2px;
                }
                .form-group-text{
                    span{
                        font-weight: 100;
                        color: $ui-white;
                    }
                }
            }

            .text-lead{
                h2, p{
                    color: $ui-white;

                    button{
                        color: $ui-white;
                    }
                }
            }
        }
    }

    svg.logo{
        height: 120px;
        path{
            fill: white;
        }
    }
    .headerNav{
        display: flex;       

        .title{
            flex: 0 0 90%;
            max-width: 90%; 
            text-align: center;
            padding-left: 10%;
        }

        .cross{
            flex: 0 0 10%;
            max-width: 10%; 
        }
    }
}



.form-content{
    .form-group, .form-group.has-focus{        
        .borderDisabled{
            border: 1px solid $ui-gray-ultra-light;            
        }
        .disabled{
            background-color: $ui-white;
            color: $brand-color-primary;
        }
    }    
}



.divFeeSchedule{
    width:100%;
    border: 2px solid #A9A9A9;
    flex: 0 0 100%;

    .tableFeeSchedule{
        width:100%; 

        .trFeeSchedule{
            font-size: 0.6rem;
            .tdFeeSchedule{
                border-bottom: 0.5px solid #A9A9A9;           
            }
        }

        .fontTableFeeSchedule{
            font-weight: bold;
        }
    }

    .borderBotton{
        border-bottom: 1px solid #000000;    
    }
}



.modalFeeSchedule{
    width:100%;
    height: 100%;
    .terms-conditions-text{
        font-size: 0.6rem;
    }

    table{
        width: 100%;
        margin-bottom: 10px;
    }
}

.greetingMobile{
    padding: 20px 0 0 0;

    h2{
        color: black;
    }
}

.detail-links-dashboard{
    justify-content: space-evenly;
    margin: 10px;
    padding-top: 10px; 
    border-bottom: 1px;
    
    .content-data-wrapper{
        .detail-links-href{
            height: 6.5rem;
            width: 6.5rem;
            box-shadow: $box-shadow;
            border-radius: 1rem;
            text-align: center;
            display: grid;
            align-content: center;
        }

        a{
            color: $brand-color-primary;
            text-decoration: none;

            p{
                color: $brand-color-black;
                text-decoration: none;
                font-weight: bold;
                font-size: inherit;
            }
        }
    }
}

.widget-account-detail-title-name {
    overflow: hidden !important;
    color: #000000;
    display: flex;
    align-items: stretch;
    flex-flow: wrap;
    justify-content: center;
    padding-top: 0.25rem;
    border-radius: 5px;
    font-size: 1.25rem;
    text-transform: none;
    white-space: nowrap;
    background-color: initial;
    font-weight: bold;
}

.widget-account-detail-title-number {
    overflow: hidden !important;
    color: #000000;
    display: flex;
    align-items: stretch;
    flex-flow: wrap;
    justify-content: center;    
    border-radius: 5px;
    font-size: 1.25rem;
    text-transform: none;
    white-space: nowrap;
    background-color: initial;
}

.widget{
    
    .credit-info-wrapper4{
        margin-top: 1rem;
     
        .container:before, .container:after {
            display: none;
        }
    }
}

input[type=number].no-spinner::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    appearance: none; 
    margin: 0; 
}

