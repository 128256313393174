.backoffice-layout {
    height: auto !important;
    .row{
        margin-right: 0;
        margin-left: 0;
    }
    @include respond-to(mobile) {
        .toolbar-item.view-title {
            margin: 0;
        }
    }
    .navbar-header {
        padding: 0;
    }
     > .container--layout {
         > .container-fluid,
         > .container {
            max-width: initial;
            padding: 0;
        }
    }
    .text-lead {
        text-align: left;
    }
    .view-title {
        text-align: left;
    }

    .view-page {
        &::after,
        &::before {
            content: none;
        }
    }
    .toolbar-item--fixed {
        display: none;
    }

    .above-the-fold {
        .container--layout {
             > .container-fluid,
             > .container {
                max-width: initial;
                padding: 0;

                .col {
                    flex: 0 0 100%;

                    max-width: 100%;
                }

                .container-fluid,
                .container {
                    max-width: initial;
                    // padding: 0;

                    .col {
                        flex: 0 0 100%;

                        max-width: 100%;
                    }
                }
            }
        }
    }
}
