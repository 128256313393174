/* Botones */

.btn {
    white-space: initial;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: $brand-font-family-bold;
    font-weight: $brand-font-weight-bold;


    >span {
        /* esto esta bien para arreglar lo de safari? */
        // width: 100%;
        text-align: center;
    }

    >* {
        margin: 0 0.3em;
    }

    .svg-wrapper,
    svg {
        width: 1em;
        height: 1em;
        min-width: initial;
    }
}


.btn.btn-primary {
    // se pisa el color por que segun color-yiq deberia de ser oscuro
    color: white;
    max-width: $btn-primary--width;
    max-height: $btn-primary--heigth;
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-right: auto;
    margin-left: auto;

    &.btn-circle-bg {
        background-color: #98CFDE;
        border-color: #98CFDE;
    }
}

.btn.btn-secondary {
    // se pisa el color por que segun color-yiq deberia de ser oscuro
    color: $btn--color(128, 123, 123);
    max-width: $btn-primary--width;
    max-height: $btn-primary--heigth;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding-top: 18px;
    padding-bottom: 18px;
    margin-right: auto;
    margin-left: auto;

    &.btn-circle-bg {
        background-color: #98CFDE;
        border-color: #98CFDE;
    }
}

.btn.btn-primary:hover {
    color: white;
}

.btn.btn-primary:active:hover,
.btn.btn-primary:active:focus,
.btn.btn-primary:active.focus,
.btn.btn-primary.active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active.focus,
.open>.btn.btn-primary.dropdown-toggle:hover,
.open>.btn.btn-primary.dropdown-toggle:focus,
.open>.btn.btn-primary.dropdown-toggle.focus {
    color: white;
}

.btn.btn-only-icon {
    padding: 0.5rem 1rem;

    background-color: transparent;
    border: none;
    color: $brand-info;

    &:hover,
    &:focus,
    &:active,
    &:active:hover {
        // box-shadow: none;
        background-color: transparent;
        color: $brand-info;
    }

    .svg-wrapper {
        width: 1rem;
        height: 1rem;
    }
}

.btn-block+.btn-block {
    margin-top: $m;
}

.btn.is-loading {
    transition: 0.1s ease;
    background-color: darken($brand-info, 20%);
    position: relative;

    display: flex;
    margin: auto;

    .btn-loading-text {
        @include visually-hidden;
    }

    .btn-loading-indicator {

        span:nth-child(1),
        span:nth-child(2),
        span:nth-child(3) {
            animation: bouncedelay 1.4s infinite ease-in-out both;
            content: "";
            margin: 0 0.3em;
            width: 0.5rem;
            top: 0.25em;
            left: -1rem;
            height: 0.5rem;
            background-color: currentColor;
            border-radius: 100%;
            display: inline-block;
        }

        span:nth-child(2) {
            left: 0;
            animation-delay: 0.16s;
        }

        span:nth-child(3) {
            left: 1rem;
            animation-delay: 0.32s;
        }
    }
}

.container-navbar{
    .btn.btn-cancel{
        border: none;

        &:hover{
            color: darken($brand-info, 10%);
            background-color: transparent;
        }
        
        span{
            display: none;
        }

        svg{
            width: 0.8em;
            height: 0.8em;
        }
    }

    @include respond-to(mobile) {
        &.container-navbar-unlock-profile {
            justify-content: center;
        }
    }
}

.btn-facebook {
    @include button-variant($btn-facebook-color, $btn-facebook-bg, $btn-facebook-border);
}

.btn-flex {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    &.btn-facebook .svg-icon {
        border-color: transparentize($btn-facebook-color, 0.8);
    }

    &.btn-default .svg-icon {
        border-color: transparentize(color-yiq($btn-default-color), 0.8);
    }

    &.btn-outline .svg-icon {
        border-color: currentColor;
    }

    .svg-wrapper {
        width: 2rem;
    }

    .svg-wrapper,
    svg {
        width: 2.8rem;
        height: 2.8rem;
        min-width: initial;
    }

    span {
        text-align: center;
        flex: 1;
    }
}

.btn.btn-outline {
    appearance: initial;
    border-color: currentColor;
    background-color: transparent;
    color: $brand-info;

    &:hover {
        border-color: $brand-color-accent;
        color: white;
        background: $brand-color-accent;
    }
}

.btn.btn-outline-contrast {
    color: inherit;

    &:hover {
        color: inherit;

        border-color: $brand-info; //$brand-info;
        background-color: $brand-info; //$brand-info;
    }
}

.btn-outline {
    &.btn-outline-edit-desktop {
        max-width: 150px;
        max-height: 60px;
        font-size: 14px;
        &:hover .svg-wrapper path{
            fill: white !important;
        }
    }
}

.btn.btn-link {
    border: 1px solid transparent;
    background-color: transparent;
    color: $brand-info; //$brand-info !important;

    &:hover {
        text-decoration: none;
        background-color: $brand-info; //$brand-info;
        color: white; // color-yiq($brand-info; //$brand-info) !important;
    }
}

// Renders exactly a link in a button tag
.btn.btn-asLink {
    border: none;
    text-decoration: underline;
    color: $brand-info;
    font-weight: normal;
    vertical-align: baseline;
    font-size: 1rem;
    font-family: sans-serif; //$font-family-base;
    text-transform: none;
    padding: 0;
    display: inline;
    background-color: transparent;

    >span {
        margin: 0;
        font-family: inherit;
    }

    &:hover {
        color: darken($brand-info, 5%);
        background-color: transparent;
    }

    &:focus {
        background-color: transparent;
    }
}


.btn-close-session .svg-icon {
    width: 1.75em;
    height: 1.75em;
}

.btn.btn-ico {
    color: $brand-info;
    border: none;
    background-color: transparent;
    // margin-left: -0.75rem;
    padding: 0;

    @include respond-to(mobile) {
        justify-content: initial;
    }

    .svg-wrapper {
        background-color: transparentize($brand-info, 0.85);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        margin-left: 0;

        svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    &:hover {
        background-color: transparent;
        color: darken($brand-info, 5%);

        .svg-wrapper {
            color: white;
            background-color: $brand-info;
        }
    }

    &:focus,
    &:focus:active,
    &:active {
        box-shadow: none;

        .svg-wrapper {
            box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.4);
        }
    }
}

/* Botones de login paso 0 en mobile */

.btn.icon-with-border {
    padding: 0.375rem 1rem !important;

    .svg-wrapper,
    .svg-wrapper svg {
        width: 2.4rem;
        height: 2.4rem;
        min-width: initial;

        +span {
            padding-left: 1em;
        }

        .svg-icon {
            border-right: 1px solid;
            padding-right: 1rem;

            box-sizing: initial;

            >div {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

.view-title .btn-only-icon {
    >span {
        @include ellipsis;
    }
}

@include respond-to(desktop) {
    .app-page {
        
        .btn-only-icon {
            border: 1px solid currentColor;

            .svg-wrapper:first-child {
                margin-right: 0.5rem;
            }

            .svg-wrapper:last-child {
                margin-left: 0.5rem;
            }
        }

        .back-btn {
            padding-left: 0;
            padding-right: 0;
            border: none;
            font-size: 1.1rem;
        }

        .btn-block:not(.btn-image) {
            display: flex;
            margin-right: auto;
            margin-left: auto;
            width: 100%;

            &:not(:last-child) {
                
                //margin-right: 1rem;  this came with the starter kit, I'll leave it commented just in case
            }
        }
        .text-right .btn-block:not(.btn-image) {
            &:not(:last-child) {
                margin-right: 0;
            }

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    .enrollment-layout {
        .app-page {
            .btn-block+.btn-block:not(.btn-image) {
                margin-top: $m;
            }
        }
    }
}

.app-page td button.btn.btn-circle-bg {
    flex-flow: row wrap;
    border-radius: 50%;
    width: 2.25em;
    padding: 0.5em;

    .svg-wrapper,
    svg {
        width: 1.1em;
        height: 1.1em;
    }
}

.app-page .view-header .btn.btn-default {
    width: auto;
}

p .btn.btn-quiet {
    padding: 0;
    font-size: 1em;
    vertical-align: baseline;

    span {
        font-family: $font-family-base;
    }
}

.btn.btn-quiet {
    border: none;
    color: $brand-info;
    font-weight: normal;
    text-transform: none;
    padding-left: 0;
    padding-right: 0;
    padding: 0.5em;
    background-color: transparent;

    >span {
        margin: 0;
    }

    &:hover {
        color: #2c9fbe; //darker($brand-info, 5%);
        background-color: transparent;
    }

    &:focus {
        background-color: transparent;
    }
}


.btn.btn-quiet {

    @include respond-to(desktop) {
        padding: 0.5rem !important;
        font-weight: bold;
    }

    &:hover {
        color: white;
        background-color: $brand-info;
    }

    &:active {
        color: white;
        background-color: $brand-primary;
    }

    &:focus,
    &:active,
    &:focus:active {
        text-decoration: none;
        box-shadow: 0 0 2px rgba(black, 0.25);
    }

    &.is-loading {
        background-color: transparent;

        .btn-loading-indicator {

            span {
                width: 0.375em;
                height: 0.375em;
            }
        }
    }
}

.theme-auth .view-header .navbar .btn.btn-only-icon.btn-cta {
    background-color: $brand-info;
    color: white;
    border-color: transparent;
    .svg-wrapper svg path{
        fill: white;
    }
}

.btn.btn-small {
    padding: 0.5rem;
}

.btn.btn-only-icon.btn-circle {
    padding: 0;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    border-radius: 50%;

    .svg-wrapper {
        margin: 0;

        svg {
            width: 1.25em;
            height: 1.25em;
        }
    }

    >span {
        @include visually-hidden;
    }
}

.theme-auth .btn.btn-only-icon.btn-circle {
    &:hover {
        background-color: $brand-info;
        border-color: $brand-info;
        color: white;
    }

    &:active:hover {
        color: white;
    }

    &:focus,
    &:focus:active,
    &:active {
        outline: none;
        box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.4);
    }
}

/* Botones como controles */
.btn.is-active {
    background-color: #c4e8f2;
    color: $gray-darker;
}

// .btn-quiet {
//     padding-left: 0 !important;
//     padding-right: 0 !important;
// }


/**
 * Desarrollo del estado :focus para los botones
 */

.btn {

    &:focus,
    &:focus:active,
    &:active {
        outline: none;
        box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.4);
    }

    &.btn-quiet {

        &:focus,
        &:focus:active,
        &:active {
            outline: initial;
            box-shadow: none;
        }
    }
}

.btn-link:hover,
.btn-link:focus {
    text-decoration: none;
}

.app-page .btn-list-item {
    margin-left: 1rem;
    border: none;
}

.view-close .svg-wrapper svg {
    width: 0.875em;
    height: 0.875em;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    pointer-events: none;
}