.rc-table {
    font-size: 12px;
    color: #666;
    transition: opacity 0.3s ease;
    position: relative;
    line-height: 1.5;
    overflow: hidden;
}

.rc-table .rc-table-scroll {
    overflow: auto;
    margin-right: -1rem;
}

.rc-table .rc-table-scroll table {
    width: auto;
    min-width: 100%;
}

.rc-table .rc-table-header {
    overflow: hidden;
    background: #f7f7f7;
}

.rc-table-fixed-header .rc-table-body {
    background: white;
    position: relative;
}

.rc-table-fixed-header .rc-table-body-inner {
    height: 100%;
    overflow: scroll;
}

.rc-table-fixed-header .rc-table-scroll .rc-table-header {
    overflow-x: scroll;
    padding-bottom: 20px;
    margin-bottom: -20px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.rc-table-fixed-columns-in-body {
    visibility: hidden;
    pointer-events: none;
}

.rc-table .rc-table-title {
    padding: 16px 8px;
    border-top: 1px solid #e9e9e9;
}

.rc-table .rc-table-content {
    position: relative;
}

.rc-table .rc-table-footer {
    padding: 16px 8px;
    border-bottom: 1px solid #e9e9e9;
}

.rc-table .rc-table-placeholder {
    padding: 16px 8px;
    background: white;
    border-bottom: 1px solid #e9e9e9;
    text-align: center;
    position: relative;
}

.rc-table .rc-table-placeholder-fixed-columns {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: transparent;
    pointer-events: none;
}

.rc-table table {
    width: 100%;
    border-collapse: separate;
    text-align: left;
}

.rc-table th {
    background: #f7f7f7;
    font-weight: bold;
    transition: background 0.3s ease;
}

.rc-table td {
    border-bottom: 1px solid #e9e9e9;
}

.rc-table td:empty:after {
    content: ".";
    visibility: hidden;
}

.rc-table tr {
    transition: all 0.3s ease;
}

.rc-table tr:hover {
    background: #eaf8fe;
}

.rc-table tr.rc-table-row-hover {
    background: #eaf8fe;
}

.rc-table th,
.rc-table td {
    padding: 16px 8px;
    white-space: nowrap;
}

.rc-table-expand-icon-col {
    width: 34px;
}

.rc-table-row-expand-icon,
.rc-table-expanded-row-expand-icon {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    border: 1px solid #e9e9e9;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: white;
}

.rc-table-row-spaced,
.rc-table-expanded-row-spaced {
    visibility: hidden;
}

.rc-table-row-spaced:after,
.rc-table-expanded-row-spaced:after {
    content: ".";
}

.rc-table-row-expanded:after,
.rc-table-expanded-row-expanded:after {
    content: "-";
}

.rc-table-row-collapsed:after,
.rc-table-expanded-row-collapsed:after {
    content: "+";
}

tr.rc-table-expanded-row {
    background: #f7f7f7;
}

tr.rc-table-expanded-row:hover {
    background: #f7f7f7;
}

.rc-table-column-hidden {
    display: none;
}

.rc-table-prev-columns-page,
.rc-table-next-columns-page {
    cursor: pointer;
    color: #666;
    z-index: 1;
}

.rc-table-prev-columns-page:hover,
.rc-table-next-columns-page:hover {
    color: #2db7f5;
}

.rc-table-prev-columns-page-disabled,
.rc-table-next-columns-page-disabled {
    cursor: not-allowed;
    color: #999;
}

.rc-table-prev-columns-page-disabled:hover,
.rc-table-next-columns-page-disabled:hover {
    color: #999;
}

.rc-table-prev-columns-page {
    margin-right: 8px;
}

.rc-table-prev-columns-page:before {
    content: "<";
}

.rc-table-next-columns-page {
    float: right;
}

.rc-table-next-columns-page:before {
    content: ">";
}

.rc-table-fixed-left,
.rc-table-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
}

.rc-table-fixed-left table,
.rc-table-fixed-right table {
    width: auto;
    background: white;
}

.rc-table-fixed-left {
    left: 0;
    box-shadow: 4px 0 4px rgba(100, 100, 100, 0.1);
}

.rc-table-fixed-left .rc-table-body-inner {
    margin-right: -20px;
    padding-right: 20px;
}

.rc-table-fixed-header .rc-table-fixed-left .rc-table-body-inner {
    padding-right: 0;
}

.rc-table-fixed-right {
    right: 0;
    box-shadow: -4px 0 4px rgba(100, 100, 100, 0.1);
}

.rc-table-fixed-right .rc-table-expanded-row {
    color: transparent;
    pointer-events: none;
}

.rc-table.rc-table-scroll-position-left .rc-table-fixed-left {
    box-shadow: none;
}

.rc-table.rc-table-scroll-position-right .rc-table-fixed-right {
    box-shadow: none;
}

.rc-pagination {
    font-size: 12px;
    font-family: "Arial";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0;
}

.rc-pagination>li {
    list-style: none;
}

.rc-pagination-total-text {
    float: left;
    height: 30px;
    line-height: 30px;
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
}

.rc-pagination:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
}

.rc-pagination-item {
    cursor: pointer;
    border-radius: 6px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    list-style: none;
    float: left;
    border: 1px solid #d9d9d9;
    background-color: white;
    margin-right: 8px;
}

.rc-pagination-item a {
    text-decoration: none;
    color: #666;
}

.rc-pagination-item:hover {
    border-color: #2db7f5;
}

.rc-pagination-item:hover a {
    color: #2db7f5;
}

.rc-pagination-item-active {
    background-color: #2db7f5;
    border-color: #2db7f5;
}

.rc-pagination-item-active a {
    color: white;
}

.rc-pagination-item-active:hover a {
    color: white;
}

.rc-pagination-jump-prev:after,
.rc-pagination-jump-next:after {
    content: "•••";
    display: block;
    letter-spacing: 2px;
    color: #ccc;
    font-size: 12px;
    margin-top: 1px;
}

.rc-pagination-jump-prev:hover:after,
.rc-pagination-jump-next:hover:after {
    color: #2db7f5;
}

.rc-pagination-jump-prev:hover:after {
    content: "«";
}

.rc-pagination-jump-next:hover:after {
    content: "»";
}

.rc-pagination-jump-prev-custom-icon,
.rc-pagination-jump-next-custom-icon {
    position: relative;
}

.rc-pagination-jump-prev-custom-icon:after,
.rc-pagination-jump-next-custom-icon:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: all 0.2s;
    content: "•••";
    opacity: 1;
    display: block;
    letter-spacing: 2px;
    color: #ccc;
    font-size: 12px;
    margin-top: 1px;
}

.rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon .custom-icon-jump-next {
    opacity: 0;
    transition: all 0.2s;
}

.rc-pagination-jump-prev-custom-icon:hover:after,
.rc-pagination-jump-next-custom-icon:hover:after {
    opacity: 0;
    color: #ccc;
}

.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
.rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
.rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
    opacity: 1;
    color: #2db7f5;
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    margin-right: 8px;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
    cursor: pointer;
    color: #666;
    font-size: 10px;
    border-radius: 6px;
    list-style: none;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    float: left;
    text-align: center;
}

.rc-pagination-prev a:after {
    content: "‹";
    display: block;
}

.rc-pagination-next a:after {
    content: "›";
    display: block;
}

.rc-pagination-prev,
.rc-pagination-next {
    border: 1px solid #d9d9d9;
    font-size: 18px;
}

.rc-pagination-prev a,
.rc-pagination-next a {
    color: #666;
}

.rc-pagination-prev a:after,
.rc-pagination-next a:after {
    margin-top: -1px;
}

.rc-pagination-disabled {
    cursor: not-allowed;
}

.rc-pagination-disabled a {
    color: #ccc;
}

.rc-pagination-options {
    float: left;
    margin-left: 15px;
}

.rc-pagination-options-size-changer {
    float: left;
    width: 80px;
}

.rc-pagination-options-quick-jumper {
    float: left;
    margin-left: 16px;
    height: 28px;
    line-height: 28px;
}

.rc-pagination-options-quick-jumper input {
    margin: 0 8px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    outline: none;
    padding: 3px 12px;
    width: 50px;
    height: 28px;
}

.rc-pagination-options-quick-jumper input:hover {
    border-color: #2db7f5;
}

.rc-pagination-options-quick-jumper button {
    display: inline-block;
    margin: 0 8px;
    font-weight: 500;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 12px;
    border-radius: 6px;
    height: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: rgba(0, 0, 0, 0.65);
    background-color: white;
    border-color: #d9d9d9;
}

.rc-pagination-options-quick-jumper button:hover,
.rc-pagination-options-quick-jumper button:active,
.rc-pagination-options-quick-jumper button:focus {
    color: #2db7f5;
    background-color: white;
    border-color: #2db7f5;
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
    border: none;
    height: 24px;
    line-height: 24px;
    margin: 0;
    font-size: 18px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
    float: left;
    margin-right: 8px;
    list-style: none;
}

.rc-pagination-simple .rc-pagination-simple-pager .rc-pagination-slash {
    margin: 0 10px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
    margin: 0 8px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    outline: none;
    padding: 5px 8px;
    min-height: 20px;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
    border-color: #2db7f5;
}

.rc-pagination-simple .rc-pagination-simple-pager button {
    display: inline-block;
    margin: 0 8px;
    font-weight: 500;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 6px;
    height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: rgba(0, 0, 0, 0.65);
    background-color: white;
    border-color: #d9d9d9;
}

.rc-pagination-simple .rc-pagination-simple-pager button:hover,
.rc-pagination-simple .rc-pagination-simple-pager button:active,
.rc-pagination-simple .rc-pagination-simple-pager button:focus {
    color: #2db7f5;
    background-color: white;
    border-color: #2db7f5;
}

@media only screen and (max-width: 1024px) {

    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
        display: none;
    }
}

.rc-table-content {
    font-size: 1rem;
}

// .rc-table th {
//     // border-bottom: 1px solid #eeeeee;
// }
.rc-table td {
    border-bottom: 1px solid #eeeeee;
}

.rc-table th,
.rc-table td {
    padding: 0.5rem;
    white-space: normal;

    &.text-ellipsis {
        white-space: nowrap;
        max-width: 0;
        /* Avoid resizing beyond table width */
        @include ellipsis;
    }

    &:first-of-type {
        padding-left: 1rem;
    }

    &:last-of-type {
        padding-right: 1rem;
    }
}

.rc-table th {
    color: $brand-primary;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: normal;
}

.rc-table .rc-table-title {
    border-top: none;
    color: $brand-primary;
    padding: 1rem 0;
}

.rc-table .rc-table-header {

    &,
    & table,
    & .rc-table-thead,
    & tr,
    & th {
        background-color: transparent;
    }
}

.rc-table-row:hover {
    cursor: pointer;
}

.rc-table {

    th:first-child,
    td:first-child {
        >.c-control {
            margin: 0;
        }
    }
}

.rc-table th {
    background-color: transparent;
}

.rc-table .rc-table-thead tr:hover {
    background: transparent;
}