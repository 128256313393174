.billpay-update-payee {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    .heading {
        @include fully-centered-row;

        .separator {
            padding: 0 3px;
            margin: 0;

            &:before {
                content: "-";
                display: inline-flex;
            }
        }
    }

    .billpay-logo {
        padding: 8px 0;
    }

    .billpay-update-payee-form {
        .content-wrapper,
        .form-footer {
            @include fully-centered-row;
        }

        .content-wrapper {
            .fields-wrapper {
                @include fully-centered-col;
            }
        }
    }

    .billpay-updatepayee-form {
        .form-content{
            padding-left: 0;
            padding-right: 0;
        }
    }


    .billpay-update-payee-summary {
        @include row-v_start-h_space_between;
        padding: 20px 40px;

        > div {
            @include col-v_start-h_start;
            width: 50%;
        }

        p {
            @include col-v_start-h_start;
            margin-bottom: 20px;

            .summary-label {
                color: $brand-color-black;
                font-weight: bold;
            }

            .summary-value {
                color: $brand-color-black;
            }
        }
    }

    .new-modal-wrapper {
        
        .new-modal {
            @include fully-centered-col;
            width: 470px;
            background-color: #ffffff;

            .new-modal-header {
                border-bottom: none;
            }

            .description {
                text-align: center;
            }

            article {
                padding: 0;

                .btns-wrapper {
                    @include fully-centered-col;
                    padding: 10px 0;

                    .btn.btn-link {
                        text-transform: none;
                        height: 59px;

                        &:hover, &:active {
                            background-color: #ffffff;
                            border: 3px solid $brand-color-primary;
                            color: $brand-color-primary !important;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .billpay-update-payee {
        .billpay-update-payee-form {
            .content-wrapper,
            .form-footer {
                @include fully-centered-col;
            }
        }
    }

    .billpay-summary {
        .billpay-summary-content-wrapper {
            &.update-schedule-summary-wrapper{
                padding-left: 0;
                padding-right: 0;
            }
            .billpay-summary-content {
                .billpay-summary-content-body {
                    .billpay-update-payee-summary {
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        > div {
                            @include col-v_start-h_start;
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}

.update-payee-buttons {
    .container {
        div {
            div{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}