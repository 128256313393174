/**
 * Soluciona un bug de react slick en un contendor, (incluso con ancestros flex)
 * al intentar calcultar el ancho de los elementos.
 */

.app-content {
    min-width: 0;
}

.widget {
    position: relative;
    margin-bottom: $l;
    .widget__title,
    h3 {
        width: 100%; //border-bottom: 1px solid $brand-color-element;
        padding: 0.5rem ($l / 2);
        margin-top: 0;
        border-radius: 2px;
        color: $widget-title--color;
        font-size: 1.125rem;
        font-weight: bold;
        text-align: center;
    }
}

.widget--notifications {
    @include respond-to(mobile) {
        .table-data {
            max-width: 80%;
        }
    }
}

.widget--exchangeRates {
    padding: 0;

    border-radius: 10px;

    @include respond-to(mobile) {
        .data-wrapper {
            .data-label {
                width: 100%;
                margin-top: 1rem;
                text-align: center;
            }
            .data-amount {
                width: 100%;
                text-align: center;
            }
        }
        .slick-slide > div {
            display: flex;
            padding: 0;
        }
        .slick-dots {
            position: relative;
            margin-top: 1rem;
        }
    }
    .exchenge-data {
        margin: auto;
        text-align: center;

        @include respond-to(mobile) {
            display: flex;
            align-items: center;
            justify-content: center;
            .flag {
                margin: 0 1rem;
                .svg-wrapper,
                svg {
                    width: 3rem;
                    height: 3rem;
                    min-width: 3rem;
                }
            }
            span.data-name {
                position: initial;
                top: initial;
                left: initial;
                float: initial;

                display: initial;
                align-self: initial;

                height: initial;
                max-width: initial;

                text-align: initial;
            }
        }
        @include respond-to(desktop) {
            .flag {
                float: left;
                height: $s;
            }
        }
        .data-name {
            position: relative;
            top: 13px;
            left: 0.5rem;
            float: left;
            display: flex;
            align-self: center;
            height: 3rem;
            max-width: 75%;
            text-align: left;
        }
    }
    h4 {
        align-self: center;
        margin-left: -1rem;
        font-size: 1rem;
        line-height: 1.1rem;
    }
    .flag {
        max-width: 8rem;
    }
}

.widget--bio-banner {
    font-family: $headings-font-family;
    background-color: lighten($brand-warning, 30%);

    h4 {
        color: $widget-title--color;
        font-size: 1.3rem;
    }

    p,
    label {
        color: $gray-darker;
    }
}

.widget__box {
    padding: 1rem;
    border-radius: 10px;
}

.widget--portfolio {
    height: 100%;
    .chart {
        min-height: 300px;
    }
    .chart .row {
        height: 100%;

        > div {
            align-self: center;
            width: 100% !important;
        }
    }

    /* Reset sizing on chart */
    #DonutChart,
    #DonutChart > div,
    #DonutChart [dir] {
        width: 100% !important;
        height: 100% !important;
    }

    #DonutChart [dir] [aria-label] {
        display: flex;
        justify-content: center;
    }

    #DonutChart,
    .data-chart {
        max-width: 400px;

        @media (max-width: 575px) {
            max-width: initial;
            margin: auto;
        }
    }

    .data-chart {
        position: absolute;
        z-index: initial;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-top: initial;
        text-align: center;
        .amount {
            text-align: right;
            margin: 0;
            font-size: 1.1rem;
        }

        .products {
            margin-top: 0;
            font-size: 0.75rem;
        }
        small {
            font-size: 0.75rem;
            font-weight: bold;
        }

        /* Stack vertically chart and references */
        @media (max-width: 575px) {
            right: 0;
            left: 0;
        }
    }

    .portfolio-item {
        border-radius: 5px;
        color: $widget-porfolio-items--color;
        display: block;
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
        padding: 1rem;
        text-decoration: none;

        > .row {
            align-items: center;
            justify-content: space-between;
        }
        .data-title {
            font-size: 1.125rem;
            font-family: sans-serif;
        }

        .data-aux {
            font-size: 0.75rem;
        }

        .data-wrapper {
            margin: 0;
        }

        &.liquidity {
            @include colorize-button($widget-porfolio-liquidity--background-color);
        }
        &.fixedIncomes {
            @include colorize-button($widget-porfolio-fixedIncomes--background-color);
        }
        &.variableIncomes {
            @include colorize-button($widget-porfolio-variableIncomes--background-color);
        }
        &.others {
            @include colorize-button($widget-porfolio-others--background-color);
        }
        &.empty {
            background-color: $widget-porfolio-empty--background-color;
            color: white;
            pointer-events: none;

            &:hover,
            &:focus {
                background-color: $widget-porfolio-empty--background-color;
            }
        }   

        .chart {    
            min-height: 300px;
        }

        #DonutChart,
        .data-chart {
            max-width: initial;
            margin: auto;
        }

        .data-chart {
            .amount .data-amount {
                font-size: 1.6rem;
            }
        }
        .data-chart {
            right: 0;
            left: 0;
        }
    }
}
/* Prevent google charts to create layer n perf issues */
#DonutChart [aria-label] > div[aria-label] {
    left: initial !important;

    @include visually-hidden;
}

/* estilos para widgets en estado de edición */
.draggable-list .draggable-list__item {
    padding: 0;
    margin: 0;

    border: solid 1px lighten($brand-primary, 60%);
    border-radius: 10px;
}

.draggable-list__item-container {
    height: 100%;

    .widget {
        height: 100%;
        padding: 0.25rem;
        margin-bottom: 1rem !important;

        h3 {
            padding: 0.5rem (($l / 2) - 0.25rem);
            margin: 0 0 1rem;
        }
        &:active {
            border: solid 1px $widget-title--color;
        }
        .container,
        .container-fluid {
            padding: 0 (($l / 2) - 0.25rem);
        }
        .widget-close-button {
            position: absolute;
            top: 0.25rem;
            right: 4px;
            padding: 0.65rem;

            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;

            @include respond-to(desktop){
                top: -1px;
                right: -6px;

                &:hover{
                    background-color: transparent;
                }
            }
        }
        .overlay {
            position: absolute;
            top: 3.3rem;
            right: 0;
            bottom: 0;
            left: 0;

            display: block;

            border-radius: 10px;

            background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9));
        }
    }
}

.widget-preloader {
    position: relative;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 120px;
    border-radius: 0;
    color: $brand-color-primary;
    opacity: 1;
    background-color: rgba(239, 237, 247, 0.2);
    animation: overlayAppear 0.5s ease;
    animation-fill-mode: forwards;
    transition: 0.1s ease;
    pointer-events: all;
    > div {
        margin: auto;
    }

    span:nth-child(1),
    span:nth-child(2),
    span:nth-child(3) {
        top: 0.25em;
        left: -1rem;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin: 0 0.3em;
        border-radius: 100%;
        content: "";
        background-color: currentColor;
        animation: bouncedelay 1.4s infinite ease-in-out both;
    }

    span:nth-child(2) {
        left: 0;
        animation-delay: 0.16s;
    }

    span:nth-child(3) {
        left: 1rem;
        animation-delay: 0.32s;
    }
}
