$semibold: 600;

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
button,
.btn span,
.text-lead,
.data-label,
.c-control-label,
.form-group-label,
.control-label {
    font-family: $headings-font-family;
}

.data-label,
// .c-control-label,
.form-group-label,
.control-label {
    font-weight: $semibold;
    font-size: .875em;
}

label.c-control-label {
    font-weight: normal;
    @include respond-to(mobile){
        font-size: 16px;
    }
    
}

:root {
    @include poly-fluid-sizing("font-size", $map-globalFontSize);
}
  /** 
     * La siguiente funcion lo que hace es interpolar los valores del mapa que 
     * se le pasa para breakpoints que genera con las claves y utilizarlos en la
     * propiedad que se le pasa.
     *
     * El ejemplo sigiente define el "font-size" para el mapa "globalFontSize"
     *
     *   $map-globalFontSize: (
     *       320px: 13px,
     *       375px: 16px,
     *       1600px: 24px
     *   );
     *
     * Entonces en 320 seria 13px hasta 375 interpolaria hasta los 16px,
     * y asi sucesivamente
    */

    
/* 
   Here goes the CSS asosiated with the external layout and the pallete, this 
   includes login, forget pass, etc.
   ========================================================================== */
@import "external-scheme";

/* 
   Here goes the CSS asosiated with the default layout and the pallete, this is 
   used in all authenticated routes, and share palette with the enrollment
   ========================================================================== */
@import "default-scheme";
