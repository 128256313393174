/**
 * New list to replace:
 * navigational lists and similar
 */

// .list-title {
// }
// .list-wrapper {
// }
// .list-group {
// }
.list-group-item {
    padding: 1rem;
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid #999999; // lighten($brand-primary, 60%);
    button {
        padding: 0;
        border: none;
        color: $brand-info;
        background-color: transparent;
    }
}

/* ul */

.list {
    padding: 0;
    margin: 0.75em 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
}

/* a, div */

.list-item-inner {
    display: block;
    padding: 0.25em 0;
    &.flex-container {
        display: flex;
    }
    >.c-control,
    >.form-group-wrapper-inline>.form-group,
    >.form-group {
        margin-top: 0;
        margin-bottom: 0;
    }
}

/* indentation */

.list .list {
    margin: 0.25em 0;
    margin-left: 1rem;
}

.list--separated .list:not(.list--separated) {
    margin-bottom: 1em;
}

.list--separated>.list-item {
    border-bottom: 1px solid lighten($brand-primary, 60%);
}

.list--separated .list .list-item-inner {
    padding: 0.25em 1rem;
}

.form-group-wrapper .list--separated>.list-item>.list-item-inner {
    min-height: 4rem;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}

.list-item-inner.flex-container {
    flex-flow: wrap;
}

.list--separated {
    .form-control,
    .react-tagsinput,
    .Select .Select-control {
        padding: 6px 0.5em;
    }
    .Select-placeholder,
    .Select--single>.Select-control .Select-value {
        padding-left: 0.5rem;
        line-height: initial;
    }
}

.list--bulleted {
    padding-left: 2.25rem;
    list-style: initial;
}

.list .list--bulleted {
    padding-left: 1.25rem;
}

.list--bulleted .list-item .list-item-inner {
    padding-left: 0.25rem;
}

.list em {
    font-size: 0.75rem;
    font-style: normal;
    text-transform: uppercase;
}

// .list-item-inner + .list {
//     display: none;
// }
/**
 * data-list,
 * navigational-list,
 * product-list,
 * list in messages,
 * .selection-list,
 */

/* Deprecated - in external wrapper only */

/* Deprecated => .list */

/* ul/ol */

.data-list {
    padding: 0;
    list-style: none;
    @include when-inside(".section-content-heading") {
        .data-list {
            text-transform: uppercase;
            .data-label {
                font-weight: bold;
            }
        }
    }
    /* li */
    .data-wrapper {
        margin: 0.5rem 0;
    }
}

.list--permissions,
.navigational-list {
    .navigational-list-item {
        .is-active {
            color: $brand-primary !important;
            background-color: lighten($brand-primary, 60%);
            .c-control-label,
            &.collapsable-button {
                color: $brand-primary !important;
            }
        }
    }
}

.navigational-list-item {
    &.list-item--noChilds {
        >.c-control:only-child {
            .c-control-label {
                width: 100%;
                padding-top: 1rem;
                padding-right: 1rem;
                padding-bottom: 1rem;
                margin-left: 1rem;
                color: $brand-info;
                font-weight: normal;
            }
        }
    }
    .c-control {
        margin-bottom: 0;
    }
    .collapse-open+.form-group {
        display: block;
    }
    .collapsable-button {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 1rem 0.5rem;
        border: none;
        color: $brand-info;
        font-size: 1em;
        background-color: transparent;
        >span {
            overflow: hidden;
            flex-grow: 1;
            max-width: 100%;
            text-align: left;
            text-overflow: ellipsis;
        }
        .svg-wrapper {
            flex-grow: 0;
            width: initial;
            color: $brand-primary;
        }
        .c-control-mark .svg-wrapper {
            transform: initial;
        }
    }
    .collapse-open {
        color: $brand-primary;
        font-weight: bold;
    }
    >.form-group {
        margin: 0.5rem 0;
        .c-control {
            .c-control-label {
                position: relative;
                width: 100%;
                padding: 0.25rem 0.5rem;
                &::before {
                    border: 1px solid #cccccc;
                }
                .c-control-text {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.tight-containers{
    .navigational-list-item a{
        color: $brand-color-black;
    }
}

.navigational-list-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    .btn-only-icon {
        width: auto;
        @include respond-to(mobile) {
            span {
                @include visually-hidden;
            }
        }
        .svg-wrapper {
            margin: 0;
            color: $brand-info;
        }
    }
}

/**
 * TODO Resolver estas listas con el componente list
 */

.normal-list {
    padding: 0;
    text-align: center;
    list-style: none;
    li {
        padding-bottom: 1em;
        font-family: $font-family-base;
        font-size: 0.875rem;
        &::before {
            display: inline;
            content: "- ";
        }
    }
}

.list-item-hint {
    color: #aaaaaa;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
}

.list-wrapper {
    width: 100%;
}

.list {
    >.list-item {
        padding: 0 1rem;
        padding: 0;
    }
}

// fix permission list while its in process
.transfer-data {
    .list--permissions>.list-item {
        padding: 0.75em 1rem;
    }
}

.list--permissions {
    width: 100%;
    width: auto;
    margin: 0 -0.5rem;
    font-family: $font-family-base;
    &>.list-item {
        padding: 0.75rem 0.5rem;
    }
    .list {
        margin: 0.25em 0;
        .list {
            border-bottom: none;
        }
        em {
            color: #999999;
            font-size: 0.875rem;
        }
    }
    >.list-item {
        border-bottom: 1px solid $gray-lighter;
        font-family: $headings-font-family;
        >.list-item-inner {
            color: $brand-info;
        }
        &:last-child {
            border-bottom: none;
        }
    }
    em {
        color: $brand-info;
        font-size: 1rem;
        font-style: normal;
        text-transform: initial;
    }
}

.list-item-inner {
    font-family: $headings-font-family;
    display: flex;
    align-items: center;
    min-height: 3rem;
}

.template-list {
    position: absolute;
    top: 0;
    right: 0;
}

.list-group-item--actionable {
    display: flex;
    justify-content: space-between;
    padding: 0;
    .btn {
        padding: 1rem;
        &.btn-only-icon {
            border: none;
            .svg-wrapper {
                margin: 0;
            }
            span {
                @include visually-hidden;
            }
        }
    }
    .btn:not(.btn-only-icon) {
        flex-grow: 1;
        justify-content: flex-start;
        font-size: 1rem;
        font-weight: normal;
        text-align: left;
        text-transform: none;
        &:hover,
        &:focus {
            color: darken($brand-info, 5%);
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.list-item--deleteable {
    position: relative;
    .list-item-inner {
        padding: 0;
        border-bottom: 1px solid $gray-lighter;
    }
    .btn-quiet {
        flex-direction: column;
        align-items: start;
        border-bottom: none !important;
        small {
            text-align: left !important;
            color: $ui-gray-base;
            padding: 0;
            margin-left: 0;
        }
        span {
            text-align: left !important;
            font-weight: bold;
            margin-right: 3rem !important;
            border-radius: .3rem;
            .inline-message{
                display: none;
            }
        }
        &:hover,
        &:active,
        &:focus {
                background-color: lighten($brand-color-accent, 40%) !important;
                 color:$brand-color-accent!important;
        }
    }
    .close--absolute {
        line-height: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        color: $brand-color-element;

        border-radius: 100%;
        position: absolute;
        right: 0.5em;
        opacity: 1;
        border: 1px solid transparent;
        .icon-trash {
            width: 1rem;
            height: 1rem;
            line-height: 2em;
            color:$brand-color-accent;
        }
        &:hover{
            border: 1px solid $brand-color-accent;
        }
    }
}

.list-item-inner {
    border-bottom: 1px solid $gray-lighter;

    > .btn {
        text-transform: initial;
        width: 100%;
        border-radius: 0;
        justify-content: space-between;
        font-weight: normal;
        background: none;
    }
}
