.data-wrapper {
    margin: 0;
    display: block;

    &.data-wrapper-inline {
        .data-label {
            display: inline;
        }
    }

    .data-label {
        display: block;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
.data-name {
    color: $brand-primary;
}
.data-wrapper-flex {
    display: flex;
    align-items: center;

    > .svg-wrapper {
        margin: 0 0.25rem;
        width: initial;
    }

    @include when-inside(".text-center") {
        justify-content: center;
    }
}

.data-title,
.data-aux,
.data-date {
    font-size: 0.875rem;
    font-family: $font-family-base;
}

.data-wrapper-inline .data-date-mleft {
    margin-left: 3em;
}

h3.data-title {
    font-weight: normal;
    color: $brand-primary;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    margin-top: 1.5rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
}

.data-aux {
    text-transform: uppercase;

    .svg-wrapper {
        display: inline-block;

        .svg-icon {
            width: 1rem;
            height: 1rem;
        }
    }
}

.data-numeric {
    text-align: right;
}

.data-date {
    span {
        width: 100%;
        float: left;
        font-size: 0.7rem;

        @media (max-width: 767px) {
            float: none;
        }
    }
}

.data-label {
    font-family: $font-family-base;
    font-weight: bold;

    // se cambia para alinearlo con otros textos
    // font-size: 0.875rem;
}

.data-name {
    font-size: 1.20em;
    color: $data-name--color;
    display: block;

    h4 {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $font-family-base;

        /*
         ! siempre se va a querer esto? parece una definicion demaciado 
         ! estricta
         */
    }
}

.data-name-grey {
    font-size: 1.25em;
    color: grey;
    display: block;
    font-weight: bold;

    h4 {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $font-family-base;

        /*
         ! siempre se va a querer esto? parece una definicion demaciado 
         ! estricta
         */
    }
}

.data-strong {
    font-size: 1.5em;
}

.data-amount-wrapper {
    flex: 1;
}

.data-amount {
    white-space: nowrap;
    font-size: 1.5em;
}

.data-amount-currency {
    font-size: 0.7em;
    opacity: 0.7;
}

.transfer-data {
    align-items: baseline;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.transfer-data-flow {
    font-size: 1rem;
    align-items: center;
}

@include respond-to(mobile) {
    .transfer-block {
        align-items: center;
    }

    .transfer-data {
        justify-content: center;
    }

    .data-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
        flex-direction: column;
        margin: 0.5rem 0;
    }

    .transfer-data-highlight {
        flex-direction: column;
        align-items: center;
    }

    .transfer-data {
        .data-wrapper {
            flex-direction: column;
        }
    }

    .transfer-data-flow {
        flex-wrap: nowrap;
    }

    .data-amount {
        font-size: 1.25rem;
    }
}

.Select {
    .control-label,
    .data-amount,
    .data-label {
        font-family: sans-serif;
        font-weight: normal;
        font-size: 1em;

        &:not(:last-child) {
            margin-right: 0.25rem;
            margin-bottom: 0;
        }
    }
}

.data-wrapper {
    margin: 0.5rem 0;

    > * {
        margin-left: 0;
        margin-right: 0;
    }

    .data-label {
        display: block;
    }
}

.main-container, .container--layout {
    .data-amount-credit {
        color: $data-amount-credit--color;
    }

    .data-amount-debit {
        color: $data-amount-debit--color;

        .data-amount-currency {
            &::after {
                font-size: 1.25em;
                content: " -";
                display: inline;
            }
        }
    }
}

.transfer-data-flow {
    border-bottom: 1px solid $separation-line--color;
    border-top: 1px solid $separation-line--color;
    padding: 10px 0;
    justify-content: space-between;

    .svg-wrapper {
        margin: 0.5rem;
    }

    .svg-wrapper,
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
}

.transfer-data .data-label {
    margin-right: 0.375em;
}

.transfer-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include respond-to(mobile) {
        .transfer-data {
            display: flex;
            flex-direction: row;

            &.transfer-data-highlight {
                flex-direction: column;
            }
        }

        .data-amount {
            font-size: 1.5em;
        }
    }

    .transfer-data-flow {
        width: 100%;
        display: flex;
    }

    .svg-wrapper {
        width: initial;
    }
}

.transfer-data-highlight {
    .data-amount {
        display: block;
        font-size: 2em;
    }
}

.data-wrapper.data-wrapper-inline {
    display: block;

    > span {
        display: inline-block;

        .data-label {
            margin-right: 0.25em;
        }
    }
}

@include respond-to(mobile) {
    .data-aux {
        .svg-wrapper {
            position: relative;
            top: 0.2rem;
            left: 0;

            .svg-icon {
                min-width: auto;
                margin-right: 0.25rem;
            }
        }
    }

    .transfer-data {
        .data-label {
            padding: 0 0.375em;
        }
    }
}

.content-data-strong {
    font-weight: normal;
    line-height: 1.4;
}

.data-tag {
    color: white;

    > span {
        background-color: $data-tag--background-color;
        padding: 0.25em 0.5em;
        border-radius: 5px;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 0.75rem;
        text-transform: uppercase;
        display: inline-block;
    }

    @include respond-to(mobile) {
        text-align: right;
    }

    @include when-inside(".is-expired") {
        > span {
            background-color: $data-tag--background-color--is-expired;
        }
    }
}

.table-data > .data-tag {
    text-align: center;
}

.data-tag-blocked {
    > span {
        background-color: #707070;
    }
}

.data-product-image {
    display: flex;
    align-items: center;

    .svg-wrapper {
        margin-right: 0.5rem;

        .svg-logo {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}


.data-tag {
    text-align: inherit;
}


.data-label {
    display: inline-block;

    margin-bottom: 0.25rem;
}


.transfer-block {
    .data-wrapper:not(.transfer-data) {
        width: 100%;
    }
}
