//.image applies to the div that contains an image
.image {
    flex-basis: 10rem;
    min-width: 10rem;
    margin: auto;
    position: relative;
    display: inline-flex;
    z-index: 1;

    .svg-wrapper {
        position: absolute;
        bottom: 0;
        top: auto;
        right: 0;
    }

    figure {
        height: 10rem;
        margin: 0;
        width: 100%;
        overflow: hidden;
        position: relative; // border: 0.3rem solid white;
        border-radius: 50%;

        img {
            z-index: -1;
            border-style: none;
            display: block;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            background-color: darken($brand-primary, 5%);
        }
    }
}
