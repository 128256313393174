.container-fluid {
    position: relative;
}

.scrollable {
    > .container {
        overflow: auto;
    }

    .table {
        height: 0;
    }
}

/*
   Grilla para imagen de seguridad
   ========================================================================== */

.image-grid {
    .col {
        margin-bottom: 1.5rem;
    }
}

.is-current {
    button {
        border: 1px solid $brand-primary;

        box-shadow: 0 0 7px rgba(black, 0.3);
    }
}

.is-current::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    display: block;

    width: 100%;
    height: 100%;

    content: "";

    background-image: url("data: image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOC4zODcgMzAuMjA2Ij4gIDxkZWZzPiAgICA8c3R5bGU+ICAgICAgLmNscy0xIHsgICAgICAgIGZpbGw6IG5vbmU7ICAgICAgICBzdHJva2U6ICNmZmY7ICAgICAgICBzdHJva2UtbGluZWNhcDogcm91bmQ7ICAgICAgICBzdHJva2UtbGluZWpvaW46IHJvdW5kOyAgICAgICAgc3Ryb2tlLW1pdGVybGltaXQ6IDEwOyAgICAgICAgc3Ryb2tlLXdpZHRoOiA4cHg7ICAgICAgfSAgICA8L3N0eWxlPiAgPC9kZWZzPiAgPHBhdGggaWQ9IlBhdGhfNzI0IiBkYXRhLW5hbWU9IlBhdGggNzI0IiBjbGFzcz0iY2xzLTEiIGQ9Ik0uNSwxMi4xODcsOC42ODEsMjIuNzA2LDMwLjg4Ny41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzLjUgMy41KSIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;

    pointer-events: none;
}

/*
   Detalle de productos
   ========================================================================== */

/* Barra de acciones en detalle de un producto */
.product-detail-commands {
    margin-top: 1rem;
    margin-right: -1rem;
    margin-bottom: 1rem;
    margin-left: -1rem;
}



$nav-tabs--foreground-color: $brand-primary;
$nav-tabs--background-color: lighten($brand-primary, 60%);
$nav-tabs--border-color: #cfcae7;
$nav-tabs--background-color--active: white;

.nav-tabs {
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    border-bottom: 1px solid $nav-tabs--border-color;

    li {
        margin-right: 0.5rem;
        margin-bottom: -1px;
        margin-left: 0.5rem;

        .tab {
            border: 1px solid transparent;
            border-bottom-color: $nav-tabs--border-color;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            color: $nav-tabs--foreground-color;
            font-size: 0.75rem;

            background-color: $nav-tabs--background-color;

            &:hover {
                color: white;

                background-color: $brand-info;
            }

            &:focus {
                color: $nav-tabs--foreground-color;

                background-color: $nav-tabs--background-color--active;
            }

            &[disabled] {
                &:hover,
                &:focus {
                    color: $nav-tabs--foreground-color;

                    background-color: $nav-tabs--background-color;
                    box-shadow: none;
                }
            }
        }

        .is-active {
            border-color: $nav-tabs--border-color;
            border-bottom-color: transparent;

            color: $nav-tabs--foreground-color;

            background-color: $nav-tabs--background-color--active;
        }
    }
}

.nav-pills {
    margin-top: 0.5rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem;

    li {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        margin-left: 0.5rem;

        .btn {
            padding: 0.5rem !important;

            font-weight: bold;

            &:hover {
                color: white;

                background-color: $brand-info;
            }

            &:active {
                color: white;

                background-color: $brand-primary;
            }

            &:focus,
            &:active,
            &:focus:active {
                text-decoration: none;

                box-shadow: 0 0 2px rgba(black, 0.25);
            }
        }

        > .navigational-list-item:only-child {
            border-bottom: none;
        }
    }

    > .btn {
        padding: 0.5rem;
        margin-right: 0.75rem;
        margin-bottom: 0.75rem;

        border: none;
    }
}

/* Barra de acciones sobre tablas de movimientos */
.movement-commands {
    @include respond-to(desktop) {
        > li + li {
            margin-left: $s;
        }
    }

    li .btn {
        padding: 0.5rem;
    }
}

@include respond-to(desktop) {
    .filters {
        margin-top: 1rem;
        margin-bottom: 1rem;

        > .col:last-child {
            // padding: 0;
        }

        form {
            display: flex;
            align-items: flex-end;

            > .container--layout {
                width: 100%;
                margin: 0;
            }

            .container {
                // display: flex;
                // flex-wrap: wrap;
            }
        }

        .form-group {
            margin: 0;

            .input-group {
                margin-bottom: 0;
            }
        }
    }
}




/*
   Administracion
   ========================================================================== */

/**
    * @TODO
    * renombrar y poner en archivo aparte
    */

.col-header {
    display: flex;

    h4,
    h3 {
        margin: 0.5rem 0;

        color: $brand-primary;
    }

    .btn {
        margin-left: 1rem;
    }
}

// Get container size right on @normal
.above-the-fold {
    .container--scrollable-table {
        flex-grow: 1;

        height: 100%;
        min-height: 100%;
        padding: 0.5rem 1rem 2rem;
        margin: 0;

        .rc-table {
            height: 100%;

            table {
                border-spacing: 0;
            }

            .rc-table-content {
                height: 100%;

                .rc-table-scroll {
                    overflow: hidden;

                    display: flex;
                    flex-direction: column;

                    height: 100%;

                    .rc-table-header {
                        overflow-x: hidden;

                        display: flex;
                        align-items: center;

                        min-height: 3rem;
                        padding-bottom: 0;
                        margin-top: auto;
                        margin-bottom: auto !important;

                        border-bottom: 2px solid $gray-lighter;
                    }

                    .rc-table-body {
                        flex-grow: 1;

                        max-height: initial !important;
                    }

                    .rc-table-footer {
                        padding: 0;

                        border: 0;
                    }
                }
            }
        }

        > .container {
            display: flex;
            flex-direction: column;

            > .row {
                flex-grow: 1;

                > .col {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .container--viewport-fit {
        flex-grow: 1;

        > div > .row {
            height: 100%;

            > .col {
                display: flex;
                flex-direction: column;
            }
        }
    }
}


.slick-slider {
    margin-bottom: 0.5rem;

    text-align: center;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: $brand-info;
}

.preloader {
    background-color: transparent;
}


.account-sobregiro-label {
    position: absolute;
    top: 6em;
    right: 3.2em;

    font-weight: lighter;
}









.panel {
    border: none;
    border-radius: 10px;

    background-color: lighten($brand-primary, 60%);
    box-shadow: none;

    .panel-heading,
    .panel-body {
        border: none;
    }

    .panel-heading {
        background-color: transparent;
    }

    .panel-title {
        margin-top: 1rem;

        color: $brand-primary;
    }
}

.separator {
    position: relative;

    margin: 0 0 1rem 0;

    text-align: center;

    span {
        position: relative;

        padding: 0.5em;
        margin: 0 1rem;

        background-color: lighten($brand-primary, 60%);
    }

    hr {
        position: absolute;
        top: 0;
        bottom: 0;

        width: 100%;
    }
}

@include respond-to(desktop) {
    .select-open-top .Select-menu-outer {
        top: auto;
        bottom: 100%;
    }
}

.login-background {
    .toolbar-btn {
        color: white;
    }
}

.admin-label {
    padding: 0.25rem;
    margin-left: 1rem !important;

    border: 1px solid #a19cb8;
    border-radius: 5px;

    color: #a19cb8;
    vertical-align: text-bottom;
}

.has-error .filepond--root [data-filepond-item-state="processing-complete"] .filepond--item-panel {
    background-color: #64605e;
}

.failed-payment-line {
    color: $brand-danger;
}

.succeeded-payment-line {
    color: $ui-color-success;
}

.extended-content {
    overflow: auto;

    flex-grow: 1;

    transform: translate3d(0px, 0px, 0px);
    transition: transform 0.3s ease 0s;
}

.changed-direction {
    flex-direction: column;

    margin-top: 0;
    margin-bottom: 0;
}

.table-title-wrapper {
    display: flex;

    padding: 1rem 0;

    .nav-pills {
        margin-left: auto;
    }
}

.filters .container--layout > .container {
    padding: 0;
}

.slick-slider {
    .data-wrapper {
        display: inline-block;
    }
}

.btn-only-icon.payment-icon {
    border-color: transparent;

    background-color: $brand-color-accent;

    &:active,
    &:focus {
        border-color: transparent;

        background-color: $brand-color-accent;
    }

    .svg-wrapper {
        color: white;
    }
}

.environment-tag {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0.25rem 0;

    .svg-wrapper {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
}