$alert-error--background: rgba($brand-danger, 0.9);
$alert-warning--background: rgba($brand-warning, 0.9);
$font-family-alert: $font-family-base;

.alert {
    padding: .75rem;
    margin-bottom: 1rem;
}

.container--alert {
    margin-bottom: 0 !important;

    .alert {
        margin-bottom: 0;
    }

    .alert {
        font-family: $font-family-alert;
        border-radius: 5px;
    }

    .alert-warning {
        background-color: $alert-warning--background;
        border-color: $alert-warning--border-color;
        color: $alert-warning--color;
    }

    .alert-danger {
        border: none;
        color: $alert-error--color;
        background-color: $alert-error--background;
    }
}
