$brand-primary: $brand-color-primary;
// $ui-color-success: $brand-color-secondary;
$brand-info: $brand-color-accent;

$brand-danger: $brand-color-red;
$brand-warning: $brand-color-yellow;
$ui-color-success: $brand-color-green;


$input-color-placeholder: #000;

$gray: $ui-gray-base;
$gray-lighter: $ui-gray-ultra-light;

// $font-family-base: sans-serif;
$headings-font-family: $brand-font-family;

$navbar-default-color: $brand-color-black;
$navbar-active-color: white;