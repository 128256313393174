.view-wrapper {
    min-height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 0 2px;
    @media (max-width: $screen-sm-max) {
        padding: 0;
    }
    @media (min-width: $screen-sm-min) {
        padding-right: 2%;
    }
    @media (min-width: $screen-md-max) {
        padding-right: 10%;
    }
    @media (min-width: $screen-lg-min) {
        padding-right: 18%;
    }
    @include respond-to(mobile) {
        padding: 12px;
        &.success-page {
            background-color: $brand-color-secondary;

        }
        &.card-admin-success {
            padding: 0;
        }
    }
}

.agreements-detail-view {
    .app-content {
        margin-left: 230px;
        max-width: 80%;
        padding: 0 1%;
    }
}

.view-header {
    z-index: 99;
}

.view-page {
    z-index: 98;
    height: max-content;
    width: 100%;
    // overflow: auto;
    flex-grow: 1;
    -webkit-overflow-scrolling: touch;
    justify-content: space-between;
    display: flex;
    // overflow-x: hidden;
    // overflow: hidden;
    z-index: 0;

    @include respond-to(mobile) {
        overflow: initial;
        flex-direction: column;
    }
}

.view-content {
    min-height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    // overflow: auto; // removed as it created two scrollbars

    @include respond-to(mobile) {
        overflow: visible;
    }
}

.cc-admin-view {

    .view-page {
        overflow: visible;
        .view-content {
            overflow: inherit;
        }
    }

    .above-the-fold {
        .form-content{
            padding-left: 0;
            padding-right: 0;
        }
    }


}


.view-footer {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

/* Aplicacion desktop */

// .app-page {

// }

@include respond-to(desktop) {
    .main-container {
        display: block;
    }

    .main-container {
        height: 100%;

        display: flex;
    }

    .app-page {
        .view-wrapper {
            // display: block;
            min-height: initial;
        }

        .view-title {
            margin-bottom: 0;
        }

        .view-title {
            text-align: initial;
        }

        .view-content {
            position: relative;
        }
    }

    .view-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;

        .container--layout {
            flex-shrink: 0;
        }
    }

    .main-container-credit-card {
        margin: 0 auto;
        padding: 0 5%;
        overflow-x: hidden;
        overflow: visible;
    }
}

.container--layout.flex-grow.scrollable {
    padding-bottom: 4rem;
}

.view-title {
    flex-grow: 1;
    text-align: center;

    h2 {
        margin: 0;
        font-size: 1.25rem;
        display: inline-block;

        @include respond-to(desktop) {
            font-size: 1.5rem;
        }

        font-weight: $title-font-weight;
    }

    >*:last-child {
        margin: 0;
    }
}

.view-title {
    h2 {
        margin: 0;
    }
}

.view-movements {
    max-height: 455px;
    width: 100%;

    a.table-row{
        border: none;
        // find a way to add the min-height without breaking the scroll

        .table-data.table-data-movements{
            padding: 0.25em 0.4em;
            flex-grow: unset;

            &:first-of-type{
                width: 1.2rem;
                min-width: 35px;

                .svg-wrapper{
                    justify-content: start;
                    margin: 0 auto;
                }
            }

            &:nth-of-type(3n){
                flex-grow: 1;
                margin-left: 0rem;
                
                .data-text{
                    text-align: left;
                }
            }
        }
    }
}

.view-movements-with-filter {
    max-height: 270px;

    a.table-row{
        border: none;

        .table-data.table-data-movements{
            padding: 0.25em 0.4em;
            flex-grow: unset;

            &:first-of-type{
                width: 1.2rem;
                min-width: 35px;

                .svg-wrapper{
                    justify-content: start;
                    margin: 0 auto;
                }
            }

            &:nth-of-type(3n){
                flex-grow: 1;
                margin-left: 0rem;
                
                .data-text{
                    text-align: left;
                }
            }
        }
    }
}

.view-movements-credit-card-details {
    max-height: 390px;
    width: 100%;
    a.table-row{
        border: none;

        .table-data.table-data-movements{
            padding: 0.25em 0.4em;
            flex-grow: unset;

            &:first-of-type{
                width: 1.2rem;
                min-width: 35px;

                .svg-wrapper{
                    justify-content: start;
                    margin: 0 auto;
                }
            }

            &:nth-of-type(3n){
                flex-grow: 1;
                margin-left: 0rem;
                
                .data-text{
                    text-align: left;
                }
            }
        }
    }
}

.view-movements-with-filter-credit-card-details {
    max-height: 200px;

    a.table-row{
        border: none;

        .table-data.table-data-movements{
            padding: 0.25em 0.4em;
            flex-grow: unset;

            &:first-of-type{
                width: 1.2rem;
                min-width: 35px;

                .svg-wrapper{
                    justify-content: start;
                    margin: 0 auto;
                }
            }

            &:nth-of-type(3n){
                flex-grow: 1;
                margin-left: 0rem;
                
                .data-text{
                    text-align: left;
                }
            }
        }
    }
}

.data-text {
    &.smaller-description {
        padding-inline-start: 5%;
        font-size: smaller !important;
        color: darkgrey;
}}

@include respond-to(mobile) {
    .view-title {
        flex-wrap: wrap;
    }
}

@include respond-to(desktop) {
    .theme-auth {
        .view-page {
            position: relative;

            &::after {
                z-index: 10;
                content: "";
                // background: linear-gradient($brand-primary, transparent);
                width: 100%;
                height: $s;
                position: absolute;
                bottom: 0;
                top: 0;
            }
        }

        .theme-auth {
            .view-page {
                &::after {
                    // background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
                }
            }
        }
    }
}