.show-loader {
    min-height: 400px;
}

.theme-auth .preloader {
    background-color: rgba(255, 255, 255, 0.75);
}

.preloader {
    top: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 5;
    opacity: 1;
    animation: overlayAppear 0.5s ease;
    animation-fill-mode: forwards;
    pointer-events: all;
}

.scrollable {
    position: relative;

    .loader {
        top: initial;
        bottom: 15vh;
    }
}

.loader {
    margin: auto;
    width: 3rem;
    height: 3rem;
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: spin 2s infinite linear;
}

.loader > div:nth-child(1),
.loader > div:nth-child(2) {
    content: "";
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: $brand-info;
    border-radius: 100%;

    animation: bounce 2s infinite ease-in-out;
}

.loader > div:nth-child(2) {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
}

.spinner .bounce1 {
    animation: bouncedelay 1.4s infinite ease-in-out both;
    content: "";
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #333;

    animation: bouncedelay 1.4s infinite ease-in-out both;
    border-radius: 100%;
    display: inline-block;
    animation-delay: -0.16s;
}
