.app-page .sendMoney-mainContainer{
    display: flex!important;
    .sendMoney-container1, .sendMoney-container2{
        padding: 0;
    }
   
}
.add-money-wrapper-class{
    padding: 0;
}

.field-add-recipient{
    .input-group{
        border: 2px solid #F7971C!important;
    }
}
.sendMoney-container1{
    width: 60%!important;

}
.sendMoney-container2{
    width: 40%!important;
    .col-container{
        background-color: #b1d8da;
        box-shadow: 0px 0px 10px 0px #969595;
        padding: 2em 1em 3em 1em;
        .transactions{
            background-color: #ffffff;
            margin-bottom: 0.7em;
            box-shadow: 0px 0px 10px 0px #5f5f5f;
            .transactions-title {
                display: flex;
                align-items: flex-start;
                justify-content: start;
                padding: 0.75em;
                padding-bottom: 0.3em;
                font-family: $brand-font-family;
                font-size: 0.9rem;
                font-weight: normal;
                text-transform: uppercase;
                color: $brand-color-base;
                background-color: transparent ;
                margin: 2px;
            }
            .transactions-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.75em;
                padding-top: 0.3em;
            }
            .transaction-info-name{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            
            .transaction-info-date{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            .transaction-info-money{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            .indicatorContainer {
                color: $brand-color-base;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding: 8px;
                justify-content: flex-end;
                -webkit-transition: color 150ms;
                transition: color 150ms;
                box-sizing: border-box;
            }
            

        }
    }

}
.sendMoney-container2-mobile{
    .col-container{
        background-color: #AFDCD7;
        box-shadow: 0px 0px 10px 0px #969595;
        padding: 2em 1em 3em 1em;
        .transactions{
            background-color: #ffffff;
            height: 50%;
            margin-bottom: 0.7em;
            box-shadow: 0px 0px 10px 0px #5f5f5f;
            .transactions-title {
                display: flex;
                align-items: flex-start;
                justify-content: start;
                padding: 0.75em;
                padding-bottom: 0.3em;
                font-family: $brand-font-family;
                font-size: 0.9rem;
                font-weight: normal;
                text-transform: uppercase;
                color: $brand-color-base;
                background-color: transparent ;
                margin: 2px;
            }
            .transactions-info{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.75em;
                padding-top: 0.3em;
            }
            .transaction-info-name{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            
            .transaction-info-date{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            .transaction-info-money{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
            .indicatorContainer {
                color: $brand-color-base;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                padding: 8px;
                justify-content: flex-end;
                -webkit-transition: color 150ms;
                transition: color 150ms;
                box-sizing: border-box;
            }

        }
    }

}


.sendMoney-schedule{
    width: 60%!important;
    height: 40px!important;
}

.addMoney-colbtn{
    display: flex;
    justify-content: space-between;

}


.addMoney-btn{
   width: 45%!important;
}

.sendMoney-summary-v2 {
    @include fully-centered-col;
    width: 100%;
    margin-bottom: 30px;

    .heading {
        @include row-v_center-h_space_between;
        width: 100%;
        max-width: 500px;
        margin-bottom: 0.53em;
    }

    .heading-with-logo {
        @include fully-centered-row;

        .separator {
            padding: 0 3px;
            margin: 0;

            &:before {
                content: "-";
                display: inline-flex;
            }
        }
    }

    .sendMoney-summary-content-wrapper {
        margin-top: 2em;
        width: 100%;
        max-width: 500px;
        padding: 27px 12px;
        background-color: #ffffff;
        // margin: 20px 0;
        margin-bottom: 20px;

        .sendMoney-summary-content {
            position: relative;
            background-color: #ffffff;
            border-radius: 8px;
            -webkit-box-shadow: 0 4px 17px -2px #0000071f;
            box-shadow: 0 4px 17px -2px #0000071f;

            .sendMoney-summary-content-body {
                width: 100%;
            }

            .sendMoney-summary-content-footer {
                @include row-v_center-h_end;

                .btn-edit {
                    width: auto;
                    height: auto;
                    color: #F7971C;
                    font-size: 0.9em;
                    font-weight: bold;
                    background: none;
                    border: none;
                }
            }
        }
    }
}

.sendMoney-copyToClipboard{
    border-style: dotted;
    margin-left: auto;
    margin-right: auto;
    width: 11rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem; 

    background-color: $brand-color-primary;
    @include respond-to(desktop) {
        color:white;
    }
    .code {
        font-weight: 700;
        font-size: larger;
    }
    .svg-wrapper{
        position: absolute;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        padding-top: 1rem;
        width: 1rem;
        height: 1rem;
        .svg-icon {
            width: 1rem;
            height: 1rem;         
        }
    }
}
