.modal-dialog,
.theme-auth {
    color: $gray-darker; // $gray-darker;

    .data {
        border-bottom: 1px solid $brand-color-element;
    }

    .app-footer {
        // background-color: white;
    }

    .check-list {
        .c-control--radio {
            .c-control-label::before {
                border: none;
            }
        }

        .c-control-label {
            color: $checks-list-type-color;
        }

        .c-control-input:checked~.c-control-label {
            color: $brand-color-black;
        }

        .c-control-label {
            color: $brand-color-black;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
        }
    }

    .c-control--radio,
    .c-control--checkbox,
    .c-control--switch {
        .c-control-label::before {
            box-shadow: none;
            // border: 1px solid $brand-primary;
        }
    }

    .app-footer {
        color: $ui-color-field; // $gray-darker;
        background-color: #ffffff;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4 {
        color: $headings-color;
    }

    h1,
    h2,
    h3,
    h4 {
        // color: $brand-primary;
    }

    &.app .main-container,
    &.view-wrapper .main-container {
        background-color: transparent;
    }

    .text-lead,
    .page-title,
    .view-title {
        color: $brand-primary; // $brand-primary;
    }

    .input-group {
        border: $input-border-width solid $ui-gray-light;
    }

    .form-control {
        color: $gray-darker; // $gray-darker;
    }

    .progress {
        background-color: $brand-color-element;

        .progress-bar {
            background-color: $brand-info; // $brand-info;
        }
    }

    .container-fluid {
        max-width: initial !important;
    }

    .form-group.has-focus {
        .input-group {
            border-color: $brand-primary;
        }
    }

    .btn.btn-only-icon {
        color: $brand-info;

        &:hover,
        &:focus,
        &:active,
        &:active:hover {
            color: $brand-info;
        }
    }
}

.descriptionStatus{
    font-family: $secondary-font-family;
}

.employee-employer-info{
    font-family: $secondary-font-family;
    font-size: 0.875rem;
    font-weight: bold;
    font-style: italic;
}

.theme-highlight {
    background-color: $app--background-color;
    color: $app--color;

    &.view-header {
        background-color: $app--background-color;
        color: $app--color;

        .navbar {
            background-color: $app--background-color;
        }
    }

    .toolbar-item--fixed {
        color: $brand-info;
    }

    .view-title {
        color: $app--color;
    }

    .section-content-heading {
        border-color: transparent;
    }
}

.app-footer a {
    color: $brand-info;
}

@include respond-to(mobile) {
    .theme-product-detail {
        &.view-header .navbar {
            color: $brand-primary;
            background-color: $ui-white;
        }

        .text-lead,
        .page-title,
        .view-title {
            color: white;
        }

        &.view-morphing {
            color: $brand-primary;
            background-color: $ui-white;
        }

        .product-item .data-aux {
            background-color: lighten($brand-primary, 30%);
        }

        .toolbar-item.toolbar-item--fixed {
            color: $brand-primary;
        }

        .toolbar-product-name{
            .btn.view-back{
                color: $brand-primary;
            }
        }

        .section-content-heading {
            border-color: $brand-primary;
            margin-bottom: 0;
        }

        .slick-dots li.slick-active button:before {
            color: lighten($brand-primary, 30%);
        }
    }
}

@include respond-to(desktop) {
    .theme-auth .view-header .navbar>.container-fluid {
        align-items: baseline;
    }
}

.navbar-brand {
    display: flex;
    height: auto;
}

.patriot-act{
    font-style: italic;
    
    a{
        font-style: italic;
        color: $brand-color-terciary;
    }
}