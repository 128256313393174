.billpay-scheduled-payments {
    width: 100%;

    .new-modal-wrapper {
        width: 100%;
        
        .new-modal {
            @include fully-centered-col;
            width: 470px;
            padding: 0 1em;
            background-color: #ffffff;

            .new-modal-header {
                border-bottom: none;
            }

            .description {
                text-align: center;
                margin-top: 1.5em;

                strong {
                    color: $brand-color-accent;
                }
            }

            article {
                padding: 0;

                .btns-wrapper {
                    @include fully-centered-row;
                    padding: 10px 0;

                    &.btn-block + .btn-block { 
                        margin: 0;
                    }
                    
                    .justify-content-center.row{
                        > button{
                            margin: 0 !important;
                            }
                    }

                    .btn.btn-link {
                        width: 33%;
                        height: 59px;
                        text-transform: uppercase;
                        font-size: 1em;
                        font-weight: bold;
                        outline: none;

                        &:hover, &:active {
                            background-color: #ffffff;
                            border: 3px solid $brand-color-primary;
                            color: $brand-color-primary !important;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .billpay-scheduled-payments-header {
        margin-bottom: 1em;

        .main-title {
            @include fully-centered-col;
            text-transform: uppercase;
        }

        .subtitle {
            @include fully-centered-col;
            margin: 0;
            padding-bottom: 1em;
        }
    }
}