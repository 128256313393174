.icon-message-page {
    text-align: center;
    justify-content: center;
    position: relative;

    &:after {
        content: "";
        width: 375px;
        height: 375px;
        border-radius: 130vw;
        background-color: $brand-color-primary;
        opacity: 0.2;
        z-index: -1;

        @media screen and (max-width: 600px){
            width: 100%;
            height: 100vw;
        }
    }

    .container--layout {
        z-index: 10;
        position: relative;
    }

    h2 {
        color: $brand-primary;
        margin: 1rem 0 1rem 0;
        font-size: 1.25rem;
    }

    .text-lead {
        text-align: center;
        margin-top: 20px;
        max-width: 340px;
        margin-right: auto;
        margin-left: auto;
        font-size: 1em;
    }

    .svg-big-icon{
        width: 7em;
        height: 7em;
    }

    .btn.btn-block.btn-primary{
        width: 170px;
    }

    // Center in @normal
    @include respond-to(desktop) {
        flex-grow: 1;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.catastrophic-error-page {
    background-color: $brand-danger;

    h2 {
        color: white;
    }
}

.icon-message-page::after {
    position: fixed;
}

body {
    .no-more-data {
        .svg-big-icon {
            width: 12rem;
            height: 12rem;
        }
        // color: $brand-color-primary;
        color: #333333;
        .text-lead {
            opacity: 0.25;
            text-align: center;
        }

        margin: auto;

        @include respond-to(desktop) {
            margin: 2rem auto;
        }
    }

    .illustration-wrapper {
        width: 6rem;
        height: 6rem;
        background-color: $widget-title-background--color;
        background-color: white;

        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        .svg-big-icon {
            width: 4rem;
            height: 4rem;
        }
    }
}



.communications-module , .mailing {

    .container--layout:last-child {
        margin: 0 !important;
    }

    .illustration-wrapper {

        background-color: white;
        cursor: pointer;
        svg{
            cursor: auto;
            opacity: 0.30;            

            path{
                fill: white;
                stroke: $brand-color-primary;
            }
        }


        
    }
}

.svg-big-icon {
    width: 15vw;
    height: 15vw;

    @include respond-to(mobile) {
        width: 35vw;
        height: 35vw;
    }
}

@include respond-to(desktop) {
    .icon-message-page {
        &::before {
            content: "";
            width: 40%;
            padding-top: 40%;
            border-radius: 50%;
            background-color: $brand-primary;
            opacity: 0.2;
            position: absolute;
            left: 0;
            margin: -20% 30% auto 30%;
            top: 50%;
        }

        .justify-content-center {
            justify-content: center;
        }

        &:after {
            content: none;
        }
    }
}