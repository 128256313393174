/* bubble */
$bubble--color:  white;
$bubble--background-color: #cccfe3;
$bubble-warning--background-color: $brand-warning;
$bubble-success--background-color: $ui-color-success;
$bubble-error--background-color: $brand-danger;

.bubble-wrapper {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .data-aux {
        color: $gray-darker;
    }
}

.bubble {
    display: flex;
    border-radius: 50%;
    padding: 1rem;
    color: $bubble--color;
    background-color: $bubble--background-color;
}

.table-data {
    .bubble {
        padding: 0.5rem;
        width: 2.5rem;

        .svg-wrapper,
        svg {
            color: inherit;
            width: 1.5em;
            min-width: 1.5em;
            height: 1.5em;
        }
    }
}
.bubble.bubble-warning {
    background-color: $bubble-warning--background-color; //$bubble-warning--background-color;
}

.bubble.bubble-success {
    background-color: $bubble-success--background-color;
}

.bubble.bubble-error {
    background-color: $bubble-error--background-color;
}
