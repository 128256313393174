// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
    clear: both;
    content: "";
    display: table;
}

@include respond-to(mobile) {
    .flex-grow {
        flex-grow: 1;
    }

    .justify-content-center {
        justify-content: center;
    }
    
    .justify-content-right {
        justify-content: right;
    }
}

.justify-content-right {
    justify-content: right;
}

.margin-lateral-auto {
    margin: 0 auto;
}

.margin-top-0{
    margin-top: 0 !important;
}

.margin-top-5percent{
    margin-top: 12px !important;
}

.margin-0{
    margin: 0 !important;
}

.justify-content-center {
    justify-content: center !important;
    .more-information{
        display: flex;
        justify-content: center;
        button, button.btn-block:not(.btn-image){
            border: 0;
            margin: 0 10px;
            padding: 5px 0px 5px 14px;
            width: 34px !important;
            display: inline-flex;
            color: transparent;
        }
    }
}

.position-right-left-unset {
    right: 0;
    left: unset !important;
}

.content-center {
    justify-content: center;
}

@include respond-to(desktop) {
    .app-page .justify-content-center {
        justify-content: flex-start;
    }

    .productRequest .justify-content-center {
        justify-content: center;
    }

    .new-modal .justify-content-center {
        justify-content: center;
    }

    .justify-content-center {
        justify-content: center !important;
    }    
    
    .justify-content-center-btn {
        // margin-left: 0px !important;
        display: flex;
    }
}

.align-sections-center {
    align-items: center;
}

@include respond-to(mobile) {
    .align-items-center {
        align-items: center;
        &.align-items-center-agreements {
            align-items: unset;
            margin: 4rem 0;
            width: 90%;
            margin-left: 8%;

        }
    }
}

@include respond-to(desktop) {
    .align-items-center {
        &.align-items-center-agreements {
            padding-top: 30px;
        }
    }
}

.align-text-center {
    text-align: center;

    &.align-text-center-go-dashboard {
        padding-top: 20px;
    }
}

.min-height-max-content{
    min-height: max-content;
}

.align-items-end {
    align-items: flex-end;
}

[hidden] {
    display: none !important;
}

.mobile-only{
    @include respond-to(desktop){
        display: none !important;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ws-nowrap{
    white-space: nowrap;
}

@include respond-to(desktop) {
    max-width-75 {
        flex: unset;
        max-width: unset;
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.help-block {
    color: inherit;
}

.text-muted {
    color: inherit;
    opacity: 0.75;
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.display-block{
    display: block;
}

.justify-content-end {
    justify-content: flex-end;
}

.flex {
    flex: 1;
}

.margin-horizontal-10{
    margin: 0 10px;
}

.margin-vertical-10{
    margin: 10px 0;
}

.margin-horizontal-25{
    margin: 0 25px;
}

.margin-vertical-25{
    margin: 25px 0;
}

.margin-bottom-25{
    margin-bottom: 25px;
}

.margin-bottom-22{
    margin-bottom: -22px;
}

.full-length{
    width: 100%;
}

.full-length-desktop{
    @include respond-to(desktop){
        width: 100% !important;
    }
}
.min-form-mobile{
    align-self: center;
    min-height: 400px;
}

/**
 * ! Debo llevar esto a las secciones y no realizar estas escepciones aqui
 */

.theme--auth:not(.enrollment-layout) {
    @include respond-to(desktop) {
        .align-items-center {
            align-items: initial;
        }
    }
}

.add-colon {
    &::after {
        content: ":";
        display: inline;
    }
}

@include respond-to(desktop) {
    .form-content-agreements {
        .justify-content-center {
            .col-12 {
                flex: unset;
                max-width: 90%;
            }
        }
    }
}

.btn-min {
    width: 10px;
    margin: 10px;
    padding: 16px;
    border: none;
}

.font-raleway{
    font-family:'Raleway' !important;
}