/* ==========================================================================
Local variable component |
* Identificardor de variables relativas => fm
* Algunas variables estan con string porque estan localmente
========================================================================== */

$ui-grey-ultra-light: $gray-lighter;
$ui-color-type: #484848;
$ui-grey-base: gray;
$ui-white: white;
$fm--line-height: 20px;
$form-control--border: none;
$fm-control--border-radius: 4px;
$fm-no-bg: transparent;
$fm-space--xs: $xs;
$fm-space--s: $s;
$fm-space--m: $m;
$fm-space--l: $l;
$fm-fucus--color: #484848;
$fm-fucus--bg: white;
$form-control-fucus--color: #484848;
$hasError--color: $brand-danger;
$hasError--background-color: lighten($brand-danger, 30%);

// class form boostrap overrides
.form-group {
    margin: $form-field-vertical-space 0 $form-field-vertical-space;

    font-size: 1rem;
}

.form-control {
    height: auto;
    padding: 20px 15px 8px 15px;

    border: $form-control--border;
    border-radius: $fm-control--border-radius;

    color: $gray-darker;
    font-size: $form-field-font-size;
    line-height: $fm--line-height;
    font-weight: bold;
    box-shadow: none;

    &:focus {
        background-color: $fm-fucus--bg;
    }
    &[disabled]{
        background: $input-disabled-background;
    }
}

.form-control-select {
    width: 100%;

    // border: none;

    color: $brand-primary;

    background-color: transparent;
    box-shadow: none;

    appearance: none;
}

.form-group-box {
    margin: $s 0 $m;
}

.form-control:not(.form-control-select) .input-group {
    /* fix annoing bug in android when focus on field */
    @include respond-to(mobile) {
        z-index: 1;

        transform: translate3d(0, 0, 0);
    }
}

.input-group {
    border: 1px solid $input-border;
    border-radius: $input-border-radius;

    font-size: $fm-space--s;

    background-color: $input-bg;
    // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

    .btn {
        border-radius: 0;

        &:first-child {
            border-bottom-left-radius: $input-border-radius;
            border-top-left-radius: $input-border-radius;
        }

        &:last-child {
            border-top-right-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
        }
    }
    input{
        font-family: $brand-font-family-bold;
        font-weight: $brand-font-weight-bold;
    }
}

.form-group-control-list {
    margin-top: 0.5rem;

    .c-control {
        margin-right: 1em;
    }
}

.form-group-input-wrapper--select {
    max-height: 3.25rem;
    padding: 0.7rem 0.4rem;
}

.form-control-select {
    padding: 0.875rem 0.75rem;
}

.input-group textarea.form-control {
    resize: vertical;
}

/* Form field label animation */
.form-group{
    position:relative;  
    .form-group-text .control-label{
        font-family: $brand-font-family-bold;
        font-weight: $brand-font-weight-bold;
        position: absolute;
        left: 16px;
        top: 15px;
        color: $gray-base;
        z-index: 10;
        transition: 150ms ease-out;
        font-size: 14px;
        width: 80%;
        line-height: 20px;
        span{
            white-space: normal;
            position: relative;
        }
    }
}

.has-focus.form-group, .has-value.form-group, .false-input-group{
    input, .false-input-group{
        //Removed focus padding
        //padding: 15px 11px 9px 10px;
    }
    .form-group-text .control-label{
        transform: 150ms ease-out;
        top: 3px;
        font-size: 10px;
        color: $gray;
    }
}

.control-label-select{
    top: 1px;
    font-size: 10px;
    color: $gray;
    position: absolute;
    left: 13px;
    z-index: 10;
    transition: 150ms ease-out;
    width: 80%;
    font-weight: bold;
}

.form-group-text,
.input-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    .control-label{
        white-space: pre;
        text-align: left;
        pointer-events: none;
    }
}

.input-group {
    .form-control,
    .form-control-select {
        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.has-error {
    .input-group {
        background-color: $form-group-error--backbround-color !important;

        .form-control {
            box-shadow: none !important;
            &:-webkit-autofill {
                // box-shadow: 0 0 0 4rem $form-group-error--backbround-color inset !important;

                -webkit-text-fill-color: $form-group-error--color !important;
            }
        }
    }

    &.has-focus.input-group {
        background-color: $form-group-error--backbround-color !important;

        .form-control {
            &:-webkit-autofill {
                // box-shadow: 0 0 0 4rem $form-group-error--backbround-color inset !important;

                -webkit-text-fill-color: $form-group-error--color !important;
            }
        }
    }
}

.has-error {
    .form-control {
        &:-webkit-autofill {
            // box-shadow: 0 0 0 4rem $form-group-error--backbround-color inset !important;
            -webkit-text-fill-color: $form-group-error--color !important;
        }
    }
}

.form-control {
    transition: none;

    &:-webkit-autofill {
        // box-shadow: 0 0 0 4rem white inset !important;
    }
}

.has-focus {
    .form-control {
        &:-webkit-autofill {
            // box-shadow: 0 0 0 4rem white inset !important;
        }
    }
}

.has-error {
    .form-group-error {
        color: $form-group-error--backbround-color;
    }

    // .control-label, .control-label small {
    //     color: $gray-base;
    // }

    .form-group-error {
        font-size: 0.875rem;
    }

    .form-control {
        color: $form-group-error--color;

        background-color: $form-group-error--backbround-color;
    }

    .help-block {
        color: $form-group-error--backbround-color;
        font-weight: bold;
    }
}

.modal-dialog,
.theme-auth {
    .has-error {
        .form-group-error {
            color: $form-group-error--color;
            font-weight: bold;
        }

        .control-label, .control-label small {
            color: $gray-base;
        }

        .input-group .c-control-label {
            &,
            & > div {
                color: $form-group-error--color;
            }
        }

        .form-group-error {
            font-size: 0.875rem;
        }

        .input-group {
            border-color: $form-group-error--color;

            color: $form-group-error--color;

            // background-color: $form-group-error--backbround-color;
            div {
                div[class*="control"]{
                    background-color: $form-group-error--backbround-color;
                }

                div{
                    span[class*="indicatorSeparator"]{
                        background-color: $form-group-error--backbround-color; 
                    }
                }
            }
        }

        .help-block {
            color: $form-group-error--color;
            font-weight: bold;
        }


    }
}

.selection-group {
    margin-bottom: 1.25rem;

    .c-control-label {
        padding: 0.5em 1em;

        font-size: 0.875rem;
        // border-bottom: 1px solid $gray;
        font-weight: bold;

        &::before,
        &::after {
            content: none;
        }
    }

    .c-control-input + .c-control-label {
        color: $gray-lighter;
    }

    .c-control-input:checked + .c-control-label {
        color: $ui-grey-base;
    }
}

/* REVISAR CODIGO */

// Select en mobile para campo compuesto coloreado
.currency-selector {
    max-width: 6rem;

    font-size: 0.875rem; // position: absolute;

    .Select-control {
        font-size: 1em;
    }

    &.document {
        max-width: 14rem;

        span.Select-value-label {
            overflow: hidden;

            display: block;

            width: 160px;

            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.Select .Select-control {
    padding-top: 12px;
    padding-bottom: 12px;

    background-color: transparent;
}

.Select .Select.is-open > .Select-control,
.Select.is-focused .Select-control {
    background-color: transparent !important;
}

.input-group-datepicker {
    .react-datepicker-wrapper {
        display: block;

        .react-datepicker__input-container {
            display: block;
        }
    }
}

.form-group--datepicker {
    // Dark layer to cover content
    @include respond-to(mobile) {
        .react-datepicker-popper {
            position: fixed !important;
            bottom: 0 !important;
            left: 0 !important;

            width: 100% !important;
            margin: 0 !important;

            transform: translateY(0) !important;
        }

        .react-datepicker-popper::after {
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;

            width: 100%;
            height: 100%;

            content: "";

            opacity: 0;
            background-color: $ui-grey-base;

            animation: appearDatePicker 0.25s forwards;
            pointer-events: none;
        }

        .react-datepicker {
            position: absolute;
            bottom: 0;
            top:0;

            width: 100%;

            transform: translateY(0);
            animation: slideUp 0.25s forwards;
        }

        .react-datepicker__month-container {
            float: none;
        }
    }

    .input-group {
        input {
            float: none;

            display: block;

            background-color: transparent;
        }
    }

    .react-datepicker-wrapper {
        padding: 0;

        .react-datepicker__input-container {
            input,
            .mobile-custom-datepicker-input {
                padding: 13px $fm-space--s;

                border: none;
            }

            &:after {
                position: absolute;
                top: 50%;
                right: 0;

                margin-top: -0.75em;
            }

            .mobile-custom-datepicker-input.expand-area-input-picker{
                width: inherit;
            }
        }
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        width: 100%;
    }

    .input-group {
        border: none;
    }

    .form-control {
        padding: 0;

        input {
            padding: 14px 1em;

            border: 1px solid $gray;
            border-radius: 0.15rem;
        }
    }

    &.form-group--datepicker-period-filter {
        padding-left: 2px;
    }
}

@include respond-to(desktop) {
    .datepicker-period-filter {
        width: 100% !important;
        padding-top: 0;
        margin-top: 0;
    }
}


.datepicker-period-filter-field {
    border: 1px solid #e87722 !important;
}

@include respond-to(mobile) {
    .period-filter-container {
        width: 100%;
        margin: 0 !important;
       // margin-left: 5%;
        //margin-right: 5%;
        .container--layout:last-child &.container--layout:last-child {
            margin: 0 5%;
        }

        .datepicker-div-period-filter-mobile{
            width: 100%;
            padding: 0 20px;

            .datepicker-period-filter-field{
                width: 100%; 
            }

            .datepicker-filter-button-inner{
                padding: 0px 0px 10px 0;
                margin: 0;
                justify-content: center;
            }
        }
        
        

    }


    .datePicker-info{
        padding-top: 3%;
        width: 100%;
        text-align: center;
        span{
            font-weight: 400;
        }
    }
    .datepicker-period-filter {
        padding-top: 0;
        margin-top: 0;
    }
}

//container--layout align-items-center period-filter-container

@include respond-to(desktop) {
    .period-filter-container {
        width: 100%;
        margin-top: 0;

    }
    .datePicker-info{
        padding-top: 2%;
        padding-left: 1rem;
        span{
            font-weight: 400;
        }
    }
}

@media (max-width: 767px) {

    .container--layout:last-child .container--layout:last-child {
    /* margin-bottom: 0; */
    margin: 0 2rem;
    width: 100%;
    }
}


.theme-auth {
    .form-group--emailList {
        .input-group {
            border: none;
        }

        &.has-error .react-tagsinput {
            color: $form-group-error--color;

            background-color: $form-group-error--backbround-color;
        }
    }

    .react-tagsinput {
        border: 1px solid $gray;
        border-radius: 5px !important;
    }

    .react-tagsinput-tag {
        padding-left: 0.5rem;

        // border: none;
        border-radius: 3px;

        color: white;

        background-color: $brand-info;
    }

    .react-tagsinput-remove {
        border: none;

        background-color: transparent;

        &::before {
            color: inherit;
        }
    }

    .form-group .react-tagsinput--focused {
        border-color: $form-group-focus--border-color;
    }

    .form-group .react-tagsinput--focused {
        background-color: $gray-lighter;
    }
}

// textarea for terms and conditions
.input-group-text-area {
    overflow: auto;

    display: block;

    max-height: 50vh;
    padding: 13px 1em;

    > * {
        margin: auto;
    }
}

.weekday-selection {
    display: flex;
    flex-wrap: wrap;

    @include respond-to(mobile) {
        justify-content: space-between;
    }

    .c-control {
        margin: 0;
    }

    .c-control-label {
        padding: 0.5em;

        border: 1px solid currentColor;
        border-radius: 4px;

        color: $brand-info;

        + .c-control-label {
            margin-right: 0.25em;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    .c-control-input:checked + .c-control-label {
        color: $ui-white;

        background-color: $brand-info;
    }
}

.scheduler-frequency-suboptions {
    .control-label {
        font-weight: normal;
    }

    .input-group {
        margin-top: 0;
        margin-bottom: 0;
    }

    .form-text-group {
        display: flex;

        label {
            display: flex;
            align-items: center;
        }
    }

    .c-control {
        display: flex;

        label {
            display: flex;
            align-items: center;

            font-family: $headings-font-family;
        }

        .form-group,
        .input-group {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.form-group-row {
    display: flex;

    > * + * {
        margin-left: $fm-space--s;
    }
}

.form-group--scheduler {
    .check-list .c-control-label {
        font-size: $fm-space--s;
    }
}

@include respond-to(desktop) {
    .weekday-selection {
        .c-control {
            margin-right: 0.5rem;
        }
    }
}

.field-text-optional {
    margin-left: 0.25em;

    font-size: 0.75em;

    opacity: 0.75;
}

/* This is just a text addon for a field, it may be convenient to iterate about this */

.currency {
    height: auto;
    padding: 13px 1em;

    border-right: 1px solid $form-group--border-color;
    border-radius: 0.15625rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    font-size: $fm-space--s;
    line-height: 1.4;

    box-shadow: none;

    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    @include when-inside(has-error) {
        border-color: $form-group--border-color;
    }
    @include when-inside(has-focus) {
        border-color: $form-group-focus--border-color;
    }
}

.form-group--small .currency {
    padding-top: 7px;
    padding-bottom: 7px;
}

.has-error .currency {
    border-color: $form-group--border-color;
}

.has-focus .currency {
    border-color: $form-group-focus--border-color;
}

.security-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.form-group--loanPayments {
    width: 100%;
    margin-bottom: 0;

    .data-wrapper {
        padding-right: 2.5rem;
    }

    .table-row.is-expired {
        border-color: currentColor;

        color: $form-group-error--color;

        background-color: $form-group-error--backbround-color;
    }

    .table-data:first-child {
        padding-left: 0.75rem;
    }

    .table-data:last-child {
        padding-right: 2.25rem;

        @include respond-to(mobile) {
            padding-right: 2.25rem;
        }
    }

    .c-control-label {
        align-items: baseline;

        width: 100%;
        padding: 0.5rem 0;

        font-family: sans-serif;

        @include respond-to(mobile) {
            display: flex;
        }
    }

    .c-control-label::after,
    .c-control-label::before {
        right: 0.5rem;
        left: initial;
    }

    .c-control-label::after {
        content: "✓";
    }

    .c-control-input.disabled,
    .c-control-input[disabled] {
        ~ .c-control-label {
            opacity: initial;
            box-shadow: initial; // ??

            cursor: pointer;
        }

        + .c-control-label {
            box-shadow: none;

            cursor: not-allowed;

            &::before,
            &::before {
                opacity: 0.3;
            }
        }
    }

    .c-control-input:checked {
        ~ .c-control-label::after {
            transform: scale(0);
        }

        + .c-control-label::after {
            transform: scale(1.6);
        }
    }
}

@include respond-to(mobile) {
    .slideFromBottom {
        width: 100%;
        
        // Dark layer to cover content
        &::after {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 3;

            width: 100%;
            height: 100%;

            content: "";

            opacity: 0;
            background-color: #222222;

            pointer-events: none; // display: none;
            /* Esto tendria que conseguir realizarlo de alguna forma */
            // animation: hideLayer 0.3s ease forwards;
        }

        &.is-open {
            &::after {
                opacity: 0.7;

                animation: showLayer 0.3s ease forwards;
                pointer-events: all;
            }

            .Select-menu-outer {
                animation: slideUp 0.3s ease forwards;
            }
        }

        /* Select parts */
        .Select-option {
            user-select: none;
        }

        .Select-menu-outer {
            position: fixed;
            top: initial;
            bottom: 0;
            left: 0;
            // animation: slideUp 0.3s ease;
            overflow: auto;
            overflow-y: scroll;
            z-index: 4;

            width: 100%;
            max-height: 90%;

            border: none;
            border-radius: 0;

            color: $brand-info;
            text-align: center;

            background-color: white;

            -webkit-overflow-scrolling: touch;
        }

        .Select-menu {
            overflow-y: auto;

            max-height: 100%;
        }

        .Select-option {
            box-sizing: border-box;

            display: block;

            padding: $fm-space--s ($l / 2);

            color: currentColor;

            background-color: transparent;

            cursor: pointer;

            &:last-child {
                border-radius: 0;
            }

            &.is-selected {
                color: currentColor;

                background-color: transparent;
            }

            &.is-focused {
                color: currentColor;
                font-weight: bold;
                // this one is active in the Summary screen mobile Select
                background-color: rgba(14, 109, 110, 0.1);
            }

            &.is-disabled {
                color: currentColor;

                opacity: 0.75;

                cursor: default;
            }
        }
    }

    .slideFromBottom::after {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;

        width: 100%;
        height: 100%;

        content: "";

        opacity: 0;
        background-color: #222222;

        transition: 0.3s ease;
        pointer-events: none;
    }

    .is-shown.slideFromBottom::after {
        opacity: 0.7;

        pointer-events: all;
    }

    .slideFromBottom-wrapper {
        position: fixed;
        top: initial;
        bottom: 0;
        left: 0;
        z-index: 4;

        width: 100%;
        max-height: 100%;

        border: none;
        border-radius: 0;

        color: $brand-info;
        text-align: center;

        opacity: 0;
        background-color: white;

        transform: translate3d(0, 100%, 0);

        -webkit-overflow-scrolling: touch;
    }

    .is-shown .slideFromBottom-wrapper {
        animation: slideUp 0.3s ease forwards;
    }
}

.Select {
    .control-label,
    .data-label {
        margin-right: $fm-space--s;

        font-family: sans-serif;

        @include ellipsis;
    }
}

.form-section-title {
    color: $brand-primary;

    h3 {
        font-weight: normal;
    }
}

.form-group--bank-selector {
    .Select {
        min-width: 8.5rem;
    }
}

.Select {
    @include when-inside(".selector-size--small") {
        width: 4rem;
        min-width: 4rem;
    }
    @include when-inside(".selector-size--medium") {
        width: 8.5rem;
        min-width: 8.5rem;
    }
}

.input-group input:only-child,
.input-group textarea:only-child {
    border-radius: $input-border-radius !important;
    margin: 0 !important;
}

.scheduler-message {
    font-family: $headings-font-family;
}

.form-title {
    color: $brand-primary;
}

.form-group-wrapper {
    display: flex;
    flex-flow: column;

    > .form-group {
        margin-bottom: 0.5em;
    }

    .form-group-wrapper-inline {
        flex-flow: row;
    }
}

.form-group-wrapper-inline {
    align-items: center;
    flex-flow: wrap;

    > .form-group {
        margin-right: 0.75rem;
        margin-bottom: 0;
    }
}

.form-group-wrapper {
    display: flex;
    flex-flow: column;

    > .form-group {
        margin-bottom: 0.5em;
    }

    .form-group-wrapper-inline {
        flex-flow: row;
    }
}

.form-group-wrapper-inline {
    align-items: center;
    flex-flow: wrap;

    > .form-group {
        margin-right: 0.75rem;
        margin-bottom: 0;
    }
}

.select-small .Select {
    width: 10rem;
}

.list-item-inner .select-small .Select {
    width: 9rem;
}

.form-group {
    .input-group {
        margin-bottom: ($fm-space--s/2);
        .input-group-prepend{
            padding: 12px 10px 12px 20px;
            .svg-wrapper{
                width: 20px;
                height: 20px;
            }
        }
    }

    label,
    span {
        margin-bottom: 0;
    }
}

.form-group.hasError .form-group-box {
    background-color: $hasError--background-color;

    .form-control {
        color: $hasError--color;
    }
}

.form-group-box {
    .c-control {
        align-self: center;
    }
}

.form-control:focus {
    outline: none;
}

.c-control-label::before {
    // border-color: $brand-primary;
    border-radius: 5px;
}

.control-label {
    .text-optional {
        // margin-left: 0;
        // font-size: .875rem;
        // color: rgba(0, 0, 0, 1);
    }
}

.form-group-warning {
    position: absolute;
    right: 0;
    z-index: 10;

    padding: 5px;

    border-radius: 2px;

    color: $alert-color;

    background: $alert-bg;

    &::after {
        position: absolute;
        top: -14px;
        right: 10px;
        left: auto;

        display: inline-block;

        border: 7px solid transparent;
        border-bottom-color: $alert-bg;

        content: "";
    }
}

.filepond--root {
    .filepond--drop-label label {
        color: $brand-color-accent;
        font-size: 1em;

        cursor: pointer;
    }

    [data-filepond-item-state*="error"] .filepond--item-panel,
    [data-filepond-item-state*="invalid"] .filepond--item-panel {
        background-color: $brand-color-red;
    }

    [data-filepond-item-state="processing-complete"] .filepond--item-panel {
        background-color: $brand-color-green;
    }

    .filepond--drop-label .filepond--label-action {
        text-decoration-color: $brand-color-secondary;
    }
}

.filepond--file .filepond--file-action-button {
    background: $brand-color-accent;
}

.form-section-title .section-title {
    font-size: 1em;
    font-weight: bold;
}

.intro-description {
    font-size: 1em;
    font-weight: 500;
}

.form-group--stepper-field {
    .stepper-list {
        display: flex;
        flex-flow: wrap;

        .form-group {
            width: 5rem;
            margin: 0 1rem 0 0;
        }
    }
}

.form-group-wrapper {
    margin: 1rem 0;

    .form-group {
        margin-top: 0;
    }
}

.control-label {
    display: inline-block;

    margin-bottom: 0.25rem;
}

.list-item-inner .form-group-wrapper {
    margin: 0;
}

.form-required {
    font-family: $brand-font-family;
    font-weight: $brand-font-weight-semibold;
    text-align: right;
    align-items: right;
    font-size: 14px;
    margin: 0 auto 43px auto;
    max-width: 390px;
}

@include respond-to(mobile) {
    .form-control.date-form-control {
        height: 53px!important; 
    }
}

@include respond-to(desktop) {
    .form-control.date-form-control {
        height: 32px!important; 
    }
}

.input-group-side{
    box-sizing: border-box;
    margin: 0px 12px 0px 0px;
    min-width: 0px;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    -webkit-box-align: center;
    align-items: center;

    .side-button{
        color: #f7971c;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
    }

    .load {
        width: 25px;
        height: 25px;
        margin: 0px auto 0;
        border: solid 4px #f8a646;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
        -webkit-transition: all 0.5s ease-in;
        -webkit-animation-name: rotate;
        -webkit-animation-duration: 1.0s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        transition: all 0.5s ease-in;
        animation-name: rotate;
        animation-duration: 1.0s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.input-group-info{
    box-sizing: border-box;
    margin: 0px 0px 4px;
    min-width: 0px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: rgb(112, 122, 138);
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
 

 @-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}