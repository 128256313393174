/**
 * Estilos basicos para la tipografia
 */

:root {
    font-size: 16px;
}

//@include font('Open Sans', '/fonts/OpenSans');
//@include font('Open Sans Bold', '/fonts/OpenSans_Condensed');
//@include font('Open Sans SemiBold', '/fonts/OpenSans_SemiCondensed');


h1,
.h1,
h2,
.h2,
h3,
.h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-weight: $title-font-weight;
    font-family: $brand-font-family-bold;
    font-weight: $brand-font-weight-bold;
}

p,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

h1,
.h1 {
    font-size: 22px;
}
h2,
.h2 {
    font-size: 20px;
}
h3,
.h3 {
    font-size: 18px;
}
h4,
.h4 {
    font-size: 16px;
    font-weight: normal;
}

small,
.small {
    font-size: $font-size-small;
}

p,
ul li,
ol li,
.help-block {
    font-size: 14px;
}

@include respond-to(mobile) {
    h1,
    .h1 {
        font-size: 1.75rem;
    }
    h2,
    .h2 {
        font-size: 1.5rem;
    }
    h3,
    .h3 {
        font-size: 1.25rem;
    }
    h4,
    .h4 {
        font-size: 22px;
    }
}
