.form-group .btn-only-icon {
    border: none;
    padding: 0;

    .svg-wrapper {
        &:last-child {
            margin: 0 0.8rem;
        }

        svg {
            z-index: 1;
            width: 1.6rem;
            height: 1.6rem;
            padding: 0;
        }
    }
}

.messages-container {
    padding-right: ($l / 2) !important;

    .message-textarea {
        height: 150px;
    }
}

.filetag {
    margin: 0.25rem 0;
}

@include respond-to(desktop) {
    .btn-section {
        margin-top: 1rem;
    }
}

.communications-desktop-cols {
    height: 100%;

    > .col {
        // overflow: hidden;
    }
}

// .col-scrollable {
//     overflow-y: auto;
// }

@include respond-to(desktop) {
    .above-the-fold {
        .container--box {
            border-radius: 10px;
            background-color: lighten($brand-primary, 80%);
            padding: 1rem 0;
        }
    }

    .btn-icon-only {
        margin: 0;
        border: none;
        padding: 0.5em 0.25em;
        background-color: transparent;

        > span {
            @include visually-hidden;
        }
    }
}

.open-conversation ul {
    list-style: none;
    padding: 0;

}

/* Message-wrapper */
.message-item {
}

.message-title {
    margin-bottom: 0.5rem;
}

/* Message-body */
.list-content {
    margin-bottom: 0;

    > p {
        @include ellipsis;
        margin: 0;
    }
}

.mailing {
    @include respond-to(desktop) {
        margin: 0;
        flex-grow: 1;
        > .container--layout {
            margin: 0;
        }
    }

    .form-group {
        .btn-only-icon {
            svg {
                fill: $brand-info;
            }
        }
    }
    .form-control{
        padding: 6px 12px;
    }
    .react-tabs__tab-list{
        flex-wrap: wrap;
    }
    .icon {
        margin: 0;
        border: none;
        display: flex;
        padding: 0.5em 0.25em;
    }
    .btn{
        border: none;
        font-size: 16px;
        padding: 0;
        margin: 0;
        background: none;
        box-shadow: none;
        color: $ui-gray-dark;
        &:focus, &:focus:active,
        &:hover, &:active, &:focus-visible, &.active{
            background: none;
            border: none !important;
            color: $brand-color-primary;
            box-shadow: none;
            outline: none;
        }
    }
    .icon-tags {
        overflow: hidden;
        display: flex;
        width: 100%;
        margin-bottom: 1em;

        &.btn-group .btn + .btn,
        &.btn-group .btn + .btn-group,
        &.btn-group .btn-group + .btn,
        &.btn-group .btn-group + .btn-group {
            margin: 0;
        }

        .btn {
            flex-grow: 1;
            @include respond-to(desktop) {
                flex-direction: column;
                margin-top:0;
            }
            padding: 0.5em;
            // flex-grow: 1;
            // flex-basis: 50%;
            // max-width: 50%;

            > span {
                white-space: nowrap;
                font-size: 0.8rem;
            }

            .svg-wrapper,
            svg {
                width: 1.5em;
                height: 1.5em;
            }

            &.btn-block {
                margin-right: 0;
            }

            &.btn-block + .btn-block {
                margin-top: 0;
            }

            &:not(.active) {
                > span {
                    @include respond-to(mobile) {
                        @include visually-hidden;
                    }
                    @include respond-to(md) {
                        @include visually-hidden;
                    }
                    @include respond-to(sm) {
                        @include visually-hidden;
                    }
                }
            }
        }

        .active {
            // background-color: $brand-info;
            color: $brand-color-primary;
            // border: 1px solid transparent;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .messages-container {
        padding: 0;
    }

    .message-list {
        margin: 0;

        li {
            background-color: white;
            // padding: $s;

            > div,
            > div > div {
                width: 100%;
            }

            // p {
            //     margin: 0;
            // }

            .data-date {
                white-space: nowrap;
            }

            @include respond-to(mobile) {
                padding: 1rem;
            }

            h4 {
                @include ellipsis;
            }

            cursor: pointer;
        }
        .read-message {
            color: #969696;

            // h4,
            // p {
            //     margin: 0;
            // }

            p.date {
                opacity: 0.5;
            }
        }
        .svg-wrapper {
            width: auto;
        }

        h4 {
            // margin: 0;
        }

        li {
            display: flex;
            .mail-image {
                width: 50px;
                height: 50px;
                display: block;
                margin-right: 1em;
                border-radius: 10px;
            }

            .message-text {
                margin: 0 1.5em 0 0;
                padding: 0;
                font-size: 0.875rem;
            }

            .icons {
                display: flex;
                // margin-top: 0.4em;

                .btn {
                    margin: 0;
                    border: none;

                    padding: 0.5em 0.25em;

                    > span {
                        @include visually-hidden;
                    }
                }

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    display: block;
                    float: left;

                    &.attach-image {
                        margin-right: 0.3em;
                    }
                }
            }
        }
    }



    .open-conversation {
        ul {
            margin-bottom: 20px;
        }
        .btn.btn-link:hover {
            color: $brand-primary !important;
            background-color: transparent;
        }

        button {
            justify-content: flex-end;
            max-width: 60px;

        }
        .view-title {
            min-width: 150px;
        }
    }

    .read-message, .message-list {
        .message-item{
            box-shadow: $box-shadow;
            border-radius: $box-border-radius;
            padding: 20px 10px;
            margin-bottom: 16px;

            .flex-container {
                .ellipsis {
                    max-height: 110px;
                }
            }
        }
    }

    .response-section {
        padding: 2em 0;
        background-color: lighten($brand-primary, 60%);
        width: 100%;
        z-index: 30;
    }
}
