.sendMoney-manage-recipient {
    width: 100%;
    
    .new-modal-wrapper {
        
        .new-modal {
            @include fully-centered-col;
            width: 470px;
            padding: 0 1em;
            background-color: #ffffff;

            .new-modal-header {
                border-bottom: none;
            }

            .description {
                text-align: center;
                margin-top: 1.5em;

                strong {
                    color: $brand-color-accent;
                }
            }

            article {
                padding: 0;

                .btns-wrapper {
                    @include fully-centered-row;
                    padding: 10px 0;

                    &.btn-block + .btn-block { 
                        margin: 0;
                    }

                    .justify-content-center.row{
                        > button{
                            margin: 0 !important;
                            }
                    }

                    .btn.btn-link {
                        width: 33%;
                        height: 59px;
                        text-transform: uppercase;
                        font-size: 1em;
                        font-weight: bold;
                        outline: none;

                        &:hover, &:active {
                            background-color: #ffffff;
                            border: 3px solid $brand-color-primary;
                            color: $brand-color-primary !important;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    .highlighted-link {
        color: $brand-color-primary;
        font-weight: 600;
        cursor: pointer;
    }

    .sendMoney-manage-recipient-list {
        width: 100%;
        padding: 1em;

        .sendMoney-manage-recipient-list-heading {
            color: $brand-color-black;
            border-bottom: 3px solid #F3F9F8;
            padding-bottom: 0.7em;
            font-size: 0.9em;
        }

        .sendMoney-manage-nav {
            @include row-v_center-h_end;
            margin-bottom: 1em;
        }

        .sendMoney-manage-recipient-list-item {
            width: 100%;
            @include row-v_center-h_start;
            border-bottom: 2px solid #F3F9F8;
            padding: 8px 0;

            .recipient-details {
                width: calc(100% - 44px);
            
                .highlighted {
                    font-weight: bold;
                    color: $brand-color-accent;
                }

                .regular-bold {
                    font-weight: bold;
                    color: $brand-color-black;
                }
            }

            .btn-icon {
                width: 22px;
                height: 22px;
                border: none;
                margin: 0 0 0 18px;
                padding: 0;
                outline: none;

                > * {
                    margin: 0;
                }
            }
        }
    }

    .sendMoney-update-recipient-summary {
        @include row-v_start-h_space_between;
        padding: 20px 40px;

        > div {
            @include col-v_start-h_start;
            width: 50%;
        }

        p {
            @include col-v_start-h_start;
            margin-bottom: 20px;

            .summary-label {
                color: $brand-color-black;
                font-weight: bold;
            }

            .summary-value {
                color: $brand-color-black;
            }
        }
    }
}

@media (max-width: 844px) {
    .sendMoney-manage-recipient {
        .new-modal-wrapper {
            padding: 0;

            .btns-wrapper {
                .container {
                    .row {
                        .btn-block
                         {
                            margin-top: 0px !important;
                        }
                    }
                }
            }
        }
    }
}