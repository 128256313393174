.iframePayveris {
    border: none;
    margin: 0px !important;
    height: 100%;
    width: 100%;

    @include respond-to(desktop) {
        margin-top:4% !important;
    }
    @include respond-to(mobile) {
        margin-bottom:10% !important;
        margin-top:10% !important;
      }
    
}

.payVerisContainer{
    @include respond-to(mobile) {
      //  height: 100%;
        width: 100%;
        h1 {
            padding-top: 10%;
        }
    }
    @include respond-to(desktop) {
        margin-top:2.5% !important;
        margin-left: 10%;
        width: 85%; 
    }

    .view-page .view-content{
        overflow: hidden;
    }
}

.billpay-summary {
    @include fully-centered-col;
    width: 100%;
    margin-bottom: 30px;

    .heading {
        margin-bottom: 0.5em;
        @include row-v_center-h_space_between;
        width: 100%;
        max-width: 500px;
    }

    .billpay-summary-content-wrapper {
        margin-top: 2em;
        width: 100%;
        max-width: 500px;
        padding: 27px 12px;
        // background-color: #F5F5F5;
        // margin: 20px 0;
        margin-bottom: 20px;

        .billpay-summary-content {
            position: relative;
            background-color: #ffffff;
            border-radius: 8px;
            -webkit-box-shadow: $box-shadow;
            box-shadow: $box-shadow;

            .billpay-summary-content-body {
                width: 100%;
            }

            .billpay-summary-content-footer {
                @include row-v_center-h_end;

                .btn-edit {
                    width: auto;
                    height: auto;
                    color: #F7971C;
                    font-size: 1em;
                    font-weight: bold;
                    background: none;
                    border: none;
                }
            }
        }
    }
}


.billpay {
    .billpay-container {
        @include respond-to(mobile){
            width: 100%;
            max-width: 390px;
            margin: 0 auto;
        }
        .billpay-logo {
            padding-right: 6px;
            &.detail-links-href{
                flex-direction: row;
            }
        }

        .capitalize {
            text-transform: capitalize !important;
        }
        // removed to use globally, moved it to _shared.scss
        //
        // .split-body-wrapper {
        //     @include row-v_start-h_space_between;

        //     > * {
        //         width: 45%;
        //     }
        //     order: 3;
        //     flex-basis: 100%;
        //     margin-top: 37px;

        //     @include respond-to(mobile) {
        //         margin-top: 0;
        //     }
        // }

        .heading {
            text-align: center;
        }
        #widget_container {
            min-height: 90vh;
            iframe {
                height: 34rem;
                //width: 51vh;
                min-width: 374px;
                max-width: 54vh;
                margin-left: -0.2em;

            }
        }

        .sendMoneySelector-heading{
            @include respond-to(mobile) {
                display: none;
            } 
        }

        .sendMoneyCard{
            justify-content: center;
            align-items: center;
            display: flex;

            .sendMoneyCard-container{
                max-width: 42rem;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-top: 30px;

                @include respond-to(mobile) {
                    margin-top: 10px;
                }
            }

            .sendMoneyCard-bg{
                background-image: url(../images/sendmoneybg1.jpg);
                width: 14rem;
                height: auto;
                background-size: cover;
                background-position: bottom;
                filter: sepia(100%); 


                @include respond-to(mobile) {
                    height: 8rem;
                    width: auto;
                }
            }

            .sendMoneyCard-right{
                width: 28rem;

                @include respond-to(mobile) {
                    width: auto;
                }
            }

            .sendMoneyCard-card{
                @include respond-to(mobile) {
                    display: block;
                }

                display: flex;
                box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
                border-radius: 0.5rem;
                background-color: #fff;
                overflow: hidden;
            }

            .sendMoneyCard-headerContainer{
                padding: 1.25rem;  
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
                
                .title{
                    font-size: 1.25rem;
                    font-weight: bold;
                    color:#333333;
                }

                .subtitle{
                    color: #4a5568;
                    font-size: 1.025rem;
                }
            }

            .sendMoneyCard-footerContainer{
                padding: 1.25rem;  

                .button-container{
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    @include respond-to(mobile) {
                        display: block;
                        text-align: center;
                    }

                }

                button{
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    padding-top: 0.75rem;
                    padding-bottom: 0.75rem;
                    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
                    //background-color: #0E6D6E;
                    background-color: #e87722;
                    

                    color: white;
                    border-radius: 0.5rem;
                    cursor: pointer;
                    border: 0;
                    @include respond-to(mobile) {
                        margin-top: 10px;
                        width: 100%;
                    }
                }
                button:disabled,
                button[disabled]{
                    //border: 1px solid #999999;
                    background-color: $btn--background;
                    color: $btn--color;
                    cursor: not-allowed;
                }

                .conditions{
                    font-size: 0.9rem;
                    color: #718096;
                    margin-top: 3.75rem;
                    text-align: center;
                }
            }
        }

        .billpay-form {
            .update-schedule-payments-container {
                .container {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
            width: 100%;
            max-width: 390px;
            margin: 0 auto;
            .billpay-form-footer {
                @include col-v_center-h_start;
                padding: 1em 0;

                p {
                    margin: 0;
                }
                .info-message {
                    font-family: $brand-font-family-semibold;
                    font-weight: $brand-font-weight-semibold;
                    padding-left: 4px;

                }
            }
            
            .transfer-options-container{
                display: flex;

                .btn-click-here{
                    width: auto;
                    font-size: 1em;
                    background-color: $btn--background;
                    color: $btn--color;
                    border: none;
                    box-shadow: none;
                    border-radius: 2px;
                    margin-top: 0;
                    margin-left: 8px;
                    font-weight: 600;
                    padding: 0px 8px 2px 8px;
                    text-decoration: none;
                    white-space: nowrap;
                }
            }
        }
        .billpay-summary-footer{
            width: 100%;
            &.update-schedule-summary-buttons{
                div {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .billpay-payment-summary {
            @include row-v_start-h_space_between;
            padding: 20px 40px;
    
            > div {
                @include col-v_start-h_start;
                width: 50%;
            }
    
            p {
                @include col-v_start-h_start;
                margin-bottom: 20px;
    
                .summary-label {
                    text-transform: uppercase;
                    color: #333333;
                    font-weight: bold;
                }
    
                .summary-value {
                    color: #333333;
                    font-weight: normal;
                }
            }
        }
        .recent-transactions {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            .powered-by-footer{
                display: none;
                visibility: hidden;
            }
        }
    }
    .fee {
        color: $brand-color-terciary ;
        font-size: small;
        font-weight: bold;
        font-family: "Raleway", sans-serif;    
    }
}

@media (max-width: 700px) {

    .billpay-summary {
        .heading {
            max-width: 100%;
        }

        .billpay-summary-content-wrapper {
            width: 100%;
            max-width: 100%;
            padding: 27px 12px;
            margin: 20px 0;
        }
    }
}

// @media (max-width: 1250px) {
//     .billpay {
//         .billpay-container {
//             .split-body-wrapper {
//                 @include fully-centered-col;
    
//                 > * {
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }