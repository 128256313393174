/* Interpolation map to acomodate font size on :root */
$map-globalFontSize: (
    320px: 13px,
    375px: 16px,
    767px: 18px,
    768px: 14px,
    1024px: 15px,
    1440px: 16px,
) !default;

/* Footer height on external pages */

$external-footer-height: 2.5rem;

$xs: 0.75rem;
$s: 1rem;
$m: 1.25rem;
$l: 2rem;

$title-font-weight: 600;
$body-type-weight: normal;

/* ==========================================================================
   COMPONENTS
   ========================================================================== */

/* Objects -------------------------- */

/* button */

// Button Facebook
$btn-facebook-bg: #3b5998;
$btn-facebook-color: color-yiq($btn-facebook-bg);
$btn-facebook-border: darken($btn-facebook-bg, 5%);

/* control */

// selection-list variables
$selection-list-icon--color: $brand-info;
$selection-list--color: $gray-dark;
$selection-list--background-color-active: $gray-lighter;
$selection-list--color-active: $gray-darker;

// checks-list variables
$checks-list-icon--color: $brand-info;
$checks-list--color: $gray-dark;
$checks-list--color-active: $gray-darker;

// icon-toggle variables
$icon-toggle--color: darken($brand-info, 5%);
$c-control-input--border-color: $gray;
$control-label-box--border-color: $brand-color-primary;
$control-label-box--background-color: $input-bg;

/* data */
$data-amount-credit--color: $ui-color-success;
$data-amount-debit--color: $brand-danger; //$brand-color-red;
$data-name--color: $gray-dark;

$data-tag--background-color: #49ccbd;
$data-tag--background-color--is-expired: $brand-danger; //$brand-color-red;
/* transfer-data-flow borders */

$separation-line--color: $gray-lighter; //$brand-color-element;

/* media object */
$media-object--backgound-color: #675b90;
$media-object-branded--backgound-color: white;
$media-object--backgound-color--state-success: $brand-color-primary;
$media-object--backgound-color--state-warning: $brand-warning;
$media-object--backgound-color--state-error: $brand-danger;

/* table */
$table-head--color: $brand-primary;
$table-head--border-color: $gray-lighter;
$table-row--border-color: $gray-lighter;
$table-row-expired--color: $brand-danger;
$table-row-expired--border-color: $table-row-expired--color;
$table-row-expired--background-color: lighten($brand-danger, 35%);
$table-row-success--color: $ui-color-success;
$table-row-success--border-color: $table-row-success--color;
$table-row-success--background-color: lighten($ui-color-success, 35%);
$table-data--color: $gray-light;
$table-legend--color: $brand-primary;
$table-data--padding-x: 0.5em;

// esto es asi porque se suma el margen del hijo
$table-data--padding-y: 0.25em;
$table-data--accent-width: 0.25rem;

/**
    * En @desktop la data tiene una separacion extra respecto al final de la fila
    */
$table-row--padding-to-edge: 1rem;

/* Modules -------------------------- */

/* alert */
$alert-warning--color: $brand-primary;
$alert-warning--background-color: $brand-warning;
$alert-warning--border-color: $brand-warning;

$alert-error--background-color: $brand-danger;
$alert-error--color: color-yiq($alert-error--background-color);

/* content heading section */
/* @mobile */
$section-content-heading--border-color: $gray-lighter;

/** 
    * Actualmente este color no esta utilizado, era el circulo de los iconos para 
    * los iconos en los listados de productos 
    */
$content-image--background-color: rgba(73, 204, 189, 0.5);
$content-image-icon--color: $brand-primary;

/* dropdown */
$dropdown-menu--background-color: white;
$dropdown-menu--border-color: $gray-lighter;

$dropdown-menu-item--border-color: $gray-lighter;

/* forms */
$form-group-focus--border-color: $brand-primary;
$form-group--border-color: $ui-gray-light;
$form-group-error--color: #ff2300;
$form-group-error--backbround-color: #ffffff; //lighten($form-group-error--color, 37.5%);
$form-field-vertical-space: 15px;
$form-field-font-size: 14px;
$input-border-width: 1px;
$input-border-radius: 4px;
$input-disabled-background: $ui-gray-ultra-light;

/* header command pallete */
$header-command-icon--background-color: $ui-white;
$header-command-icon--color: $brand-primary;
$header-command-icon-active--background-color: $brand-color-secondary;
$header-command-icon-active--color: white;
// $header-command--color: $brand-info;
$header-command--color: #333333;
$header-dropdown--border-color: $gray-lighter; //$brand-color-element;

$communications-badge--background-color: red;
$communications-badge--color: white;

/* navigational list */
$navigational-list-title--color: $brand-primary;

/* product item */
$product-expired--color: $brand-danger;
$product-expired--background-color: lighten($product-expired--color, 30%);
$product-name-tag--color: white;
$product-name-tag--background-color: #49ccbd;
$product-name-tag--background-color--external: #7c73a2;

/* widget */
$widget-title--color: #000000;
$widget-title-background--color: #f5f5f5;
$widget-porfolio-items--color: white;
$widget-porfolio-liquidity--background-color: #00bc9b;
$widget-porfolio-fixedIncomes--background-color: #2d96dd;
$widget-porfolio-variableIncomes--background-color: #9c56b8;
$widget-porfolio-others--background-color: $brand-primary;
$widget-porfolio-empty--background-color: $gray-lighter;

/* Layout */
/* header */
$brand-logo--color: $brand-primary;

$view--color: color-yiq($brand-primary);

$view-header--background-color: $brand-primary;
$view-header--color: color-yiq($view-header--background-color);

$sidebar--background-color: lighten($brand-primary, 60%);

/* Pages */
$list-item--color--error: $brand-danger;
$list-item--icon-color--error: $list-item--color--error;

$list-item--color--success: $ui-color-success;
$list-item--icon-color--success: $list-item--color--success;

$camera-overalay--background-color: rgba($color: black, $alpha: 0.7);

/* login desktop cols */
$login-desktop-wrapper--color: white;
$some-color: $panel-bg !default;

/* login splash */
$view-title--color: $brand-primary;
$view-icons--color: $brand-info;
$login-logo--color: $brand-primary;

/* product detail */
$view-header-product-onSmall--background-color: $brand-primary;
$view-header-product-onSmall--color: color-yiq($view-header-product-onSmall--background-color);

$view-heading-content-product-onSmall--background-color: $view-header-product-onSmall--background-color;
$view-heading-content-product-onSmall--color: color-yiq($view-heading-content-product-onSmall--background-color);

$carrousel-dots--color: lighten($brand-primary, 30%);
$carrousel-dots--color--theme-product-mobile: $brand-info;


$sidebar-width: 16rem;
$sidebar-width--collapsed: 4rem;
$sidebar-easing: cubic-bezier(0.075, 0.82, 0.165, 1);
$sidebar-transition-length: $sidebar-width - $sidebar-width--collapsed;