.toolbar {
    position: relative;

    display: block;
    flex-grow: 1;

    width: 100%;

    @include respond-to(desktop) {
        margin: 0 (-$l / 2);
    }

    .btn {
        padding: 1rem;
    }
// removed for point pickup
    // h1 {
    //     font-size: 1.7rem;
    // }
}

@include respond-to(mobile) {
    .toolbar {
        flex-flow: column;
    }   
}

.toolbar-product-name .view-title {
    display: flex;

    @include respond-to(desktop) {
        max-width: 50%;
    }
    @include respond-to(mobile) {
        width: 84%;
    }
}

.toolbar-item {
    display: flex;
    align-items: center;
    justify-content: center; // flex-wrap: wrap;

    padding-right: ($l / 2);
    padding-left: ($l / 2);
    margin-bottom: 0;

    .svg-icon {
        min-width: 100%;
    }
}

/* fake toolbar item for alignments */
.toolbar-item-placeholder{
    width: 64px; 
    height: 20px;
    display: flex;
}
/**
 * Se quita el texto para los botones del toolbar (presente en el cabezal) para 
 * el entorno exterior de la aplicacion y para @mobile.
 */

.toolbar-btn {
    > span {
        @include when-inside(".login-desktop-wrapper") {
            // @include visually-hidden;
        }
        @include respond-to(mobile) {
            @include visually-hidden;
        }
    }
}

.toolbar-product-name {
    display: flex;
    flex-direction: column; //align-items: left;

    width: 100%;
    min-width: 0;
    margin-top: 24px; 
    
    @include respond-to(desktop) {
        width: 75%;
        margin: auto;
        flex-shrink: 1;
    }

    .data-aux {
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .view-title {
        z-index: 100;

        padding-bottom: 0;
        margin-bottom: 0;

        h2 {
            width: auto;
            max-width: 100%;

            text-align: left;

            @include ellipsis;
            @include respond-to(desktop) {
                margin-right: 1rem;

                font-size: 1.5rem;
            }
        }

        .form-control {
            padding: 0;

            font-family: $headings-font-family;
            font-size: 1.5rem !important;

            &:focus {
                box-shadow: none;
            }
        }

        .input-group {
            z-index: 1;
        }

        .input-group-disabled {
            background-color: transparent;

            .form-control {
                color: inherit;

                background-color: transparent;
            }
        }
    }

    .is-editing {
        z-index: 1;

        margin-top: -$m;

        &::before {
            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            height: 100vh;

            content: "";

            background-color: rgba(14, 14, 14, 0.2);

            pointer-events: all;
        }
    }

    .buttons {
        position: absolute;
        bottom: 0rem;
        z-index: 2;

        button {
            margin: 0 0.5rem;
        }
    }


    + .toolbar-item {
        flex: 1 0 auto;
    }
}

@include respond-to(mobile) {
    .btn.toolbar-btn {
        font-size: 1rem;
    }
}

.theme-auth {
    .toolbar-btn {
        border: 1px solid currentColor;

        color: $brand-info;

        @include respond-to(mobile) {
            border: none;
        }

        &:hover {
            color: darken($brand-info, 10%);
        }
    }
}

.toolbar-item-right {
    right: 1rem;

    @include respond-to(mobile) {
        right: 0;
    }
}

.toolbar-item-left {
    left: 1rem;

    @include respond-to(mobile) {
        left: 0;
    }
}

.toolbar-btn {
    top: 0;

    padding-right: 1em;
    padding-left: 1em;

    color: white;

    background-color: transparent;

    @include respond-to(mobile) {
        margin: 0 0.625rem;

        border: none;

        @include when-inside(".toolbar-product-name") {
            color: white;
        }

        &.toolbar-btn-right {
            margin-right: -1em;
        }

        &.toolbar-btn-left {
            margin-left: -1em;
        }
    }

    &:hover {
        color: white;

        background-color: rgba(white, 0.25);
    }

    &.toolbar-btn-right {
        right: 1rem;
    }

    &.toolbar-btn-left {
        left: 1rem;
    }
}

.toolbar-item--fixed {
    position: absolute;
    z-index: 1;

    @include respond-to(mobile) {
        top: -0.75em;
        z-index: 1;

        padding: 0;

        .btn {
            margin: 0;
        }
    }

    &.toolbar-item--fixed-agreements {
        position: absolute !important;

        @include respond-to(mobile) {
            right: 5%;
            top: 8%;
        }

        @include respond-to(desktop) {
            right: 4%;
        }
    }
}

@include respond-to(mobile) {
    .toolbar-item {
        &.toolbar-item--fixed {
            z-index: 100;
        }
    }

    .view-title h2 {
        width: initial;
    }
}

@include respond-to(desktop) {
    .toolbar {
        display: flex;
        align-items: center;

        .view-title,
        .view-title h2 {
            @include when-inside(theme-auth) {
                flex-grow: initial;

                width: initial;

                text-align: initial;
            }
        }

        &.toolbar-account-header {
            display: block;
            flex-flow: column;
        }
    }

    .toolbar-item {
        display: block;
    }

    .toolbar-item--fixed {
        display: flex;
    }

    .theme-auth .toolbar-item--fixed {
        position: initial;
        
        .btn.btn-only-icon.btn-cta{
            padding: 0;

            svg{
                width: 0.8em;
                height: 0.8em;
            }
        }
    }

    .toolbar-product-name {
        flex-direction: row;
        justify-content: flex-start;
    }
}

.toolbar-item {
    .form-group,
    .c-control {
        margin-top: 0;
        margin-bottom: 0;
    }

    .view-close:hover {
        color: darken($brand-info, 10%);

        //border-color: $brand-color-accent;
        //color: white;
        //background: $brand-color-accent;
    }

    .close-rel{
        border: none;

        span{
            display: none;
        }
    }
}

@include respond-to(desktop) {
    .toolbar {
        .toolbar-item--fixed .btn {
            // padding: 0.5em;
            margin-left: 0;
        }

        /* este codigo afecta el cabezal de producto */
        + .toolbar-item .dropdown .btn {
            padding: 0.5em;
            margin-right: -($l / 2);
        }
    }


}

.toolbar-item.toolbar-item-accounts {
    justify-content: flex-end;
    display: block;

    .download-button{
        margin-right: unset;
        width: auto;
        .svg-wrapper{
            color: $brand-color-primary;
        }

        > span{
            text-transform: capitalize;
            font-weight: bold;
        }

        &:active{
            background: none;
            box-shadow: none;
        }
    
        &:focus{
            background: none;
            box-shadow: none;
        }
    }
}

/**
 * TODO: review element
 * pseudo select in header
 */

.toolbar .desktop-edit {
    .btn-select {
        padding-right: 3rem;
        margin-left: 0;

        border-color: currentColor;

        color: black;
        font-size: 0.875rem;
        font-weight: normal;

        background-image: url("../../images/selectArrowDown.svg");
        background-repeat: no-repeat;
        background-position: 96% center;
        background-size: 1rem;

        > span {
            text-align: left;
        }
    }

    .row {
        flex-wrap: nowrap;
    }
}

.desktop-edit-empty-message {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

@include respond-to(mobile) {
    .mailIcon svg {
        width: 1.25em;
        height: 1.25em;
    }

    .toolbar-product-name {
        .product-name {
            margin-top: 0;
        }
    }
}