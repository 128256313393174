/* ==========================================================================
   Normalizando algunos estilos basicos necesarios para la aplicacion
   ========================================================================== */

html,
body,
#root {
    height: 100%;
    min-height: 100%;
}

html {
    box-sizing: border-box;

    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0;

    font-family: "Open Sans";
    font-size: 1rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    cursor: default;

    @include respond-to(mobile) {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        user-select: none;

        -webkit-touch-callout: none;

        input,
        textarea {
            -webkit-user-select: initial;
            -moz-user-select: initial;
            -ms-user-select: initial;
            -khtml-user-select: initial;
            user-select: initial;

            -webkit-touch-callout: initial;
        }
    }

    -ms-content-zooming: none;
    -ms-touch-action: pan-y;
}

/**
 * Set up a decent box model on the root element
 */

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

hr {
    margin: 0.5rem;
}

img {
    width: 100%;
}

svg {
    overflow: hidden;

    fill: currentColor;
}
label {
    margin: initial;
    font-weight: $body-type-weight;
}

ul,
td {
    text-align: left;
}


hr {
    margin-right: 0;
    margin-left: 0;

    border-color: $gray-lighter;
}
