$login-desktop-wrapper--background-color: $some-color;

@include respond-to(desktop) {

    .view-header,
    .view-header .navbar {
        background: transparent;
    }

    .login-background .view-header,
    .login-background .view-header .navbar {
        background: transparent;
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;

        width: 100%;
    }

    .login-desktop-wrapper {
        padding: $l;
    }

    .login-desktop-cols {
        >.col .col {
            flex: 0 0 100%;

            max-width: 100%;
            padding-right: 0;
            padding-left: 0;
        }
    }

    video {
        transform: translate3d(0, 0, 0);
    }

    .panel.login-desktop-wrapper {
        position: relative;
        border-radius: 10px;
        color: $login-desktop-wrapper--color;
        background-color: $login-desktop-wrapper--background-color;
        box-shadow: 0px 0px 50px 1px rgba(0, 0, 0, 0.05);
        max-width: 720px;
        /* Permite que las columnas a la mitad no se alinien de lado */
        >.container--layout>.container>.row>.col {
            margin: 0 1px;
        }

        .login-desktop-wrapper {
            padding: 0;
            color: inherit;
            background-color: transparent;
        }

        .navbar{
            .navbar-header{
                .toolbar{
                    .toolbar-item{
                        button{
                            color: $brand-color-primary;

                            &:hover{
                                color: darken($brand-info, 10%);
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}

.login-desktop-wrapper {
    .toolbar-btn {
        color: white;
    }

    .text-lead {
        color: $ui-white;

        &.text-left {
            margin-bottom: 3.7rem;
            font-size: 1.5rem;
        }
    }

    .image {
        margin-bottom: 1rem;
    }

    .input-group{
        border: 2px solid $brand-primary;
    }

    .login-initial-screen{
        .justify-content-center{
            .c-control{
                .c-control-label::before {
                    border: 1px solid $ui-white;
                    border-radius: 2px;
                }
                .form-group-text{
                    span{
                        font-weight: 500;
                        color: $ui-white;
                    }
                }
            }

            .text-lead{
                h2, p{
                    color: $ui-white;

                    button{
                        color: $ui-white;
                    }
                }
            }
        }
    }

    h2.title{
        span{
            color: white;
        }
    }

    .link-forgot-password-desktop{
        display: flex;
        justify-content: space-between;
        a{
            color: $ui-white;
            span{
                border-radius: 5px;
                color: $ui-white;
            }
        }
    }

    .user-locked-alert-message {
            color: $ui-white;
    }

    .link-unlock-account-desktop{
        text-align: right;
        a{
            color: $ui-white;
            span{
                border-radius: 5px;
            }
        }
    }

    .view-page{
        .container--layout{
            .form-group{
                span{
                    // color: $ui-white;
                }
            }
        }
    }
}

.mobileLogin{
    .form-group{
        .control-label{
            top: 14px;
        }
    }
    h2.title{
        span{
            color: white;
        }
    }
    .link-forgot-password{
            display: flex;
            justify-content: space-between;
            a{
                color: $ui-white;
                span{
                    border-radius: 5px;
                    color: $ui-white;
                }
            }
        }

    .user-locked-alert-message {
        color: $ui-white;
    }

    .link-unlock-account{
        text-align: right;
        a{
            span{
                border-radius: 5px;
                color: $ui-white;
            }
        }
    }
}

.login-background{
    .view-page{
        .container--layout{
            .form-group{
                span{
                    color: $brand-color-primary;
                }
            }
        }
    }
}

.captcha {
    width: 310px;
    margin: auto;
}

// Login footer styles

.abs-pos {
    .app-footer {
        position: absolute;
        z-index: 999;
    }
}

.app-footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    background-color: rgba(189, 191, 193, 0.6);

    .legal-nav {
        li a {
            line-height: $external-footer-height;
        }
    }
}

@include respond-to(desktop) {
    .login-background {
        .app>.container--layout {
            margin-top: ($l / 2);
            margin-bottom: ($l / 2);

            &.app-footer {
                margin-bottom: ($l / 2);
                margin-bottom: 0;
                height: $external-footer-height;
            }
        }

        .view-header .navbar .navbar-header {
            padding: 0;
            margin: 0;
        }

        .toolbar-item--fixed .toolbar-btn {
            margin: 0;
        }

        .container--layout .container--layout:first-child {
            margin-top: 0;
        }

        .container--layout .container--layout:last-child {
            margin-bottom: 0;
        }
    }
}

@include respond-to(mobile) {
    .login-background .view-header .navbar {
        background-color: transparent;
    }

    .app-footer {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0;
        background: none;
    
        .legal-nav {
            li a {
                line-height: $external-footer-height;
            }
        }
    }
}