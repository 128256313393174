// HEADER & NAVBAR //
// App header
$app-header--background-color: darken($brand-primary, 10%);
$app-header--padding-top: 15px;
$app-header--padding-bottom: 10px;
$app-header--margin-bottom: 10px;
$brand-logo--color: $brand-primary;

/* ---------- LOGO ---------- */

.navbar-header>div {
    display: flex;
    justify-content: space-between;

}

.navbar-brand {
    padding: $m;
    height: $m;

    svg {
        width: $m * 9;
        padding: 0;
        color: $brand-logo--color;

        @include respond-to(desktop){
            width: $m * 11;
        }
    }
}

/* ---------- GENERALES ---------- */

.app-header,
.view-header {
    background-color: transparent;

    @include respond-to(desktop) {
        .navbar {
            padding-left: $l;
            padding-right: $l;
        }
    }

    .navbar {
        margin-bottom: 0;
    }

    .navbar-toggle {
        background-image: url("../images/styledmenu-close.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
        background-size: 0.875rem;
    background-size: contain;
    background-origin: content-box;
    }

    .navbar-toggle.collapsed {
        background-image: url("../images/styledmenu-hamburger.svg");
    }

    .navbar-toggle {
        .icon-bar {
            opacity: 0;
        }
    }

    .progress {
        background-color: rgba(28, 28, 29, 0.5);

        .progress-bar {
            background-color: white;
        }
    }
}

.card-admin-options-header {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: $brand-color-primary;
    margin-left: 10px;
    margin-right: 10px;
    border-width: thin;
    text-align: center;
    padding-bottom: 1%;
    padding-top: 2%;
    @include respond-to(mobile) {
        padding-top: 0;
        margin-top: 0;
    }

}
.card-admin-options-header2 {
    margin-left: 10px;
    margin-right: 10px;
    border-width: thin;
    text-align: center;
}
.card-admin-options-header-text.fill-available {
    font-size: 1.5rem;
}
h2{
    &.card-admin-options-header {
        margin-bottom: 0.5rem;
        margin-block-end: 0px;
        color: $brand-color-base !important;
    }

}


@include respond-to(mobile) {

    .view-header {

        .view-title h2 {
            margin-bottom: 1.25rem;
        }

        .navbar-collapse {
            margin: 0;
            border: none;
        }
    }
}

.app-header .navbar,
.view-header .navbar {
    border: none;
}

.view-header .navbar {
    color: $view-header--color;
}

// COLORING

.app,
.view-wrapper {
    color: $view--color;
}

/* ---------- HEADER DESKTOP ---------- */

.dropdown {
    ul {
        padding: 0;
        width: 100%;
        border-top: none;

        li a {
            padding: $s $l;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .dropdown-toggle span.caret {
        margin-left: 0.5em;
    }
}

.app {
    .app-header {
        transform: translate3d(0, 0, 0);

        .navbar {
            background-color: white;
            min-height: initial;
            margin-bottom: 0;
            border-radius: 0;

            >.container .navbar-brand,
            >.container-fluid .navbar-brand {
                padding-left: 0;
            }
        }

        .navbar-header {
            width: 100%;

            .container-navbar {
                display: table;
                width: 100%;
            }

            >.col {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .navbar-nav>li>a {
                padding-top: $m;
                padding-left: $m - ($l / 2);
                padding-right: ($l / 2);
                padding-bottom: ($l / 2);
                background-color: transparent; //pisar bootstrap
            }

            >.navbar-collapse {
                margin: 0 - ($l / 2); //-20px;
            }
        }
    }
}

/* ---------- HEADER MOBILE ---------- */

.view-header {
    transform: translate3d(0, 0, 0);
    padding: 0;
    // background-color: white;

    .navbar {
        border-radius: 0;
        border-width: 0 0 1px 0;
        background-color: white;
    }

    .navbar-header {
        width: 100%;
        align-items: center;

        .view-back {
            border: none;

            @include respond-to(desktop) {
                padding-left: 0;
                padding-right: 0;
                margin: 0 (-$s);

                &.btn {
                    font-size: 1.5rem;
                }

                >span {
                    @include visually-hidden;
                }
            }

            .svg-wrapper {
                margin: 0 !important;
            }
        }
        .toolbar{
            .toolbar-item{
                padding: 0;
            }
            .toolbar-btn, button {
                position: relative;
                padding: 20px 20px 20px 20px;
                margin: 0;
            }
            .emptySpace{
                visibility: hidden;
            }
        }
        .fill-available{
            width: 100%;
            width: -moz-available;
            width: -webkit-fill-available;
            width: fill-available;
            margin-top: 20px;
            text-align: center;
        }

        .subtitle{
            width: 100%;
            width: -moz-available;
            width: -webkit-fill-available;
            width: subtitle;
            font-size: 1.8em;
        }
    }

    // problema de herencia para mobile
    .navbar-default .navbar-nav>li {
        &.open>a {
            color: white;
            background-color: $brand-color-primary;

            span {
                color: inherit;
            }
        }
    }

    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form {
        background-color: $sidebar--background-color;
    }

    @include respond-to(desktop) {
        .navbar-header {
            display: flex;
            align-items: flex-start;

            .toolbar .view-title {
                flex-grow: 1;
            }

            .toolbar-product-name {
                flex-wrap: wrap;
                justify-content: flex-start;

                .view-title {
                    flex-grow: initial;
                }
            }
        }

        .labelGreeting span{
            color: black;
            font-family: 'Open Sans';
            font-weight: bold;
        }
    }
}

@include respond-to(mobile) {

    .app-header,
    .view-header {
        text-align: center;
    }

    .view-header .navbar {
        background-color: transparent;
    }

    .theme-auth .view-header .navbar {
        background-color: $ui-white;
    }
}

.app-header {
    // z-index: 100;
    z-index: 2;

    .container-fluid {
        padding: 0;
    }
}


@include respond-to(desktop) {

    .navbar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0;

        .navbar-header {
            padding: 0 $l;
        }
    }
}

@include respond-to(mobile) {
    .navbar-header {
        &.navbar-header-unlock-account {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

.navbar-right {
    margin-right: -($l / 2);
}

//--------- badge animation ---------//

.badge {
    &.animation {
        -webkit-animation: zoom-notifications 0.3s;
        /* Safari 4.0 - 8.0 */
        -webkit-animation-fill-mode: both;
        /* Safari 4.0 - 8.0 */
        animation: zoom-notifications 0.3s;
        animation-iteration-count: 1;
        animation-fill-mode: both;

        span {
            animation: fall-number 0.3s 0.2s;
            animation-iteration-count: 1;
            animation-fill-mode: both;
        }
    }
}

/*zoom of red badge */
/* Safari 4.0 - 8.0 */
@-webkit-keyframes zoom-notifications {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    75% {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

@keyframes zoom-notifications {
    from {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.3);
    }

    75% {
        transform: scale(0.9);
    }

    to {
        transform: scale(1);
    }
}

/*fall number */
/* Safari 4.0 - 8.0 */
@-webkit-keyframes fall-number {
    from {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(5px);
    }

    75% {
        transform: translateY(-5px);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes fall-number {
    from {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(5px);
    }

    75% {
        transform: translateY(-5px);
    }

    to {
        transform: translateY(0px);
    }
}