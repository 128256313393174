.scheduler {
    font-family: $brand-font-family;

    .scheduler-input-container {
        margin: 0;

        .false-input-group {
            height: 3.5em;
            padding: 15px 11px 9px 10px;
            @include row-v_center-h_start;
            font-family: $brand-font-family;

            .fi-label {
                font-weight: bold;
                text-transform: uppercase;
                padding: 0;
                padding-left: 4px;
            }

            .fi-value {
                color: $ui-gray-base;
                margin-left: 0.5em;
                padding: 0;
            }
        }

        .btn-schedule {
            max-width: 191px;
            margin-right: 0;
            margin-left: 0;
            //font-size: 1em;
            //background-color: $btn--background;
            //color: $gray-base;
            //border: none;
            //box-shadow: none;
            //border-radius: 2px;
            //margin-top: 0;
        }
    }

    .scheduler-control-panel {
        .scheduler-option-panel {
            padding-left: 2em;

            .scheduler-option-list {
                list-style-type: none;
                padding: 0;

                .scheduler-option-list-item {
                    color: $brand-color-primary;
                    cursor: pointer;
                    font-family: $brand-font-family;

                    &.selected {
                        @include row-v_center-h_start;
                        color: $brand-color-terciary;
                        font-weight: bold;

                        .svg-wrapper {
                            width: 1.5rem;
                            padding: 0;

                            .svg-icon {
                                width: 1.5rem;
                                min-width: 1.5rem;
                                height: 1.5rem;
                            }
                        }

                        span {
                            font-family: $brand-font-family;
                            text-align: left;

                            &.highlighted-text {
                                color: $brand-color-primary;
                                font-weight: bold;
                                margin-left: 0.8em;
                            }
                        }
                    }
                }
            }

            .scheduler-option-control {
                .future-date-scheduler {
                    width: 100%;
                    max-width: 330px;
                    height: 3.5em;

                    .react-datepicker-wrapper {
                        margin: 0;
                        width: 100%;
                        max-width: 330px;
                    }
                }

                .weekly-scheduler {
                    width: 100%;
                    padding-bottom: 1em;

                    .weekly-scheduler-option-list {
                        list-style-type: none;
                        @include fully-centered-row;
                        border: 1px solid $brand-color-primary;
                        padding: 1em 5%;
                        // -webkit-box-shadow: 1px 10px 10px 5px #C1E3E1; 
                        // box-shadow: 1px 10px 10px 5px #C1E3E1;

                        .weekly-scheduler-option-list-item {
                            @include fully-centered-col;
                            color: $brand-color-primary;
                            cursor: pointer;
                            font-size: 1em;
                            font-family: $brand-font-family;
                            border: 1px solid $brand-color-primary;
                            width: 12%;
                            max-width: 2.5em;
                            height: 2.5em;
                            text-transform: uppercase;
                            border-radius: 3px;

                            &:not(:last-of-type) {
                                margin-right: 0.5em;
                            }

                            &.selected {
                                background-color: $brand-color-primary;
                                border: none;
                                color: white;
                            }
                        }
                    }

                    .weekly-scheduler-description {
                        margin-top: 1em;
                        color: $brand-color-black;

                        .highlighted-text {
                            font-weight: bold;
                        }
                    }
                }

                .monthly-scheduler {
                    // padding: 0 1em 2em 1em;
                    padding-bottom: 1em;

                    .monthly-scheduler-option-selection {
                        @include row-v_center-h_start;
                        color: $brand-color-black;
                        font-weight: bold;
                        border: 1px solid $brand-color-primary;
                        padding: 1em 2em;
                        font-size: 14px;
                        // -webkit-box-shadow: 1px 10px 10px 5px #C1E3E1; 
                        // box-shadow: 1px 10px 10px 5px #C1E3E1;

                        .input-group {
                            width: 5em;
                            margin: 0 0.5em;
                        }
                    }

                    .monthly-scheduler-description {
                        margin-top: 1em;
                        color: $brand-color-primary;
                        color: $brand-color-black;
                        .highlighted-text {
                            font-weight: bold;
                        }
                    }

                    .css-393e4d-menu {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}

.required-scheduler {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    .form-required{
        margin: 0;
        text-align: right;
    }
}