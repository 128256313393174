/* Flexbox mixins */

/* ROW LAYOUTS */

@mixin vertically-centered-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

@mixin fully-centered-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@mixin row-v_center-h_start {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

@mixin row-v_center-h_end {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

@mixin row-v_start-h_center {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@mixin row-v_start-h_start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin row-v_start-h_space_between {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

@mixin row-v_center-h_space_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* COLUMN LAYOUTS */

@mixin fully-centered-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin col-v_start-h_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@mixin col-v_start-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin col-v_end-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
}

@mixin col-v_end-h_end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

@mixin col-v_center-h_start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@mixin col-v_center-h_end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}