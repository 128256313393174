.sendMoney-summary{
        .heading {
                @include row-v_center-h_space_between;
                width: 100%;
                max-width: 500px;
            }
        .sendMoney-summary-content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            .sendMoney-summary-content-mobile {
                width: 85%;
                background-color: #ffffff;
                border: 2px solid #FDE9BB;
                -webkit-box-shadow: 0px 13px 15px 5px #AFDCD7; 
                box-shadow: 0px 13px 15px 5px #AFDCD7;
                margin-top: 3%;

            }
            .sendMoney-summary-content {
                width: 60%;
                position: relative;
                background-color: #ffffff;
                border: 2px solid #FDE9BB;
                -webkit-box-shadow: 0px 13px 15px 5px #AFDCD7; 
                box-shadow: 0px 13px 15px 5px #AFDCD7;
                margin-top: 4%;
            }    
            .sendMoney-summary-content-mobile, .sendMoney-summary-content{    
                .sendMoney-summary-content-body {
                    width: 100%;
                    .sendMoney-add-money-summary{
                        padding: 20px;
                
                        > div {
                            @include col-v_start-h_start;
                          
                        }
                
                        p {
                            @include col-v_start-h_start;
                            margin-bottom: 15px;
                
                            .summary-title {
                                text-transform: uppercase;
                                color: #B3B5B5;
                                font-weight: bold;
                            }
                
                            .summary-value {
                                color: #747474;
                                font-weight: bold;
                            }
                        }

                        p {
                            font-size: 0.9em;
                        }
                    }
                }
            }
        }
    }

    .sendMoney-summary-content-footer {
       .btn-edit {
        @include row-v_center-h_end;
           width: auto;
           height: auto;
           color: #F7971C;
           font-size: 0.9em;
           font-weight: bold;
           background: none;
           border: none;
       }
   }

   .sendMoney-summary-footer{
        @include fully-centered-col;
        .btn-footer{
            @media (max-width: 800px){
                margin-top: 3%!important;
            }
        }
   }
   .sendMoney-summary-footer{
        margin-top: 4%!important;
        margin-bottom: 2%;
        @media (max-width: 700px){
            margin-top: 4%;
   }
}
 