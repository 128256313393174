/* Vector Wrappers */

.svg-wrapper {
    width: 100%;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    >div {
        display: flex;
    }
}

.svg-image {
    width: 100%;
    height: 100%;
    &.white{
        path{
            fill: white;
        }
    }
}

.svg-wrapper-small{
    svg{
        max-width: 75px;
    }
}

.svg-image-onboarding-welcome {
    width: 95%;
    height: 100%;
    margin: auto;

    @media screen and (max-width: 400px) {
        width: 75%;
    }
}

.svg-image-onboarding-summary {
    width: 95%;

}

/* Vector styling */

.class-1,
.class-2 {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    // stroke-width: 2px;
}

.class-1 {
    stroke-miterlimit: 10;
}

.transactionListVector {
    display: flex;
}

a.table-row .transactionListVector .svg-icon,
a.table-row .transactionListVector-warning .svg-icon {
    height: 1.3em;
}

a.table-row .transactionListVector-error .svg-icon,
a.table-row .transactionListVector-success .svg-icon {
    height: 1em;
}

.table-row .transactionListVector-error,
.table-row .transactionListVector-success,
.table-row .transactionListVector-warning {
    display: flex;
}

.table-row .transactionListVector .svg-wrapper,
.bubble-wrapper .transactionListVector .svg-wrapper {
    color: $brand-primary;
}

.table-row .transactionListVector-error .svg-wrapper,
.bubble-wrapper .transactionListVector-error .svg-wrapper {
    color: $brand-danger;
}

.table-row .transactionListVector-success .svg-wrapper,
.bubble-wrapper .transactionListVector-success .svg-wrapper {
    color: $ui-color-success;
}

.table-row .transactionListVector-warning .svg-wrapper,
.bubble-wrapper .transactionListVector-warning .svg-wrapper {
    color: $brand-warning;
}

.st0 {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}

.stroke {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.transactionListVector-success .stroke-fat {
    stroke-width: 2px;
}

.stroke-fat,
.fat {
    stroke-width: 3px;
}

.svg-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
}

.svg-onb{
    @include respond-to(desktop) {
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
    }
}

.svg-icon-medium{
    width: 5rem;
    min-width: 5rem;
    height: 5rem;
}

.svg-icon-small{
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
}

/* TODO: problema de especificidad con chevroms */
a.table-row svg.svg-caret,
.svg-caret {
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
}

.form-group--loanPayments {
    .c-control-mark {
        width: 1.25rem;
    }

    .svg-caret {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
    }
}

.svg-logo {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
}