/*------------------------------------*\
    #ALERT
\*------------------------------------*/

@mixin colorize-alert($background-color, $border-color) {
    color: color-yiq($background-color);
    border-color: $border-color;

    hr {
        border-top-color: color-yiq($background-color);
    }

    .alert-link {
        color: color-yiq($background-color);
    }
}

/*------------------------------------*\
    #BADGE
\*------------------------------------*/

@mixin colorize-badge($background-color, $text-color: color-yiq($background-color)) {
    color: $text-color;
    background-color: $background-color;

    &[href] {
        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;
            background-color: scale-color($background-color, $lightness: 10%);
        }
    }
}

/*------------------------------------*\
    #BUTTON
\*------------------------------------*/

@mixin colorize-button($background-color, $border-color: $background-color) {
    background-color: $background-color;
    border-color: $border-color;
    color: color-yiq($background-color);

    &:hover,
    &:focus {
        background-color: scale-color($background-color, $lightness: -10%);
        border-color: scale-color($border-color, $lightness: -10%);
    }
}

/*------------------------------------*\
    #TABLE
\*------------------------------------*/

@mixin colorize-table-row($state, $background: $background-color) {
    // Exact selectors below required to override `.table-striped` and prevent
    // inheritance to nested tables.
    .table-#{$state} {
        &,
        > th,
        > td {
            background-color: scale-color($background, $lightness: 85%);
        }
    }

    // Hover states for `.table-hover`
    // NOTE: this is not available for cells or rows within `thead` or `tfoot`.
    .table-hover {
        $hover-background: scale-color($background, $lightness: 70%);

        .table-#{$state} {
            &:hover,
            &:focus {
                background-color: $hover-background;

                > td,
                > th {
                    background-color: $hover-background;
                }
            }
        }
    }
}

/*------------------------------------*\
    #SCOPE
\*------------------------------------*/

@mixin colorize-scope($theme-colors: ()) {
    @if "table-row" {
        @each $color, $value in $theme-colors {
            @include colorize-table-row($color, $value);
        }
    }

    @if "button" {
        @each $color, $value in $theme-colors {
            .btn-#{$color} {
                @include colorize-button($value);
            }
        }
    }

    @if "badge" {
        @each $color, $value in $theme-colors {
            .badge-#{$color} {
                @include colorize-badge($value);
            }
        }
    }

    @if "alert" {
        @each $color, $value in $theme-colors {
            .alert-#{$color} {
                $background-color: scale-color($value, $lightness: 50%);
                $border-color: scale-color($value, $lightness: 90%);

                @include colorize-alert($background-color, $border-color);
            }
        }
    }
    @if "progress" {
    }
}

// @mixin colorize-navbar(
//     $background-color: $navbar-background-color,
//     $border-color: $navbar-border-color,
//     $text-color: $navbar-text-color,
//     $icon-color: $navbar-icon-color
// ){
//     background-color: $background-color;
//     border-color: $border-color;
//     color: $text-color;

//     .navbar-brand {
//         color: inherit; // $text-color; // $navbar-default-brand-color;

//         &:hover, &:focus {
//           color: scale-color($text-color, $lightness: 20%); // $navbar-default-brand-hover-color;
//           background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-brand-hover-bg;
//         }
//     }

//     .navbar-text {
//         color: inherit; // $text-color; // $navbar-default-color;
//     }

//     .navbar-nav {

//         > li > a {
//             color: scale-color($text-color, $lightness: 5%); // $navbar-default-link-color;

//             &:hover, &:focus {
//                 color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-hover-color;
//                 background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-hover-bg;
//             }
//         }

//         > .active > a {
//             &, &:hover, &:focus {
//                 color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-active-color;
//                 background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-active-bg;
//             }
//         }

//         > .disabled > a {
//             &, &:hover, &:focus {
//                 color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-disabled-color;
//                 background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-disabled-bg;
//             }
//         }

//         .open .dropdown-menu .divider {
//             background-color: scale-color($background-color, $lightness: -20%);
//         }
//     }

//     .navbar-toggle {
//         border-color: $border-color; // $navbar-default-toggle-border-color;

//         &:hover, &:focus {
//             background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-toggle-hover-bg;
//         }

//         .icon-bar {
//             background-color: $icon-color; // $navbar-default-toggle-icon-bar-bg;
//         }
//     }

//     .navbar-collapse,
//     .navbar-form {
//         border-color: $border-color; // $navbar-default-border;
//     }

//     .navbar-collapse {
//         box-shadow: none;
//     }

//     // Dropdown menu items
//     .navbar-nav {
//         // Remove background color from open dropdown

//         > .open > a {
//             &,  &:hover,  &:focus {
//                 background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-active-bg;
//                 color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-active-color;
//             }
//         }

//         @media (max-width: $grid-float-breakpoint-max) {
//             // Dropdowns get custom display when collapsed

//             .open .dropdown-menu {
//                 > li > a {
//                     color: inherit; // $text-color; // $navbar-default-link-color;

//                     &:hover, &:focus {
//                         color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-hover-color;
//                         background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-hover-bg;
//                     }
//                 }

//                 > .active > a {
//                     &, &:hover, &:focus {
//                         color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-active-color;
//                         background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-active-bg;
//                     }
//                 }

//                 > .disabled > a {
//                     &, &:hover, &:focus {
//                         color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-disabled-color;
//                         background-color: scale-color($background-color, $lightness: -10%); // $navbar-default-link-disabled-bg;
//                     }
//                 }
//             }
//         }
//     }

//     // Links in navbars
//     //
//     // Add a class to ensure links outside the navbar nav are colored correctly.

//     .navbar-link {
//         color: inherit; // $text-color; // $navbar-default-link-color;

//         &:hover {
//             color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-hover-color;
//         }
//     }

//     .btn-link {
//         color: inherit; // $text-color; // $navbar-default-link-color;

//         &:hover, &:focus {
//             color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-hover-color;
//         }

//         &[disabled],
//         fieldset[disabled] & {
//             &:hover, &:focus {
//                 color: scale-color($text-color, $lightness: 20%); // $navbar-default-link-disabled-color;
//             }
//         }
//     }
// }

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
    #{$context} & {
        @content;
    }
}

@mixin respond-to($media) {
    @if $media == xs {
        @media (max-width: $screen-xs-max) {
            @content;
        }
    } @else if $media == sm {
        @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
            @content;
        }
    } @else if $media == md {
        @media (min-width: $screen-md) and (max-width: $screen-md-max) {
            @content;
        }
    } @else if $media == lg {
        @media (min-width: $screen-lg) {
            @content;
        }
    } @else if $media == mobile {
        @media (max-width: $screen-xs-max) {
            @content;
        }
    } @else if $media == desktop {
        @media (min-width: $screen-sm) {
            @content;
        }
    }
}

/// poly-fluid-sizing
/// Generate linear interpolated size values through multiple break points
/// @param $property - A string CSS property name
/// @param $map - A SASS map of viewport unit and size value pairs
/// @requires function linear-interpolation
/// @requires function map-sort
/// @example
///   @include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
/// @author Jake Wilson <jake.e.wilson@gmail.com>
@mixin poly-fluid-sizing($property, $map) {
    // Get the number of provided breakpoints
    $length: length(map-keys($map));

    // Error if the number of breakpoints is < 2
    @if ($length < 2) {
        @error "poly-fluid-sizing() $map requires at least values";
    }

    // Sort the map by viewport width (key)
    $map: map-sort($map);
    $keys: map-keys($map);

    // Minimum size
    #{$property}: map-get($map, nth($keys, 1));

    // Interpolated size through breakpoints
    @for $i from 1 through ($length - 1) {
        @media (min-width: nth($keys,$i)) {
            #{$property}: linear-interpolation(
                (
                    nth($keys, $i): map-get($map, nth($keys, $i)),
                    nth($keys, ($i + 1)): map-get($map, nth($keys, ($i + 1))),
                )
            );
        }
    }

    // Maxmimum size
    @media (min-width: nth($keys,$length)) {
        #{$property}: map-get($map, nth($keys, $length));
    }
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */

@mixin visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin font($font-family, $font-file) {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.eot');
        src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
            url($font-file+'.woff') format('woff'),
            url($font-file+'.ttf') format('truetype'),
            url($font-file+'.svg#'+$font-family) format('svg');
        font-weight: normal;
        font-style: normal;
    }
}
