

// sizing
.menu-list,
.dropdown.open .dropdown-menu {
    font-size: 1rem;
    // margin-top: 1px;
}

// Main Navigation
.menu-list{
    flex-direction: column;

    width: 100%;
    margin: 0;

    @include respond-to(desktop) {
        margin-top: 0;
    }

    @media (min-width: 576px) {
        max-width: 100%;
        margin-bottom: auto;
    }

    /* First level nav items */
    > .menu-list-item {
        font-size: 1em;
        > a,
        > span {
            font-family: $headings-font-family;
            font-size: 1em;
        }
    }

    .svg-wrapper {
        position: absolute;
        left: 25px;

        width: 1.25em;
        margin-right: 0.5em;

        svg {
            width: 1.25em;
            height: 1.25em;
        }
    }

    .menu-list-item {
        z-index: 0;

        > a,
        > span {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding-left: 2.5em;

            color: $navbar-default-color;

            border-bottom: 1px white;
            .caret {
                position: absolute;
                right: 1.25rem;

                width: 0.75rem;
                height: 0.75rem;

                border: none;

                background: url("../../images/styledmenu-arrow-down.svg") center center no-repeat;
            }
            &.is-active {
                color: $brand-primary;
            }
        }
        &.has-focus{
            .svg-wrapper path{
                fill: white !important;
            }
        }
    }

    .dropdown.open {
        .caret {
            background: url("../../images/styledmenu-arrow-up-white.svg") center center no-repeat;
        }
        .dropdown-toggle{
            .svg-wrapper path{
                fill: white !important;
            }
        }
        .dropdown-menu {
            position: relative;
            float: none;

            border: none;

            background-color: transparent;
            box-shadow: none;

            li {
                font-size: 1em;

                a {

                    border-radius: 0;

                    color: $navbar-default-color;
                    white-space: normal;
                }
                &:last-child a {
                    border: none;
                }
            }
        }
    }
}

// mobile
.view-header .navbar .container-fluid {
    padding: 0;
}


@include respond-to(mobile) {
    // Main Navigation Mobile
    .menu-list {
        /* First level nav items */
        .menu-list-item {
            position: relative;
            font-size: 16px;
            line-height: 16px;
            > a,
            > span {
                //color: white;
                padding: 15px 15px 15px 65px !important;
            }
        }
        .dropdown.open {
            > a{
                border-bottom: 1px solid white;
                border: white;
                &:hover, &:active{
                    border-bottom: 1px solid white;
                    border: white;
                }
            }
            .dropdown-menu {
                li {
                    border: none;
                    &.dropdown.open > a.dropdown-toggle{
                        border-bottom: 1px solid white;
                        border: white;
                    }
                    &.has-focus{
                        > a{
                            &:hover{
                                background-color: $brand-color-base;
                                > span {
                                    //color: white;
                                }
                            }
                            > span {
                                //color: black !important;
                                background-color: transparent !important;
                            }
                        }
                    }
                    > a {
                        font-weight: bold;
                        span, div span{
                        }
                    }
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
    // Card Admin Navigation Mobile
    .cc-links-wrapper .menu-list {
        .menu-list-item {
            position: relative;
            background-color: white;
            > a,
            > span span {
                color: $brand-color-base;
            }
            > span{
                padding-left: 15px !important;
                text-transform: uppercase;
                &:hover, &:active{
                    span{
                        text-decoration: underline;
                    }
                    background: white;
                }
            }
        }
        
    }
    .view-header {
        // BSselector
        .container > .navbar-header,
        .container > .navbar-collapse,
        .container-fluid > .navbar-header,
        .container-fluid > .navbar-collapse {
            margin: 0;
        }

        .navbar-header {
            &::before,
            &::after {
                content: none;
            }
        }

        .navbar-toggle {
            position: absolute;
            right: 0;

            margin-right: 0.625rem;

            border: none;

            .icon-bar {
                background-color: $brand-info;
            }

            &:hover,
            &:focus {
                background-color: transparent;

                .icon-bar {
                    background-color: darken($brand-info, 10%);
                }
            }
        }
    }
}

.toggle-menu-button {
    position: relative;
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 0.75em 2em 0.75em 3.5rem;

    border: none;
    border-bottom: 1px solid white;

    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
    line-height: 1.4;
    text-decoration: none;
    white-space: normal;

    background-color: transparent;

    cursor: pointer;

    &:focus,
    &:hover {
        color: white;

        background-color: $brand-color-primary;
        outline: none;

        > .svg-wrapper svg path{
            fill: white;
        }
    }

    > .svg-wrapper{  
        color: $brand-color-primary;         
    }
}

.scrollable-sidebar {
    position: relative;
    overflow: auto;

    display: flex;
    flex: 1;
    flex-direction: column;
}

//---


.menu-list {
    @include respond-to(desktop) {
        width: $sidebar-width;
        min-width: auto;
    }
}

.menu-list-item {
    a:focus, a:hover {
        outline: none;
        .svg-wrapper svg path{
            fill: white !important;
        }
        .caret {
            background: url("../../images/styledmenu-arrow-down-white.svg") center center no-repeat;
        }
    }
}

.default-layout {
    .app-content {
        padding-left: $sidebar-width;

        transition: all 1.50s $sidebar-easing;
    }
}

.app-sidebar {
    transition: transform 0.750s $sidebar-easing;

    .menu-list {
        transition: transform 0.750s $sidebar-easing;

        span {
            transition: opacity 0.750s $sidebar-easing;
        }
    }
}

.sidebar-collapsed {
    .app-content {
        padding-left: $sidebar-width--collapsed;
    }

    .scrollable-sidebar {
        overflow-x: hidden;
    }

    .app-sidebar {
        transform: translate3d(-$sidebar-transition-length, 0, 0);

        .menu-list {
            transform: translate3d($sidebar-transition-length, 0, 0);

            span {
                opacity: 0;
            }
        }

        .menu-list-item.dropdown {
            &.open > .dropdown-menu {
                display: none;
            }
        }

        &:hover {
            transform: translate3d(0, 0, 0);

            .menu-list {
                transform: translate3d(0, 0, 0);

                span {
                    opacity: 1;
                }
            }

            .menu-list-item.dropdown {
                &.open > .dropdown-menu {
                    display: block;
                }
            }
        }
    }
}