.dropdown {
    position: relative;
    will-change: transform;
    z-index: 100;
}

.dropdown__menu {
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 1000;
    min-width: 250px;
    list-style: none;
    background-color: $dropdown-menu--background-color;
    padding: 0;
    box-sizing: content-box;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.175);
    font-size: 10px;
    border: 1px solid $dropdown-menu--border-color;
    // border-top: 1px solid $dropdown-menu--border-color !important;

    &--right {
        left: auto;
        right: 0;
    }
}

.dropdown__item {
    border-bottom: 1px solid $dropdown-menu-item--border-color;
    box-sizing: content-box;
    margin-left: 0 !important;
    margin-right: 0 !important;

    @include respond-to(mobile) {
        &:first-child {
            border-top: 1px solid $dropdown-menu--border-color;
        }
    }

    @include respond-to(desktop) {
        &:last-child {
            border-bottom: none;
        }
    }

    > div,
    > .btn.btn-quiet {
        color: $brand-info;
        font-size: 0.875rem;
        font-weight: normal;
        font-family: $font-family-base;
        padding: 0 !important;
        display: block;
        text-align: left;
        border-radius: 0;
        padding: 10px !important;

        &:hover {
            background-color: $brand-info;
            color: white;
        }
    }

    .btn {
        span {
            font-family: $font-family-base;
            display: block;
            text-align: left;
        }
    }
}
