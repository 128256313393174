.stage-tracker {
    @include fully-centered-col;
    width: 100%;

    .stage-tracker-wrapper {
        @include row-v_center-h_space_between;
        width: 60%;
        padding: 12px;

        .stage {
            height: 10px;
            border-radius: 7px;
            border-width: 1px;
            border-style: solid;

            &.completed {
                background-color: $brand-color-light;
                border-color: $brand-color-base;

                &.current {
                    border-color: $brand-color-base;
                }
            }

            &:not(.completed) {
                background-color: #E6E7E7;
                border-color: #E0E1E1;
            }
        }
    }
}