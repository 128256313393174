.additional-funding-container{
    @include respond-to(desktop){
        display: flex;
        flex-wrap: wrap;

        .svg-wrapper{
            div:first-of-type{
                width: 100%;
            }
        }
    }

    .add-fund-main-img{
        //width: 234px;
        //height: 212px;
        padding-left: 20px;
        width: 100%;
        text-align: center;
        .svg-wrapper{
            width: 234px;
            height: 212px;
            margin-top: 10%;
            max-width: 300px;
            display: inline-flex; 
        }
    }

    .padding-left-5{
        padding-left: 5px;
    }

    .data-font{
        text-align: left;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #333333;
        opacity: 1;
    }

    .text-gray{
        text-align: center;
        font: normal normal normal 12px/17px Open Sans;
        letter-spacing: 0px;
        color: #6A6D73;
        opacity: 1;
    }

    .margin-top-35{
        margin-top: 35px;
    }

    .horizontal-padding-30 {
        padding-left: 30px;
        padding-right: 30px;
        justify-content: space-between;
    }

    .cursor-pointer{
        cursor: pointer;
    }
    

    .form-content .additional-funding-wrapper{
        border: 0;
        margin-top: 0;
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-bottom: 20px;

        .data-name-grey{
            margin-top: 35px;
            text-align: left;
            font: normal normal bold 20px/27px Open Sans;
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
        }



        .data-wrapper{
            .data-aux{
                color: $brand-color-black;
                text-transform: none;
            }
            .data-aux.grey-aux{
                font-weight: $brand-font-weight-semibold;
            }
        }

        .content-data.row{
            width: 100%;
            margin: 0 auto;

            .content-data-wrapper{
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        .data-label-alt{
            color: $brand-color-black;
        }
    }

    .additional-funding-links .content-data-wrapper {
        width: 100%;
    }

    .data-message {
        font-family: $brand-font-family-semibold;
        font-weight: $brand-font-weight-semibold;
        font-size: 16px;
    }

    .additional-funding-links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 25px;
        margin-right: 10px;

        .content-data-wrapper{
            width: 15%;
            display: inline-block;

            a{
                width: 100%;
                cursor: pointer;

                .svg-wrapper{
                    justify-content: center;
                    width: 100%;
                }
            }

            span{
                justify-content: center;
            }
        }
    }

    .additional-funds-card {
        // width: 390px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0 4px 17px -2px #0000071f;
        border-radius: 8px;
        opacity: 1;
        padding-left: 40px;
        padding-top: 26px;
        padding-bottom: 26px;
      }
    
    .card-arrow-color{
        color: #E87722;
    }

    .card-distribution{
        justify-content: space-between;
        padding-right: 20.47px;
    }

    .form-content{
        .btn-default{
            .svg-wrapper{
                width: 0.5em;
                height: 0.5em;
                min-width: 10px;
            }
        }
    }

    .switch-direct-deposit-button {
        padding-top: 2em;
    }
}