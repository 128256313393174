.section-content-heading {
    > .container,
    > .container-fluid {
        padding-bottom: $l;

        > .row {
            align-items: center;

            @include respond-to(desktop) {
                margin: 0 -2rem;
            }
        }
    }

    @include respond-to(mobile) {
        .col > .container {
            padding: 0;
        }
    }
}

.section-content-heading {
    > .container-fluid,
    > .container {
        max-width: initial;

        border-bottom: 1px solid $section-content-heading--border-color;

        @include respond-to(desktop) {
            padding: 0 0 $l 0;
            margin: 0 1rem;
        }
    }
}

.theme-product-detail {
    .section-content-heading {
        > .container,
        > .container-fluid {
            padding-bottom: 0;
        }
    }
}

.content-heading {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.content-image-wrapper {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;

    /* Avatar in settings */
    .rounder-content .svg-wrapper {
        padding: 0;
    }
}

@include respond-to(mobile) {
    .content-image {
        width: 100%;
        height: 100%;

        .rounder-content {
            width: 100%;
            height: 100%;
        }
    }
}
.avatar-text {
    font-size: 1.25em;
    position: absolute;

    color: white;
    font-family: $headings-font-family;
    font-weight: normal;
}
.content-image {
    position: relative;

    overflow: hidden;
    display: inline-flex;
    width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 50%;
    background-color: $content-image--background-color;

    .avatar-gravatar {
        position: absolute;

        width: 100%;
        height: 100%;
    }

    figure,
    img {
        border-radius: 50%;
    }

    figure {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: none;
        color: $content-image-icon--color;

        .svg-wrapper {
            width: initial;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.content-data-strong {
    &,
    &.data-amount {
        display: block;
        font-size: 1rem;
        font-family: sans-serif;
        color:black;
    }
}

.content-data-small {
    font-size: 0.75rem;
}

.content-data-wrapper {
    .data-desc {
        display: block;
    }

    .data-wrapper-inline {
        margin: 0;
    }

    font-size: 0.875rem;
}

@include respond-to(mobile) {
    .section-content-heading {
        > .container > .row {
            display: block;
        }

        &.container--layout {
            margin-bottom: 0rem;
        }

        &.heading-mob{
            .content-data-wrapper {
                font-size: 1rem;
            }
            .data-text.content-data-strong{
                font-size: 1.5rem;
            }
            .last-connection{
                margin-top: 5%;
            }
        }
    }

    .content-heading {
        flex-direction: column;
        text-align: center;
    }

    .content-data-wrapper {
        text-align: center;
    }

    .content-image-wrapper {
        margin: auto;
    }
}

.section-content-heading {
    > .container-fluid,
    > .container {
        border-bottom: 2px solid $section-content-heading--border-color;
        max-width: initial;
    }
}

@include respond-to(mobile) {
    .theme-product-detail .section-content-heading {
        padding-bottom: 1rem;

        > .container-fluid,
        > .container {
            border-bottom: none;
        }

        .progress {
            margin-bottom: 0;
        }

        .btn-primary {
            margin-top: 0.5rem;
        }

        .btn-secondary {
            margin-top: 0.5rem;
        }
    }

    .section-content-heading > .container {
        > .row > .col {
            max-width: 540px;
            margin: auto;

            &.content-data-wrapper {
                // margin: 1rem auto;
            }
        }
    }
}

.content-data-row {
    align-items: center;
}

.section-content-heading {
    .data-wrapper {
        .data-desc {
            display: initial;
        }
        div.data-label {
            display: block;
        }
        span.data-label {
            display: inline;
        }

    }
}

.align-product-head {
    position: relative;

    font-size: 1em;

    &::before {
        position: absolute;
        top: 1em;
        z-index: -1;

        display: block;

        width: 100%;
        height: 1px;

        content: "";

        background-color: $gray-lighter;
    }
    .text-lead {
        padding: 0 0.5rem;
        margin-left: 2.5rem;

        background-color: white;
    }

    .collapse {
        border-bottom: 1px solid $gray-lighter;
    }

    .btn-quiet {
        &,
        &:focus {
           background-color: white;
           color: $brand-info;
        }
    }
}

.section-content-heading {
    > .container-fluid,
    > .container {
        max-width: initial;

        border-bottom: 1px solid $section-content-heading--border-color;

        @include respond-to(desktop) {
            padding: 0 0 $l 0;
            margin: 0 1rem;
        }
    }
}