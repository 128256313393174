.color-reference {
    border-top: 0.125rem solid transparent;
}

.table .table {
    background-color: transparent;
}

.btn-circle-bg {
    span:empty {
        display: none;
    }
}

.Product {
    display: flex;
    flex-flow: wrap;

    width: 100%;

    &.economic-group {
        justify-content: space-between;

        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        .Product-row {
            flex: inherit;

            &:first-of-type {
                align-self: center;
                flex: 1;
            }
        }

        .data-wrapper {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;

            margin: 0;

            > * {
                flex: inherit;

                margin-right: 0.5rem;
            }
        }
    }

    .Product-label {
        flex: 100%;

        text-align: right;
    }

    .data-amount {
    }

    .data-label {
    }

    .data-desc {
    }

    .data-name {
        flex: 100%;
    }

    .Product-row-action {
        .btn {
            margin-left: 1rem;

            &:hover {
                svg {
                }
            }

            svg {
            }
        }
    }

    .Product-row {
        display: inline-flex;
        flex: 1;
        flex-direction: column;

        margin: 0 0 10px;

        .data-desc {
            display: flex;
            align-items: center;

            .svg-wrapper {
                width: 2.5rem;
                margin-right: 1.125rem;
            }
        }

        p {
            display: block;

            margin: 0;
        }

        &:last-of-type {
            align-items: flex-end;
        }
    }
}

.Product-row-action {
    .btn {
        margin-left: 1rem;

        &:hover {
            svg {
            }
        }

        svg {
        }
    }
}

// Tablas en salary payment

.tabs {
    display: flex;

    padding: 0;
    margin-bottom: 3rem;

    list-style: none;

    @include respond-to(desktop) {
        > li + li {
            margin-left: $s;
        }
    }

    li .btn {
        padding: 0.5rem;
    }
}

// nav nav-pills movement-commands
.btn-tab {
    padding-right: 0 !important;
    padding-left: 0 !important;

    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;

    font-weight: $light;
    text-transform: none;

    background-color: transparent;

    &:focus {
        box-shadow: none;
    }

    &:hover,
    &:focus {
        background-color: transparent;
    }

    &.is-active {
        background-color: transparent;
    }
}
