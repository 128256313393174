.table {
    width: 100%;
}

a.table-row {
    .table-data {
        color: $brand-color-black;

        .data-amount{
            color: $brand-color-black;
            font-weight: bold;
        }

        @include respond-to(mobile) {
            &.table-data-name {
                max-width: 40%;
            }
        }
    }

    .data-name {
        @include respond-to(mobile) {
            border-bottom: none;

            h4 {
                white-space: nowrap;
            }
        }

        h4 {
            white-space: normal;
        }
    }

    .table-data-icon {
        color: inherit;
        text-align: center;
    }

    &,
    &:hover {
        text-decoration: none;
    }

    .svg-icon {
        height: 20px;
    }
}

.table-data-head {
    border-bottom: 2px solid $table-head--border-color;
}

.table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    text-decoration: none;

    @include respond-to(mobile) {
        align-items: stretch;

        width: 100%;
        min-height: 4.5rem;

        border-bottom: 1px solid $table-row--border-color;
    }

    &.c-control-label::before {
        height: 1.25rem;
    }

    &:last-of-type {
        border-bottom: none;
    }

    .svg-wrapper {
        width: initial;
    }

    span {
        color: inherit;
    }
}

.table-end {
    & + span {
        display: flex;
        justify-content: center;

        line-height: 75px;
    }
}

$table-row-highlight--background-color: $alert-bg;

.table-row-highlight {
    background-color: $table-row-highlight--background-color;
}

.col-no-pad {
    padding: 0;
}

.col-no-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

@include respond-to(mobile) {
    .col.col-no-pad-mobile {
        padding: 0;
    }
}

@include respond-to(desktop) {
    .col.col-no-pad-desktop {
        padding: 0;
    }
}

.table-data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    padding: $table-data--padding-y $table-data--padding-x;

    font-size: 1rem;

    @include respond-to(desktop) {
        border-bottom: 1px solid $table-row--border-color;
    }
    @include when-inside(".table-head") {
        color: $table-head--color;
        font-size: 0.8em;
        text-transform: uppercase;
    }
    @include respond-to(mobile) {
        font-size: 0.8rem;

        &:not(.table-data-icon) {
            &:nth-child(1),
            &:nth-child(2) {
                [class*="data-"] {
                    text-align: left;
                }
            }

            &:nth-last-child(1),
            &:nth-last-child(2) {
                [class*="data-"] {
                    text-align: right;
                }
            }
        }
    }

    &:first-of-type {
        padding-left: $table-row--padding-to-edge - $table-data--accent-width;
    }

    &:last-of-type {
        padding-right: $table-row--padding-to-edge;
    }

    > [class*="data-"]:empty {
        display: none;
    }

    > [class*="data-"] {
        margin: 0.25em 0;

        font-size: 0.8rem;
    }

    [class*="data-"] {
        font-family: Raleway;
    }

    .data-amount {
        font-size: 1rem;
    }

    .data-aux {
        font-size: 0.75rem;
    }

    .data-name {
        color: $brand-primary;

        h4 {
            margin: 0;
        }
    }

    .bubble-wrapper {
        margin: 0.25rem 0;
    }
}

.table-data-icon {
    flex-grow: 0;
}

@include respond-to(desktop) {
    .table {
        display: table;
    }

    .table-data-icon:last-child {
        .svg-wrapper {
            float: right;

            vertical-align: middle;
        }
    }

    .table-column-titles {
        display: table-header-group;
    }

    .table-body {
        display: table-row-group;
    }

    .table-row {
        display: table-row;
    }

    .table-data {
        display: table-cell;

        vertical-align: middle;
    }

    .no-more-data {
        &.no-more-data-btn-movements {
            width: 50%;

            span{
                color: $brand-color-black;
                text-transform: none;
                font-weight: 500;
            }

            .btn.btn-link:hover{
                background-color: transparent;

                span{
                    color: $brand-color-black;
                }

                > .svg-wrapper{
                    color: $brand-color-primary;
                }
            }
        }
    }

    .btn-more-movements-account {
        margin-left: 0 !important;
    }
}

.table-data {
    @include respond-to(mobile) {
        .data-amount,
        .data-date {
            text-align: right;
        }

        &:first-child {
            .data-amount,
            .data-date {
                text-align: left;
            }
        }
    }

    .data-desc,
    .data-amount,
    .data-label,
    .data-date,
    .data-numeric {
        display: block;
    }

    .data-amount,
    .data-numeric {
        text-align: right;
    }

    .data-date {
        text-align: center;
    }

    .data-wrapper {
        .data-numeric,
        .data-date {
            text-align: left;
        }
    }

    .data-wrapper > .data-date + .data-label {
        margin-left: 3em;
    }
}

.table-row {
    &.is-expired {
        color: $table-row-expired--color;

        background-color: $table-row-expired--background-color;

        .data-name {
            color: $table-row-expired--color;
        }

        .product-name span {
            background-color: $table-row-expired--color;
        }
    }

    &.is-successful {
        color: $table-row-success--color;

        background-color: $table-row-success--background-color;

        .data-name {
            color: $table-row-success--color;
        }

        .product-name span {
            background-color: $table-row-success--color;
        }
    }
}

.table-legend {
    color: $table-legend--color;
}

.table-data > .data-tag {
    text-align: center;
}

.no-more-data .svg-wrapper,
.no-more-data .svg-wrapper svg {
    width: 2em;
    height: 2em;
}

a.table-row .svg-wrapper {
    color: $brand-color-accent;
}

.detailBox {
    padding: 0 1em;

    border: 1px solid $brand-color-element;
    border-radius: 5px;
}

.detailBox-row {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;

    padding: 1.25em;

    border-bottom: 1px solid $brand-color-element;

    &:nth-last-child(1) {
        border-bottom: none;
    }
}

.detailBox-data-aux span {
    display: flex;

    width: 250px;

    font-size: 0.75em;
}

.mTop {
    margin-top: 1em;

    text-align: center;
}

.btn.btn-asLink.btn-dl-error {
    font-size: 0.9em;
}

.table-caption {
    display: table-caption;
}

.table-caption-bottom {
    caption-side: bottom;
}

.table-row.table-row-movements {
    align-items: center;
}

.col-12.col-12-movements {
    min-height: 150px;
    width: 100%;
}

.preloader.preloader-movements {
    height: max-content;
    top: 120px;
}


.preloader.preloader-movements-v2 {
    height: 10px;
    top: 90%;
}

.preloader-movements-v2-container {
    height: 50px;
}
