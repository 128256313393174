.theme--auth .theme-product-detail {
    .section-content-heading {
        margin-bottom: 0;
    }

    @include respond-to(desktop) {
        margin-bottom: 1rem;
    }

    @include respond-to(mobile) {
        &.view-header .navbar {
            color: $view-header-product-onSmall--color;
            background-color: $view-header-product-onSmall--background-color;
        }

        .text-lead,
        .page-title,
        .view-title,
        h1,
        h2,
        h3,
        h4 {
            color: $view-header-product-onSmall--color;
        }

        &.view-morphing {
            color: $view-heading-content-product-onSmall--color;
            background-color: $view-heading-content-product-onSmall--background-color;
            border: none;
        }

        .toolbar-item.toolbar-item--fixed {
            color: $view-heading-content-product-onSmall--color;
        }

        .section-content-heading {
            border-color: $view-heading-content-product-onSmall--background-color;
            margin-bottom: 0;
        }
    }
}

.theme-auth {
    .slick-dots li button::before {
        color: $brand-info;
        opacity: 1;
    }

    .slick-dots li.slick-active button:before {
        color: $brand-primary;
    }

    &.theme-product-detail {
        .slick-dots li button::before {
            color: $carrousel-dots--color--theme-product-mobile;
        }

        .slick-dots li.slick-active button:before {
            color: $carrousel-dots--color;
        }
    }
}

.theme-product-detail {
    &.view-header {
        >.container--layout {
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
}