//@import "../../../../node_modules/react-select/scss/default";
.Select {
    border-collapse: separate;
    border: none;
    font-size: 1rem;
    width: 100%;
    &.is-focused {
        .Select-control {
            background-color: #eef8fb;
        }
    }
}

.select-option-label {
    font-weight: $brand-font-weight-bold;
    font-family: $brand-font-family-bold;

    color: $brand-color-primary;
    font-size: 14px;

}

.select-option-menu{
    text-align: left;
    padding: 10px 0px 10px 10px;
    
    &:hover{
        background-color: $brand-color-light;
        cursor: pointer;
    }
}

.select-option-value{
    padding-left: 5px;
    color: $ui-gray-label;
    font-weight: bold;
}

.select-option-add-link{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0.7em;
    font-size: 16px;

    font-weight: bolder;
    a{
        color: $brand-color-terciary !important;
        text-decoration: underline;
        &:hover{
            cursor: pointer;
        }
    }
}



.theme-auth {
    .form-group--select {
        .input-group {
            border: none;
            .Select {
                border: 1px solid #aaaaaa;
                border-radius: 0.15625rem;
                .Select-control {
                    border-radius: 0.15625rem;
                }
                &.is-focused {
                    border-color: $brand-primary;
                }
                .Select-multi-value-wrapper,
                .Select-input {
                    margin-right: 0.25rem;
                }
            }
        }
    }
    .form-group--composite {
        .Select,
        input {
            &:not(:last-child) {
                border-right: 1px solid #aaaaaa;
            }
        }
        .input-group > .is-focused {
            outline: 1px $brand-primary;
        }
        &.has-focus {
            border-color: 1px solid $brand-primary;
            input {
                background-color: white;
                &:focus {
                    background-color: transparent;
                }
            }
        }
        .input-group {
            // border: none;
            .Select {
                // border: 1px solid #48b7d5;
                border-top-left-radius: 0.15625rem;
                border-bottom-left-radius: 0.15625rem;
                .Select-control {
                    border-top-left-radius: 0.15625rem;
                    border-bottom-left-radius: 0.15625rem;
                }
                .Select-multi-value-wrapper,
                .Select-input {
                    background-color: transparent;
                }
            }
        }
    }
}

.has-error {
    .Select {
        .Select-control {
            background-color: lighten(#ce4a4a, 30%);
            color: #ce4a4a;
        }
    }
    &.form-group--composite {
        .input-group {
            .Select {
                // border: 1px solid #ce4a4a;
            }
        }
    }
}

.Select-menu-outer {
    z-index: 10;
}

.Select-control {
    height: auto;
    font-size: 16px;
    border: none;
    line-height: 1.4;
    padding: 13px 1em;
    box-shadow: none;
    border-radius: 0;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
    line-height: 49px;
    padding-left: 1rem;
    padding-right: 2.5rem;
}

.form-group {
    .Select-option,
    .Select-value-label {
        // Select normal
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-overflow: ellipsis;
        white-space: nowrap; // Select de productos con varios elementos dentro, que utilizan flex
    }
}

// Ellipsis de texto en selects de configurar limites de usuario
.ellipsis-text .Select > .Select-control {
    .Select-value {
        padding-top: 0.4em;
    }
    .Select-value-label {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .Select-option {
        display: block;
        white-space: nowrap;
        overflow-y: auto;
    }
}

.ellipsis-text.big-height .Select > .Select-control {
    .Select-value {
        line-height: 34px;
    }
}

.Select-input {
    height: 21px;
}

.Select.is-focused > .Select-control {
    // background-color: transparent;
}

.Select-control {
    padding-right: 0.5rem;
}

.Select-value {
    display: flex;
    text-align: center;
}

.Select-arrow-zone {
    width: 1.375rem;
}

.Select-arrow {
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 0.75rem;
    background-image: url("../../images/selectArrowDown.svg");
}

.Select.is-open .Select-arrow {
    background-image: url("../../images/selectArrowUp.svg");
}

.input-group {
    display: flex;
}

@media (max-width: 768px) {
    .input-group .form-control,
    .input-group .react-tagsinput {
        position: initial;
        z-index: initial;
        float: initial;
    }
}

.Select-control {
    .Select-input:focus {
        background-color: transparent;
    }
}

.Select-menu-outer {
    z-index: 1001;
}

.Select--multi .Select-value {
    display: inline-flex;
}

.is-searchable {
    .Select-control {
        display: inline-table;
    }
    .Select-value {
        line-height: initial !important;
    }
    .Select-input {
        padding: 0 !important;
        height: 100%;
        input {
            height: 100%;
            padding: 0 !important;
        }
    }
}


.form-group{
    .input-group.false-input-group{
        display: flex;
        justify-content: start;
        span{
            &.fi-label{
                padding-right: 0;
                font-weight: bold;
            }
            &.fi-amount{
                padding-left: 5px;
                font-weight: bold;
                font-style: italic;
                font-family: $brand-font-family;
            }
        }
    }
    .form-group-helper{
        font-size: 14px;
        color: $ui-gray-label;
        position: relative;
        top: -6px;
    }
}
