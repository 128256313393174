.media-object {
    display: flex;
    margin: 1rem 0;
    justify-content: center;
    @include respond-to(desktop) {
        justify-content: flex-start;
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    figure {
        display: block;
        align-items: center;
        margin: auto;
        @include respond-to(desktop) {
            display: contents;
        }
    }

    .svg-wrapper {
        color: white;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;

        .svg-icon {
            width: 6rem;
            height: 6rem;
        }
    }

    &--text {
        flex-grow: 1;
        margin-left: 1rem;
        font-family: $font-family-base;
        display: flex;
        justify-content: center;
        flex-direction: column;

        /* Esta clase no debe estar aqui */
        .text-lead {
            margin: 0;
        }
    }

    &.media-object--tranparent {
        .svg-wrapper {
            background-color: transparent;
        }
    }

    &.media-object--image-branded {
        .svg-wrapper {
            background-color: $media-object-branded--backgound-color;
        }
    }

    &.media-object--state-success {
        text-align: center;
        .svg-wrapper {
            background-color: transparent;
        }
    }

    &.media-object--state-error {
        .svg-wrapper {
            background-color: $media-object--backgound-color--state-error;
        }
    }

    &.media-object--state-warning {
        .svg-wrapper {
            background-color: $media-object--backgound-color--state-warning;
        }
    }

    @include respond-to(mobile) {
        flex-direction: column;

        &--text {
            justify-content: center;
            text-align: center;
            margin-top: 1rem;
            margin-left: 0;

            /* Esta clase no debe estar aqui */
            .text-lead {
                text-align: inherit;
            }
        }
    }

    .bubble {
        padding: 0;

        .svg-wrapper {
            background-color: transparent;
        }
    }
}