.recent-transactions {
    padding-top: 12px;

    @media (max-width: 700px) {
        padding: 0;

        .transaction-container {
            margin:0%;

            .scheduled-transaction{
                padding-left: 25px;
                padding-top: 10px;
                padding-bottom: 16px;
                padding-right: 25px;
            }
        }

        .transaction-container-content {
            p {
                .upper-case {
                    font-size: small;
                }
            }
            p {
                .tertiary {
                    font-size: small;
                    width: 140px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

        }

    }


    .transaction-container {
        background-color: #ffffff;
        cursor: pointer;
        box-shadow: $box-shadow;
        border-radius: 8px;
        opacity: 1;
        padding-left: 40px;
        padding-top: 26px;
        padding-bottom: 26px;
        padding-right: 40px;
        &:not(:last-of-type) {
            margin-bottom: 2.5em;
        }

        .transaction-container-heading {
            color: $brand-color-black;
            margin-bottom: 1em;
        }

        .transaction-container-content {
            @include row-v_center-h_space_between;
            width: 100%;
            flex-wrap: wrap;
            
            p {
                @include col-v_center-h_start;
                font: $brand-font-family;
                width: 33%;
                padding-right: 0.8em;
    
                span {
                    font: $brand-font-family;
    
                    &.regular {
                        color: $brand-color-black;
                    }
    
                    &.upper-case {
                        text-transform: uppercase;
                    }
    
                    &.highlighted {
                        font-weight: bold;
                    }
    
                    &.primary {
                        color: $brand-color-primary;
                    }
    
                    &.secondary {
                        color: $brand-color-black;
                    }
    
                    &.tertiary {
                        color: $brand-color-black;
                    }
                }
    
                .btn-icon, .btn, .btn-default {
                    padding: 0;
                    margin: 0.25em 0 0 0;
                    width: auto;
                    border: none;
                    color: $brand-color-primary;
                    font-size: 1.25em;
    
                    > * {
                        margin: 0;
                    }
    
                    &:hover {
                        background-color: transparent;
                    }
                }
    
                &:last-of-type {
                    @include col-v_center-h_end;
                    padding-right: 0;
                }
            }
        }
    }
}