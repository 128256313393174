@import "../../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss";

.input-group-datepicker {
    width: 10rem;
    height: 53px;


    @media (max-width: $screen-iphonex-xs) {
        width: 8.8rem;
    }
    cursor: pointer;

    input[type="text"] {
        cursor: pointer;
    }

    .form-control {
        // padding-right: 2.5rem !important; 
    }

     > div {
        flex-grow: 1;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        display: initial;
    }



    .form-control[readonly] {
        background-color: transparent;
    }
}


.react-datepicker__input-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        display: block;

        height: 1.5rem;
        min-width: 1.5rem;
        margin: 0.25rem 0.5rem;

        content: "";

        background-color: transparent;
        background-image: url("../../images/styledSchedule.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.25rem;

        pointer-events: none;
    }
}

.react-datepicker-popper {
    z-index: 10;
}

.react-datepicker__close-icon {
    top: 1rem;
    right: 2.5rem;

    width: 1rem;
    height: 1rem;

    &::after {
        content: "";

        background-color: transparent;
        background-image: url("../../images/styledClose.svg");
    }
}



@include respond-to(mobile) {
    .react-datepicker {
        border-radius: 0;
    }

    .react-datepicker__triangle {
        display: none;
    }
}

.react-datepicker__current-month {
    color: $brand-primary;
    font-size: 1.125rem;

    opacity: 0.7;
}

.react-datepicker__header {
    border-bottom: none;

    background-color: white;
}

.react-datepicker__day {
    color: #F7971C;
    font-size: 1rem;
}

.react-datepicker__current-month {
    line-height: 1.75em;
}

.react-datepicker__day--disabled {
    color: #cdcdcd;
}

.react-datepicker__day-name {
    color: $brand-primary;
    font-size: 1rem;
    font-weight: bold;
}

.react-datepicker__day--today {
    color: $brand-primary;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    border-radius: 50%;

    color: white;

    background-color: $brand-primary;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    // border-bottom-color: white;
}

.react-datepicker__navigation {
    width: 1.75rem;
    height: 1.75rem;

    border: none;
    border-radius: 50%;

    background-color: #F7971C	;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.5rem;
}

.react-datepicker__navigation--previous {
    background-image: url('../../images/styledDatepicker-Chevrom-Left.svg');
}

.react-datepicker__navigation--next {
    background-image: url('../../images/styledDatepicker-Chevrom-Right.svg');
}

.datepicker-filter-button {
    display: flex;
    align-items: baseline;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 0;
    padding-top: -0.5em;

    &.datepicker-filter-button-inner {
        padding-top: 8px;
        padding-left: 20px;
    }

}



@include respond-to(mobile) {
    .datepicker-filter-error {
        max-width: 162px;
    }
}

