/* ==========================================================================
Branding base color
========================================================================== */
$brand-color-base: #e87722;
$brand-color-primary: #e87722;
$brand-color-secondary: #e87722;
$brand-color-terciary: #f7971c;
$brand-color-accent: #e87722; // links & buttons
$brand-color-element: #efedf7;
$brand-color-light: #fdd9bd;

$brand-color-maroon: #94002C;
$brand-color-red: #B73D41;
$brand-color-green: #51911a;
$brand-color-yellow: #e0cc5f;
$brand-color-black: #333333;
$brand-color-disabled: #CACACA;

// alert colors
$alert-bg: #fcf8e3;
$alert-border: #faebcc;
$alert-color: #8a6d3b;

/* ==========================================================================
Branding grayscale
========================================================================== */
$ui-gray-dark: #2d2d2d;
$ui-gray-base: #7F7F7F;
$ui-gray-medium: #aeaeae;
$ui-gray-light: #e0e0e0;
$ui-gray-ultra-light: #f5f5f5;
$ui-white: #ffffff;
$ui-gray-label: #ADAFAF;

/* ==========================================================================
Basic Spacing
========================================================================== */
$map-globalFontSize: (
    320px: 13px,
    375px: 16px,
    767px: 18px,
    768px: 14px,
    1024px: 15px,
    1440px: 16px,
);

/*
    10px = 0.625rem
    12px = 0.75rem
    14px = 0.875rem
    ==> 16px = 1rem (base)
    18px = 1.125rem
    20px = 1.25rem
    24px = 1.5rem
    30px = 1.875rem
    32px = 2rem
*/

$xs: 0.75rem;
$s: 1rem;
$m: 1.333rem;
$l: 36px;

/* ==========================================================================
Typography
========================================================================== */

$brand-font-family: "Open Sans", sans-serif;
$brand-font-family-bold: "Open Sans", sans-serif;
$brand-font-family-semibold: "Open Sans", sans-serif;
$secondary-font-family: "Open Sans", sans-serif;

$brand-font-weight-bold: 700;
$brand-font-weight-semibold: 600;
$brand-font-weight-regular: 400;



$font-extra-small: $xs;
$font-small: $s;
$font-medium: $m;
$font-larg: $l;

$bold: 600;
$light: 200;
$regular: 300;

$ui-color-field: $ui-gray-medium;

/* ==========================================================================
Color status
========================================================================== */

$ui-color-error: $brand-color-red;
$ui-background-color-error: lighten($brand-color-red, 30%);
$ui-color-text--error: lighten($ui-color-error, 60%);

$ui-color-success: $brand-color-green;
$ui-background-color-success: lighten($brand-color-green, 30%);

$ui-color-warning: $brand-color-yellow;
$ui-background-color-alert: lighten($brand-color-yellow, 30%);

@import "bootstrap-variables-overrides";

$app--background-color: $brand-primary;
$app--color: color-yiq($app--background-color);

// App page
$app-page--primary-color: $brand-info;
$app-page-link--color: $brand-color-primary;

// App header nav
$header-nav-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);

// App footer
$app-footer--background-color: darken($brand-primary, 10%);
$app-footer--color: color-yiq($app-footer--background-color);
$app-footer-link--color: $brand-info;

// Container
$container-bottom--background-color: darken($brand-primary, 10%);

// Content Blocks
$box-shadow: 0 4px 17px -2px #0000071f;
$box-border-radius: 8px;

// C control
$c-control--border-radius: 5px;

// Header

$headings-color: #000;

// Button
$btn--font-size: 22px;
$btn--font-weight: bold;
$btn--text-transform: none;
$btn--padding: 0.5em;
$btn--margin: 0.5em;
$btn-primary--width: 390px;
$btn-primary--heigth: 60px;
$btn--height: 60px;
$btn--color: #000;
$btn--background: rgba(232, 119, 34, 0.5);
$btn--border-radius: 4px;
$btn-block--margin-top: 1rem;

/* Extra small screen / phone */
$screen-xs: 480px !default;

/* Small screen / tablet */
$screen-sm: 768px !default;

/* Medium screen / desktop */
$screen-md: 992px !default;

/* Large screen / wide desktop */
$screen-lg: 1200px !default;

/* So media queries don't overlap when required, provide a maximum */
$screen-xs-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;

/* Interpolation map to acomodate font size on :root */

$external-footer-height: $l * 1.5;

$some-color: rgba(189, 191, 193, 0.4);

:export {
    brandColorBase: $brand-color-base;
    brandColorSecondary: $brand-color-secondary;
}
