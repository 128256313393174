// Notification
.login-background {
    .notifications-wrapper {
        position: fixed;
    }
}


.notifications-wrapper {
    position: absolute;
    left: 0;
    z-index: 1001;

    display: block;

    width: 100%;

     > div {
        width: 100%;
    }

    .notifications-tc {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        bottom: auto;
        z-index: 9998;

        height: auto;
        padding: 0 2rem 1rem ;

        font-family: inherit;
    }

    .notification {
        position: relative;
        top: 0;

        display: block;

        width: 100%;
        padding: 0.5rem 0.75rem;
        // border-radius: 5px;
        margin: 1rem 0 0;

        font-size: 1rem;

        opacity: 0;

        transform: translate3d(0px, -100%, 0px);
        transition: 0.3s ease-in-out;
        will-change: transform, opacity;
        cursor: default;
    }

    .notification-success {
        color: color-yiq($ui-color-success);

        background-color: $ui-color-success;
    }

    .notification-warning,
    .notification-info {
        color: $gray-darker;

        background-color: $brand-warning;
    }

    .notification-error {
       color: color-yiq($brand-danger);

       background-color: $brand-danger;
    }

    // .notification-not-dismissible {
    // }

    // .notification-hidden {
    // }

    .notification-visible {
        opacity: 1;

        transform: translate3d(0px, 0px, 0px);
    }
}
.default-layout {

   .notifications-tc {
        padding-left: 18rem;
    }

    .sidebar-collapsed {
        .notifications-tc {
            padding-left: 6rem;
        }
    }
} 
.notification-wrapper {
    padding: 1rem;
    margin-bottom: 1rem;

    font-size: 0.875rem;
    text-align: center;

    background-color: $brand-warning;
}