.bottom-sheet {
    z-index: 1000;

    width: 100%;
    max-height: 70vh;

    background-color: white;

    .container--layout {
        margin-bottom: 0;
    }
}

.bottom-sheet-header {
    position: relative;

    border-bottom: 1px solid $gray-lighter;

    .btn {
        position: absolute;
        top: -$s;
        right: -$s;

         > span {
            @include visually-hidden;
        }
    }
}

.bottom-sheet-title {
    color: $brand-primary;
    text-align: center;
}


// POI LIST ----------------------------------------------------
// this may be the pattern to solve other lists such as messages

.list-wrapper--poi {
    position: relative;
    overflow: auto;

    flex: 1;

    height: 100%;
    max-height: 100%;

    @include respond-to(desktop) {
        border-top: 2px solid $gray-lighter;
        border-bottom: 2px solid $gray-lighter;
    }
}

.list--poi {
    width: 100%;
    max-height: 500px;
    padding: 0;
    margin: 0;

    list-style: none;

    @include respond-to(desktop) {
        position: absolute;
    }
    @include respond-to(mobile) {
        &::before,
        &::after {
            display: block;

            width: 100%;

            content: "";
        }

        &::before {
            height: 0.5rem;
        }

        &::after {
            height: 2rem;
        }
    }
}

.list-item--poi {
    list-style: none;

    &:not(:last-of-type) {
        border-bottom: 1px solid $gray-lighter;
    }

    button {
        width: 100%;

        border: none;

        background-color: transparent;

        @include respond-to(mobile) {
            display: flex;
            align-items: center;
        }

        &:focus,
        &.isActive {
            color: $brand-primary;

            background-color: #c4e8f2;
            outline: none;

            .svg-wrapper,
            h4 {
                color: $brand-primary;
            }
        }

        .svg-wrapper,
        h4 {
            color: $brand-primary;
        }

        .table-data {
            border: none;
        }
    }

    div:not(.svg-wrapper) {
        // flex-grow: 1;
        text-align: left;
    }

    .svg-wrapper {
        width: auto;
    }

    p {
        font-weight: normal;
    }

    .data-label p {
        margin: 0;
    }

    .table-data:not(.table-data-icon):nth-last-child(1) [class*="data-"],
    .table-data:not(.table-data-icon):nth-last-child(2) [class*="data-"] {
        text-align: initial;
    }
}