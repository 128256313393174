.app {
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 100%;
}

.app-page {
    overflow: auto;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    width: 100%;
    height: 100%;

    -webkit-overflow-scrolling: touch;
}

.app-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;

    min-height: 100%;
}

.app-footer {
    padding: 0 0 $l;

    .container--layout {
        margin: 0;
    }
}

.app-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $navbar-default-bg;
}

.app-sidebar {
    position: absolute;
    z-index: 1;
}

.app-page {
    position: relative;
}

.app-version {
    align-self: flex-end;

    padding: 0 ($l / 2);
}

@include respond-to(mobile) {
    /* CSS specific to iOS devices 
     * Viewport height is taller than the visible part of the document
     * in some mobile browsers
     * Webkit bug has been set to RESOLVED WONTFIX
     * https://bugs.webkit.org/show_bug.cgi?id=141832
     */

    /* 
     * Toolbar height 
     * Se necesita solo cuando se esta en safari
     */
    @supports (-webkit-overflow-scrolling: touch) {
        body {
            &:not(.mobile-native) {
            }
        }
    }

    #root > div:first-child {
        position: fixed;

        width: 100%;
    }
}

@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    // body { position: fixed }

    .mobile-native {
    }
}

.app-page {
    @include respond-to(desktop) {
        flex-direction: row;

        .app-content {
            position: relative;

            flex-direction: column;
        }

        .app-sidebar {
            // min-width: 15rem;
            // max-width: 15rem;
            // flex-basis: 15rem;
            // flex-grow: 1;
            order: -1;
        }

        .view-title {
            margin: 0;

            text-align: initial;
        }

        .view-content {
            position: relative;
        }

        .check-list {
            .c-control {
                display: block;
            }
        }

        .selection-list {
            .c-control {
                justify-content: start;
            }

            .c-control-label {
                display: inline-flex;

                width: initial;
                padding: 0 1rem;
            }
        }

        .text-lead {
            text-align: initial;
        }

        .security-image {
            flex-direction: initial;

            .image {
                flex-grow: 9999;
            }

            .text-lead {
                flex-grow: 1;

                margin: 0 1.5rem;
            }

            .btn {
                margin: 0.8rem 1.5rem 0;
            }
        }

        .container {
            max-width: initial;
        }

        /* Para que es este selector? main-container inseption... sera para el login en @normal */
        .main-container > * {
            .main-container {
                display: block;
            }
        }

        .main-container > *:not(.above-the-fold) {
            display: block;
        }
    }
    @include respond-to(mobile) {
        .main-container > * {
            .main-container {
                margin: auto !important;
            }
        }
    }
}



/* ==========================================================================
   Code to review
   ========================================================================== */

.row {
    margin-right: -1rem;
    margin-left: -1rem;
}

.col,
.container,
.container-fluid {
    padding-right: 1rem;
    padding-left: 1rem;
}

.view-wrapper {
    background-color: transparent;
}

.app,
.view-wrapper {
    a.newProduct {
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
        }
    }
}