$tabs--color: #333333;
$tabs--background-color: rgba(14, 109, 110, 0.3);
$tabs--border-color: $tabs--background-color;
$tabs-selected--color: $brand-primary;

.react-tabs__tab-panel > div {
    width: 100%;
}

.react-tabs__tab-panel--selected {
    display: flex;
    flex-grow: 1;
}

.react-tabs__tab-list {
    display: flex;

    padding: 0;
}

.react-tabs__tab {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    padding: 0.75em;
    padding-bottom: 0.3em;
    margin: 0;

    font-family: $brand-font-family;
    // font-size: 1rem;
    font-weight: normal;
    text-transform: uppercase;
}

.react-tabs__tab {
    border-bottom: 1px solid #333333;
    color: $tabs--color;
    background-color: transparent;

    margin: 2px;
}

.react-tabs__tab--selected {
    border: none;
    border-bottom: 2px solid $brand-color-primary;
    font-weight: bold;
    color: $tabs-selected--color;

    background-color: $ui-white;
}

.theme-product-detail + .container--layout .react-tabs__tab--selected {
    border: none;
}

.containerDetails{
    .react-tabs__tab-panel{

    }

    &.containerDetails-credit-card-detail {
        max-width: 25% !important;
        margin: 0 auto;
        text-align: center;
    }

    &.containerDetails-credit-card-detail-number {
        max-width: 25% !important;
        margin: 0 auto;
        text-align: center;
    }
}


.btn.download-button {
    justify-content: flex-end;
    display: flex;
    flex-grow: 1;
    padding: 0.75em;
    margin: 0;
    font-family: $brand-font-family;
    font-size: 0.9rem; 
    font-weight: normal;
    text-transform: uppercase;  
    border: none;
    color: $tabs--color;
    background-color: transparent;
    margin: 1px;
    width: auto;
}

.btn.btn-block.btn-search-button:disabled {
    .svg-wrapper {
        svg {
            path {
                fill: #ADAFAF;
            }
        }
    }
}

.notifications-setup-list {
     .c-control--checkbox {
        .c-control-label{
            .c-control-icons{
                .c-control-mark{
                    .svg-wrapper{
                        svg {
                            height: 18px;
                            width: 18px;
                            border-radius: 2px;
                            background-color: #E87722;
                            padding: 3px;

                            path {
                                stroke: #fff;
                                stroke-width: 7px;
                            }
                        }
                    }
                 }
            }
        }
    }
}





.btn.btn-block.btn-search-button {
    justify-content: flex-start;
    display: flex;
    //flex-grow: 1;
    padding: 0.75em;
    margin: 0;
    font-family: $brand-font-family;
    font-size: 0.9rem; 
    font-weight: normal;
    text-transform: uppercase;  
    border: none;
    color: $tabs--color;
    background-color: transparent;
    margin: 1px;
    width: max-content;
    .svg-wrapper {
        svg {
            z-index: 1;
            width: 2rem;
            height: 2rem;
            padding: 0;
            .cls-1 {
                    stroke:none;
                }
        }
    }

}
.btn.btn-block.btn-search-button.is-active {
    color: $tabs--color;
    background-color: transparent;
}



.btn.btn-block.download-statement-button {
    justify-content: flex-start;
    display: flex;
    flex-grow: 1;
    padding: 0.75em;
    margin: 0;
    font-family: $brand-font-family;
    font-size: 1.1em; 
    font-weight: normal;
    text-transform: capitalize; 
    text-decoration: underline; 
    border: none;
    color: $tabs--color;
    background-color: transparent;
    margin: 1px;
    width: auto;

}

.btn.btn-block.download-statement-button.is-active {
    color: $tabs--color;
    background-color: transparent;
}

.btn.btn-block.movement-button {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding: 0.75em;
    margin: 0;
    font-family: $brand-font-family;
    font-size: 0.75rem; 
    font-weight: bold;
    text-transform: uppercase;  
    border: 1px solid $brand-color-primary;
    color: $tabs--color;
    background-color: transparent;
    margin: 1px;
    width: max-content;
    margin-right: 0.5%;
    margin-right: 0.5%;

}

.btn.btn-block.movement-button.is-active {
    color: white;
    background-color: $brand-color-primary;
    
}

.movement-buttons {
    display: flex;

    padding: 0;
    width: 100%;
    margin-bottom: 20px;
}

.movement-button {
    &.movement-button-period-filter {
        width: 100%;
    }
}

a{
    &.widget-link {
        &:link, &:visited, &:hover, &:active {  text-decoration: none;  color: $brand-color-primary} 
    }
}
a {
    &.no-underline {
        &:link, &:visited, &:hover, &:active {  text-decoration: none; } 
    }

}

div {
    &.credit-card-align-right {
        @include respond-to(mobile){
            justify-content: flex-end;
        }

        justify-content: center;
    }
}

// hacks for making blocks resize proportionally
.cc-wrapper{
    display: flex;
    width: 100%;
    justify-content: center;
}
.cc-container{
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 450px;
}
.cc-container-spacer {
    margin-top: 70%;
}
.cc-plastic-wrapper, .cc-plastic-not-active{
    text-align: center;
}
.cc-plastic-wrapper{
    background-image: url("../../images/ppcard.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
    right: 2.5rem;
    @media (max-width: 300px) {
        top: 1.5rem;
        bottom: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
    }
    overflow: hidden;
    .data-label{
        position: absolute;
        left: 10%;
        @include respond-to(mobile){
            margin-left: 5%;
        }
        @media (max-width: $screen-sm-min) {
            margin-left: 2%;
        }
        color: black;
        &.cc-card-number{
            bottom: 35%;
            width: max-content;
        }
        &.cc-exp-date{
            bottom: 20%;
            .data-aux{
                font-size: .9rem;
                @media (max-width: $screen-sm-min) {
                    font-size: .7rem;
                }
                @media (max-width: 300px) {
                    font-size: .6rem;
                }
            }
        }
        &.cc-visa{
            bottom: 10%;
            text-transform: uppercase;
            .data-aux{
                @include respond-to(mobile) {
                    font-size: .8rem;
                }
                @media (max-width: 300px) {
                    font-size: .6rem;
                }
                .svg-wrapper{
                    width: 17%;
                }
            }
        }
    }

    .data-aux{
        font-family: $brand-font-family;
        font-size: 1rem;

    }
    .cc-card-number{
        letter-spacing: 3px;
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        @include respond-to(mobile){
            font-size: 0.9rem;
            .data-aux{
                font-family: $brand-font-family;
                font-size: 0.9rem;
            }
        }
        .data-aux{
            @media (max-width: 450px) {
                font-size: .7rem;
                letter-spacing: 0.1rem;
            }
        }
    }
}
.cc-data-aux-a {
    color: $brand-color-terciary !important;
    font-size: medium;
    text-decoration: none;
    &:hover,
    &:focus {
        color: darken($app-page-link--color, 10%);
    }

    @include respond-to(desktop){
        text-align: center;
    }
}

.dashboard {
    .container--layout {    
        @include respond-to(mobile) {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }  
} 

.trademark-plastic {
    @include respond-to(mobile){
        margin: 0 auto;
        margin-bottom: 20px;

    }
    
    white-space: pre;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: left;
    font-size: 0.7rem;
    color: $ui-gray-medium;

    @media only screen and (max-width: 1055px){
        max-width: 300px;
        white-space: unset;
    }
}

.trademark-disclosure {
    @include respond-to(mobile){
        margin: 0 auto;
    }
    
    white-space: pre;
    margin: 0 auto;
    margin-bottom: 10px;
    text-align: center;
    font-size: 0.7rem;
    color: $ui-gray-medium;

    @media only screen and (max-width: 1055px){
        max-width: 300px;
        white-space: unset;
    }
}

.container--disclosure{
    padding-top: 10px;
}

.cc-not-active{
    opacity: 0.3;
}

.cc-admin-operation{
    box-shadow: 0px 0px 15px 0px $brand-color-primary;
    border-color: $brand-color-primary
}

.communications-module {
    padding-right: 0;
}

.cc-plastic-not-active {
    background-image: url("../../images/ppcard-disabled.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    z-index: 3;  
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .cc-not-active-msg{
        color: black;
        margin: 16px auto;
        font-weight: bold;
        @include respond-to(mobile){
            margin-left: 1%;
        }
    }

    .cc-change-status-msg{
        font-size: medium;
        text-decoration: none;
        position: absolute;
        bottom: 10px;
        width: 100%;
    }
}


.cc-reactivate-msg .no-underline {
    span
    {
        &a 
        {
            text-decoration: none;
            text-transform: lowercase;
        }
    }
}

.menu-item-card-option {
    place-content: center !important;
    padding: 0 !important;
    span {
            color: $brand-color-black; 
        }
}

.content-data-credit{
    .other-cards-link{
        color: $brand-color-terciary;
        text-align: center;
        margin-bottom: 1rem;

        a{
            color: #f7971c;
            margin: 0 auto;
            cursor: pointer;
            font-weight: bold;

            &:hover{
                color: #f7971c;
            }
        }
    }
}

li {
    .submenuitem {
        padding-left: 1em !important;
    }
}


.cc-links-wrapper {
    max-height: 250px;
    padding-top: 1%;
    padding-bottom: 5%;
    margin: 0 auto;
    justify-content: space-between;

    .cc-link {
        span {
            text-transform: uppercase;
            cursor: pointer;
        }
    }

    @include respond-to(desktop){
        padding: 3% 0;
        max-height: 250px;
        justify-content: space-between;
        width: 80%; 
        &.row {
            margin: 0 auto;

        }

        @media (max-width: $screen-md-min) {
            width: 100%; 
        }


        &.cc-links-reduced {
            @media (min-width: $screen-sm-min) {
                // width: 125%; 
                .cc-link , .card-not-active-message {
                    span {
                        font-size: 0.80rem;
                    }
                }
            }
    
            @media (min-width: $screen-mm-min) {
                width: 105%; 
                .cc-link , .card-not-active-message {
                    span {
                        font-size: 0.90rem;
                    }
                }
            }
    
            @media (min-width: $screen-md-min) {
                width: 100%; 
                .cc-link {
                    span , .card-not-active-message {
                        font-size: 0.95rem;
                    }
                }
            }
    
            @media (min-width: $screen-lg-min) {
                width: 80%; 
            }
            @media (min-width: $screen-lx-min) {
                width: 60%; 
            }

        }

    }



    span {
        &.data-label {
            color : $brand-color-terciary ;
            text-decoration: underline;
            text-decoration-color: $brand-color-terciary ;
        }
        &.data-aux {
            font-size: 16px;
            color : $brand-color-terciary  ;
            text-decoration-color: $brand-color-terciary ;
            text-transform: uppercase;


        }
        font-size: 16px;

    }

}

.widget-container{
    margin: 1%;
}

.containerDetails .data-wrapper, .content-data-credit{
    .data-label{
        margin-bottom: 0;
    }
    .data-aux{
        font-weight: 600;
        text-transform: unset;
        font-family: $brand-font-family;
    }
    .content-data-strong{
        font-weight: bold;
    }
}

.no-size-limit-tabs {
    max-width: none !important;
}

.card-not-active-message {
    color: rgba(240, 187, 109, 0.685);
    text-decoration: none;
    text-decoration-color: rgba(240, 187, 109, 0.685);
    font-style: bold;
}
