/* Deprecated => list--separated */

/**
 * Este titulo se utiliza unicamente en settiengs, por lo que no se esta 
 * reutilizando y podriamos mergearlo con otro
 */

.navigational-list-title {
    margin-top: 1.5rem;

    color: $navigational-list-title--color;
}

.navigational-list-item {
    list-style: none;

    .btn+.btn {
        margin-top: 1rem;
    }

    >.form-group {
        button>.c-control .c-control-label {
            padding: 0;
            margin-right: 0.25rem;
        }

        .c-control .c-control-label {
            padding-left: 2.25rem;
        }

        .form-group-control-list {
            button>.c-control {
                min-width: 1.25rem;
                padding: 0;
                margin-right: 0.5rem;
            }

            .c-control {
                padding-left: 0.5rem;
            }
        }
    }

    &.navigational-list-item-agreements {
        border-bottom: 0px;

        .agreements-item-list {
            font-family: $brand-font-family;
            padding-bottom: 5px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            width: 85%;
            font-weight: 600;
            font-size: 16px;

            @include respond-to(mobile){
                width: 100%;
            }

            a {
                display: flex;
                width: 5%;
            }
            
            .agreements-options {
                display: flex;
                width: 15%;

                .btn.btn-outline:hover {
                    background: white; 
                }
                .svg-wrapper {

                    svg {
                        min-width: 17px;
                        height: 20px;
                        width: 0.75rem;
                    }
                }

                .download-button {
                    margin-top: 0px;
                }
            }
        }
    }
}

.list--permissions,
.notifications-setup-list {
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 17px -2px #0000071f;
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 8px;

    span {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        color: #333333;
    }
}


.list--permissions,
.navigational-list {
    padding-left: 0;
    margin-bottom: 0;

    list-style: none;

    .navigational-list-item {
        font-family: $headings-font-family;

        a {
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            width: 100%;

            .svg-wrapper,
            svg {
                width: 0.75rem;
                min-width: 0.75rem;
                height: 0.75rem;
            }

            svg path {
                stroke-width: 3px;
            }
        }
    }

    .c-control+span {
        flex-grow: 1;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        text-align: left;
    }

    .c-control-label .c-control-icons {
        .c-control-mark {

            .svg-wrapper,
            svg,
            path {
                width: 0.75rem;
                min-width: 0.75rem;
                height: 0.75rem;

                stroke-width: 3px;
            }
        }
    }


    .navigational-list-subtitle {
        padding: 0 1rem;
        margin: 1rem 0 0.25rem 0;

        font-weight: bold;
    }

    .collapse-open {
        color: $brand-primary;
    }

    .collapsable-button {
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .collapsable-button {
        // display: none;

        .svg-wrapper {

            &,
            svg {
                width: 0.75rem;
                height: 0.75rem;
                min-width: 0.75rem;
            }

            .stroke {
                color: $brand-info;

                stroke-width: 3px;
            }
        }

        .c-control {
            .svg-wrapper {
                .stroke {
                    color: $brand-primary;
                }
            }
        }
    }

    .collapse-open {
        .svg-wrapper {
            .stroke {
                color: $brand-primary;
            }
        }
    }

    .c-control-label .form-group-text {
        margin: 0;
    }

    >.form-group .form-group-control-list .c-control {
        padding-left: 0.5rem;
    }

    .list-item--noChilds {
        .collapsable-button.is-active {
            font-weight: 700;

            .list-item-hint {
                font-weight: 400;
            }
        }

        &>.c-control:only-child .c-control-label {
            margin-left: 0.5rem;
            padding-left: 2.25rem;
        }
    }

    .form-group-control-list {
        margin-top: 0;

        .collapsable-button {
            padding-top: .25rem;
            padding-bottom: .25rem;
            color: #222222;
        }

        .navigational-list-subtitle {
            padding-left: 0;
        }

        .c-control {
            padding-left: 0.25rem;

        }

        .list-item {
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            .list-item {
                padding: 0;
            }
        }

        >.navigational-list-subtitle {
            padding-left: .5rem;
        }
    }
}


.navigational-list--notifications {
    .c-control-label::before {
        content: none;
    }
}