.navbar-header .header-command-pallette > li > a {
    padding-bottom: 0.3rem;
}

.header-command-pallette {
    display: flex;
    .caret {
        display: none;
    }
    .detail-links-href {
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 200;
        
        a.detail-links-href {
            color: $brand-color-base;
            &:hover, &:active, &:focus{
                color: $brand-color-base;
            }
        }
    }
    .user-profile{
        position: relative;
        .rounder-content{
            float:left;
            .svg-wrapper{
                padding: 0;
            }
        }
        .user-info{
            text-align: left;
            h4{
                float: left;
                margin: 6px 0 0px 0px;
                font-weight: 600;
            }
        }
        .user-email{
            // text-align: left;
            float: left;
            font-size: 12px;
        }

    }
    li a {
        text-decoration: none;
        display: flex;

        align-items: center;
        color: $header-command--color;

        &:hover .user-profile, &:focus .user-profile{
            color: $brand-color-base;
        }
        .rounder-content {
            position: relative;
            flex-basis: initial;
            color: $header-command-icon--color;

            em {
                line-height: 3rem;
            }
            .messages-icon{
                height: 24px;
            }
        }

        > span {
            font-family: $headings-font-family;
            color: $header-command--color;
            font-weight: bold;
            font-size: 18px;
        }
    }
    .dropdown {
        text-align: center;

        &.open .rounder-content {
            background-color: $widget-title-background--color;
            color: $header-command-icon-active--color;
        }

        li a > span {
            font-family: sans-serif;
            color: $gray-darker; //?? donde aplica esto
        }
    }

    .rounder-content:hover {
        background-color: $widget-title-background--color;
    }

    .dropdown.open .corp-icon .class-1 {
        stroke: $brand-color-base;
    }

    .corp-icon:hover .fill-icon-body,
    .dropdown.open .corp-icon .fill-icon-body {
        fill: $brand-color-light;
        stroke: $brand-primary;
    }

    .corp-icon:hover .fill-icon-button,
    .dropdown.open .corp-icon .fill-icon-button {
        fill: $header-command-icon-active--background-color;
        stroke: $brand-primary;
    }

    > .dropdown-header.open {
        > a {
            &,
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }

    .dropdown-menu {
        box-shadow: none;
        border: 2px solid $header-dropdown--border-color;
        border-radius: 5px;
        z-index: 1;
        position: absolute;

        a {
            display: inline-block; /* Se hace este cambio que afecta al link del dropdow del perfil*/
        }
    }

    li:first-child{
        .class-1{
            stroke: none;
        }
        svg{
            path{
                fill: $brand-color-primary;
            }
        }
    }
}

.dropdown-menu .st0 {
    stroke: $brand-info;
}

.dropdown-menu .menu-list-item {
    a, span {
        &:hover .st0 {
            stroke: white;
        }
    }
}

.svg-stroke-color {
    stroke: $brand-primary;
}

.rounder-content {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    color: $gray-dark;
    background-color: $header-command-icon--background-color;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-wrapper {
        padding: 0.675rem;
    }
}

// redisenar este componente con flex
.dropdown-inHeader {
    .dropdown-menu {
        width: 260px;
        color: $gray-darker;
        top: 5.5rem;
        border-radius: 5px !important;
        right: 1rem;

        .ellipsis{
            font-size: 12px;
        }

        .btn {
            font-size: 14px;
            width: 100%;
            span {
                font-size: 14px;
            }
            .svg-wrapper {
                width: 1.6rem;
                svg path{
                    fill: white;
                }
            }
            &:hover{
                .svg-wrapper {
                    width: 1.6rem;
                    svg path{
                        fill: $brand-color-base;
                    }
                }
            }
            &.btn-secondary{
                .svg-wrapper {
                    svg path{
                        fill: $brand-color-base;
                    }
                }
            }
        }

    }
    &:not(.dropdown-inHeader-list) .dropdown-menu {
        padding: 1rem;
    }
}

// badge in inHeader buttons
.badge {
    border-radius: 1.5rem;
    position: absolute;
    z-index: 2;
    height: 1.5rem;
    min-width: 1.5rem;
    top: -0.25rem;
    right: -1rem;
    padding: 0;
    background-color: $communications-badge--background-color;

    span {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $communications-badge--color;
        padding: 0.5rem;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
    }

    @include when-inside(".toolbar-btn") {
        position: absolute;
        top: 0.25rem;

        span {
            position: initial;
        }
    }

    @include respond-to(mobile) {
        right: -0.5em;

        span {
            padding: 0;
            font-size: 0.75rem;
        }
    }
}

$menu-list-item--color: $brand-info;
$menu-list-item--color--hover: white;
$menu-list-item--color--active: white;
$menu-list-item--background-color--hover: $menu-list-item--color;
$menu-list-item--background-color--active: $brand-primary;

.dropdown-inHeader-list {
    .dropdown-menu {
        padding: 0 ($s / 2);
    }
    .menu-list-item {
        margin: ($s / 2) 0;
        a, > span {
            position: relative;

            display: flex;
            align-items: inherit;
            flex-direction: column;
            justify-content: center;

            width: 100%;

            min-height: 3rem;
            padding-right: 0;
            padding-right: 1rem;
            padding-left: 2.5em;

            border-radius: 5px;

            color: $menu-list-item--color;
            font-size: 1.25rem;
            text-align: left;

            &:hover {
                color: $menu-list-item--color--hover;

                background-color: $menu-list-item--background-color--hover;
            }

            &:focus,
            &.is-selected {
                color: $menu-list-item--color--active;

                background-color: $menu-list-item--background-color--active;
            }

            span {
                color: inherit !important;
                font-family: $headings-font-family;
                font-size: 1rem;
            }
            .svg-wrapper {
                position: absolute;
                left: 0.75rem;

                width: 1.2em;
                margin-right: 0.5em;
            }
        }
    }
}
